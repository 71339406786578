import axios from "axios";

export const Axios = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? "/api/v1/admin" : "http://localhost:8000/api/v1/admin/",
  headers: {
    post: {
      "Content-Type": "application/json"
    }
  }
});

export const AxiosUsers = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? "/api/v1/users" : "http://localhost:8000/api/v1/users/",
  headers: {
    post: {
      "Content-Type": "application/json"
    }
  }
});

