<template>
  <div class="phrase__container">
    <span class="phrase__text">{{ wordTranslation("additional_information.make_greenhouses_title") }}</span>

    <tippy class="tippy__orientation">
      <div class="item__info_specification tippy-description placeholder"></div>
      <template #content>
        <p class="tippy-description">
          {{ wordTranslation("additional_information.make_greenhouses_hint") }}
          <br/><br/></p>
      </template>
    </tippy>

  </div>

</template>

<script>
import {Tippy} from "vue-tippy";

export default {
  name: "v-phrase",
  props: {
    wordTranslation: Function,
  },
  components: {
    Tippy
  }
};
</script>

<style scoped lang="scss">
.phrase {
  &__container {
    padding-left: toRem(51);
    padding-right: toRem(51);
    padding-bottom: toRem(25);
    @include _desktop {
      display: flex;
      max-width: 100%;
      position: relative;
      padding: 0;
    }
  }

  &__text {
    top: toRem(-92);
    font-style: normal;
    font-weight: 400;
    font-size: toRemMob(28);
    line-height: toRemMob(33);

    @include _desktop {
      font-weight: 300;
      font-size: toRem(35);
      line-height: toRem(41);
    }
  }
}

.placeholder {
  display: inline-block;
  cursor: pointer;
  font-style: normal;
  font-weight: 300;
  font-size: toRem(16);
  line-height: toRem(24);
  @include _desktop {
    top: toRem(10);
    left: 0;
  }

  &__text {
    margin: toRem(10) toRem(10) 0;
  }
}

.tippy__orientation {
  position: relative;
  left: 82%;
  top: 0;
  @include _desktop {
    left: 0;
    top: toRem(10);
  }
}

.tippy-description {
  padding-bottom: toRemMob(10);


  &.item__info_specification {
    width: toRemMob(20);
    height: toRemMob(20);
  }

  @include _desktop {
    padding-bottom: toRem(20);
  }
  //margin-bottom: toRemMob(30);
  //@include _desktop {placeholder__text
  //  margin-bottom: toRem(30);
  //}
}
</style>
