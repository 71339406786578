<template>
  <v-page-greenhouse
    :key="key"
    :greenhouse="currentGreenhouse"
    :get-translate-json-field="getTranslateJsonField"
    :gallery-init="galleryInit"
    :active-variant="activeVariant"
  />
  <v-page-configurator
    :greenhouse="currentGreenhouse"
    :word-translation="wordTranslation"
    :get-translate-field="getTranslateField"
    :get-translate-json-field="getTranslateJsonField"
    :active-variant="activeVariant"
    :active-configuration="activeConfiguration"
    :current-configuration="currentConfiguration"
    :get-current-greenhouse-from-local-storage-cart="getCurrentGreenhouseFromLocalStorageCart"
    :get-min-variant-execution-price="getMinVariantExecutionPrice"
    :duration-time="durationTime"
    :open-cart-modal="openCartModal"
    @change-active-variant="changeActiveVariant"
    @change-active-configuration="changeActiveConfiguration"
    @change-active-width="changeActiveWidth"
    @added-configuration-to-cart="activeConfiguration.disable = true"
    :active-width="activeWidth"
    :configurations-widths="configurationsWidths"
  />
  <v-page-feature
    :get-translate-json-field="getTranslateJsonField"
    :word-translation="wordTranslation"
    :features="features"
    :current-greenhouse-id="currentGreenhouse.id"
  />
  <v-video-review
    v-if="currentGreenhouse.id === 1"
    :gallery-init="galleryInit"
    :videos="videos"
    :word-translation="wordTranslation"
    :get-translate-json-field="getTranslateJsonField"
    :is-greenhouse-page="true"
  />
  <v-additional
    :word-translation="wordTranslation"
    :get-translate-field="getTranslateField"
  />
  <v-completed
    v-if="Object.entries(currentGreenhouse).length"
    :get-translate-json-field="getTranslateJsonField"
    :word-translation="wordTranslation"
    :get-translate-field="getTranslateField"
    :gallery-init="galleryInit"
    :get-comment-title="getCommentTitle"
    :current-greenhouse-id="currentGreenhouse?.id"
  />
</template>

<script>
import {Axios} from "@/assets/core/http-common";
import vPageGreenhouse from "@/components/greenhouse-page/v-page-greenhouse";
import vAdditional from "@/components/index-page/v-additional";
import vCompleted from "@/components/v-completed";
import vPageFeature from "@/components/greenhouse-page/v-page-feature";
import VPageConfigurator from "@/components/greenhouse-page/v-page-configurator";
import VVideoReview from "@/components/v-video-review";
import {mapGetters} from "vuex";

const {changeMeta} = require("@/assets/core/seo");

export default {
  getGreenhousesUrl: "greenhouses/",
  props: {
    getTranslateField: Function,
    wordTranslation: Function,
    getTranslateJsonField: Function,
    galleryInit: Function,
    getMinVariantExecutionPrice: Function,
    openCartModal: Function,
    getCommentTitle: Function
  },
  inject: ["hidePagePreloader", "showPagePreloader", "deviceWidth", "change-active-width"],
  emits: {
    "init-change-seo": null
  },
  components: {
    VVideoReview,
    VPageConfigurator,
    vPageFeature,
    vPageGreenhouse,
    vAdditional,
    vCompleted,
  },
  name: "GreenhousePage",
  data() {
    return {
      currentGreenhouse: {},
      activeVariant: null,
      activeConfiguration: null,
      activeWidth: null,
      currentConfiguration: [],
      configurationAdditionalAttributes: [],
      durationTime: 500,
      features: [],
      videos: [],
    };
  },
  created() {
    this.showPagePreloader();
    this.$emit("init-change-seo", this.changeSeo);
  },
  computed: {
    key() {
      return this.deviceWidth < 640;
    },
    ...mapGetters(["currentCity", "currentCountry"]),
    filteredConfigurations() {
      return this.activeVariant
        ?.configurations
        .filter(conf => {
          const confWidth = this.getConfigurationWidth(conf)
          if (this.activeWidth) {
            return confWidth === this.activeWidth;
          }
          return true
        })
        .filter(conf => !conf.countries_hide?.includes(this.currentCountry.id)) || []
    },
    configurationsWidths() {
      const allWidths = [];
      (this.activeVariant?.configurations || []).forEach(configuration => {
        if (
          !allWidths.includes(this.getConfigurationWidth(configuration)) &&
          !configuration.countries_hide?.includes(this.currentCountry.id)
        ) {
          allWidths.push(this.getConfigurationWidth(configuration));
        }
      });
      return allWidths;
    },
  },
  mounted() {
    this.getGreenhouse();
  },
  methods: {
    getConfigurationWidth(configuration) {
      if (configuration.width !== null) {
        const language = this.currentCountry.abbreviation === 'ru' ? 'ru' : 'en';
        const configurationWidth = new Intl.NumberFormat(language).format(configuration.width);
        return `${configurationWidth} ${this.wordTranslation('price_help.measurement_distance')}`
      }
      return this.getWidthFromSize(this.getTranslateJsonField("size", configuration))
    },
    getWidthFromSize(size) {
      return size.replace(/x\d+[,.]?\d?/, '').trim()
    },
    async getGreenhouse() {
      try {
        const response = await Axios.get(`${this.$options.getGreenhousesUrl}${this.$route.params.greenhouseSlug}/`);
        if (response.data.errors || !response.data.greenhouse) return false;
        this.currentGreenhouse = response.data?.greenhouse;

        // Get data from get params
        const variantExecution = +this.$route.query["variant-execution"];
        const configurationId = +this.$route.query.configuration;
        // Set currentConfiguration how null
        let currentConfiguration = null;
        // if we have variantExecution select it
        if (variantExecution) {
          this.activeVariant = this.currentGreenhouse.variants_execution.find(variantExecutionFind => variantExecutionFind.id === variantExecution);
          // If we have configurationId find currentConfiguration
          if (configurationId) {
            currentConfiguration = this.activeVariant.configurations.find(configuration => configuration.id === configurationId);
          }
        } else {
          this.activeVariant = this.currentGreenhouse.variants_execution[0];
        }
        this.activeWidth = this.configurationsWidths[0];
        this.activeVariant.filteredConfigurations = this.filteredConfigurations;
        this.changeActiveConfiguration(currentConfiguration || this.filteredConfigurations[0]);
        this.features = this.currentGreenhouse.features;
        this.changeSeo();
        this.hidePagePreloader();
        this.getVideos();

      } catch (error) {
        if (error?.response?.status === 404) {
          this.$store.commit("updateIsNotFound", true);
        }
        console.error(error);
        return false;
      }
    },
    changeActiveVariant(newValueActiveVariant) {
      this.activeVariant = newValueActiveVariant;
      this.activeWidth = this.configurationsWidths[0];
      this.activeVariant.filteredConfigurations = this.filteredConfigurations;
      this.changeActiveConfiguration((this.filteredConfigurations)[0]);
    },
    changeActiveWidth(newValueActiveWidth) {
      this.activeWidth = newValueActiveWidth;
      this.activeVariant.filteredConfigurations = this.filteredConfigurations;
      this.changeActiveConfiguration((this.filteredConfigurations)[0]);
    },
    async changeActiveConfiguration(newValueActiveConfiguration) {
      if (!newValueActiveConfiguration) return;
      this.toggleConfiguration(newValueActiveConfiguration);
      this.activeConfiguration = newValueActiveConfiguration;
      try {
        const response = await Axios.get(`${this.$options.getGreenhousesUrl}${this.currentGreenhouse.id}/configurations/${this.activeConfiguration.id}/`);
        if (!response.data.errors && response.data.configuration) {
          this.currentConfiguration = response.data.configuration;
        }
      } catch (error) {
        console.error(error);
        return false;
      }

    },
    getCurrentGreenhouseFromLocalStorageCart() {
      let isNewGreenhouse = false;
      const localStorageCart = JSON.parse(localStorage.getItem("cart") || "{}");
      let currentGreenhouse = (localStorageCart?.greenhouses || []).find(cartItem => cartItem.greenhouse_id === this.currentGreenhouse.id);
      if (!currentGreenhouse) {
        isNewGreenhouse = true;
        currentGreenhouse = {
          greenhouse_id: this.currentGreenhouse.id,
          variants_execution: [],
        };
        if (!localStorageCart.greenhouses) {
          localStorageCart.greenhouses = [];
        }
        localStorageCart.greenhouses.push(currentGreenhouse);
      }
      currentGreenhouse.variants_execution = currentGreenhouse.variants_execution || [];
      return {currentGreenhouse, isNewGreenhouse, localStorageCart};
    },
    changeSeo() {
      const currentSeo = this.$store.getters.locales[this.$store.getters.currentCountry.abbreviation][this.$route.name];
      if (!currentSeo) {
        return;
      }
      let {title, description} = currentSeo;
      if (this.currentCity) {
        title = currentSeo.city_title;
        description = currentSeo.city_description;
      }
      title = title
        .replace("[[greenhouseTitle]]", this.getTranslateJsonField("title", this.currentGreenhouse))
        .replace("[[cityTitle]]", this.currentCity?.seo_title);
      description = description
        .replace("[[greenhouseTitle]]", this.getTranslateJsonField("title", this.currentGreenhouse))
        .replace("[[cityTitle]]", this.currentCity?.seo_title);
      changeMeta({title, description});
    },
    toggleConfiguration(item) {
      this.activeVariant?.configurations.forEach(configuration => {
        if (this.activeConfiguration !== item) {
          configuration.isOpen = false;
        }
      });
      if (this.activeConfiguration === item) {
        item.isOpen = !item.isOpen;
      } else if (this.activeConfiguration !== item) {
        setTimeout(() => {
          item.isOpen = true;
        }, this.durationTime);
      }
    },
    async getVideos() {
      const params = {
        "current-page": "GreenhousePage",
        "current-greenhouse": this.currentGreenhouse.id,
        "country-id": this.currentCountry?.id
      };
      this.videos = await Axios.get("videos/", {params}).then(({data}) => data.videos);
    }
  },
};
</script>

<style lang="scss">
.section {
  &_page-greenhouse {
    padding: toRemMob(35) 0 0;
    @include _desktop {
      padding: toRem(50) 0 toRem(46);
    }
  }

  &__title {
    &_page-greenhouse {
      display: none;
      @include _desktop {
        display: block;
        margin-bottom: toRem(50);
        font-weight: 300;
        font-size: toRem(45);
        line-height: toRem(53);
      }
    }
  }
}
</style>
