<template>
  <section class="section section_additional">
    <div class="container">
      <h2 class="section__title section__title_additional">
        {{ wordTranslation("additions_block.title") }}
      </h2>
      <div class="section__list section__list_additional">
        <v-additional-items
          :additions="additions"
          :get-translate-field="getTranslateField"
        />
      </div>
    </div>
  </section>
</template>

<script>
import vAdditionalItems from "@/components/index-page/v-additional-items";
import {Axios} from "@/assets/core/http-common";

export default {
  name: "vAdditional",
  props: {
    getTranslateField: Function,
    wordTranslation: Function
  },
  components: {
    vAdditionalItems
  },
  created() {
    this.getAdditions();
  },
  data() {
    return {
      additions: []
    };
  },
  methods: {
    async getAdditions() {
      try {
        const {data} = await Axios.get("additions/");
        this.additions = data?.additions || [];
      } catch (error) {
        console.error(error);
      }
    }

  }
};
</script>

<style lang="scss">

.section {
  &_additional {
    padding: toRemMob(30) 0 toRemMob(80);
    background-color: $color-white;
    @include _desktop {
      padding: toRem(70) 0 toRem(160);
    }
  }

  &__title {
    &_additional {
      margin-bottom: toRemMob(30);
      margin-left: toRemMob(42);
      font: 500 toRemMob(28)/toRemMob(33) "Roboto";

      @include _desktop {
        margin-bottom: toRem(60);
        margin-left: unset;
        font: 300 toRem(45)/toRem(53) "Roboto";
        color: $color-black;
      }
    }
  }

  &__list {
    &_additional {
      position: relative;
    }
  }
}
</style>

