<template>
  <modal-base class="modal_cart" ref="modalBase" @close="closeEvent" :wordTranslation="wordTranslation">
    <template #modal-title></template>
    <template #modal-error></template>
    <template #modal-success>
      <div class="modal__success">
        <div class="modal__image modal__image_success"></div>
        <h2 class="modal__title modal__title_success">{{ wordTranslation("base_modal.hooray") }}</h2>
        <p class="modal__description modal__description_success">
          {{ wordTranslation("cart_modal.success_text") }}
        </p>
      </div>
    </template>
    <template #modal-content>
      <div class="modal__columns">
        <div class="modal__column">
          <div @click="close" class="modal__close modal__close_mobile"></div>
          <h2 class="modal__title modal__title_cart-order">
            {{ wordTranslation("cart_modal.your_order") }}
          </h2>
          <div class="modal__wrapper">
            <div class="modal__empty"
                 v-if="isEmptyCart"
            >
              {{ wordTranslation("cart_modal.empty_price") }}
            </div>
            <div class="modal__list modal__list_greenhouse-cart">
              <div
                class="item item_greenhouse-cart"
                v-for="cartItem in $store.getters.cart.greenhouses"
                :key="`cart-item-${cartItem.greenhouse_id}`"
              >
                <div class="modal__list modal__list_cart">
                  <div class="item__wrapper item__wrapper_cart"
                       v-for="variantExecution in cartItem.variants_execution"
                       :key="`variant-execution-${variantExecution.id}`"
                  >
                    <h3 class="item__title item__title_cart">
                      {{ `${getTranslateJsonField("title", cartItem)} - ${getTranslateJsonField("title", variantExecution)}`
                      }}
                    </h3>
                    <div class="item__wrapper item__wrapper_specification">
                      <div
                        class="item item_cart"
                        v-for="configuration in variantExecution.configurations"
                        :key="`configuration-${configuration.id}`"
                      >
                        <div class="item__header item__header_specification">
                          <h4 @click="configuration.isOpen = !configuration.isOpen"
                              :class="{'active': configuration.isOpen}"
                              class="item__title item__title_specification">
                            <template v-if="deviceWidth > 640">
                              {{ `${getTranslateJsonField("size", configuration)} / ${getTranslateJsonField("title_full", configuration)}`
                              }}
                            </template>
                            <template v-else>
                              {{ `${getTranslateJsonField("size", configuration)} / ${getTranslateJsonField("title_short", configuration)}`
                              }}
                            </template>
                          </h4>
                          <div class="item__wrapper item__wrapper_left-position">
                            <div class="item__buttons item__buttons_specification">
                              <button
                                @click="changeConfigurationCount({cartItem, variantExecution, configuration, change: -1})"
                                :aria-label="wordTranslation('cart_modal.button_reduce')"
                                class="button button_count"
                              >
                                -
                              </button>
                              <div class="item__count">{{ configuration.count }}
                                {{ wordTranslation("price_help.piece")
                                }}
                              </div>
                              <button
                                @click="changeConfigurationCount({cartItem, variantExecution, configuration, change: 1})"
                                :aria-label="wordTranslation('cart_modal.button_increase')"
                                class="button button_count"
                              >+
                              </button>
                            </div>
                            <div class="item__price item__price-header">

                              {{ nicePrice(getTranslateJsonField("price", configuration)) }}
                              {{ wordTranslation("price_help.currency") }}
                            </div>
                            <button
                              @click="removeConfiguration({cart: $store.getters.cart, cartItem, variantExecution, configurationId: configuration.id})"
                              class="item__delete"
                            >
                            </button>
                          </div>
                        </div>
                        <slide-up-down
                          v-model="configuration.isOpen"
                          tag="ul"
                          @click.stop
                          :duration="durationTime"
                        >
                          <li class="item"
                              :class="{'item_flex': attribute.is_value_hint}"
                              v-for="attribute in configuration.attributes"
                              :key="`attribute-${attribute.id}`"
                          >
                        <span>
                          {{ getTranslateJsonField("category", attribute) }}
                                                    <span v-if="attribute.hint"
                                                          class="item__wrapper item__wrapper_modal-specification"
                                                          :class="{'item__wrapper_right-position': attribute.is_value_hint}">
                                                      <tippy>
                                                        <div class="item__info_specification"></div>
                                                        <template #content>
                                                          {{ attribute.image }}
                                                          <div v-if="attribute.hint.image !== null" class="tippy-image">
                                                            <img
                                                              :src='$host + attribute.hint?.image'
                                                              :alt='getTranslateJsonField("text", attribute.hint)'>
                                                          </div>
                                                           <p v-if="attribute.hint.text !== null"
                                                              v-html="getTranslateJsonField('text', attribute.hint)"
                                                              class="tippy-description">
                                                          </p>
                                                        </template>
                                                      </tippy>
                                                    </span>
                          <template
                            v-if="attribute.value">{{ ": " + getTranslateJsonField("value", attribute) }}</template>
                        </span>
                          </li>
                        </slide-up-down>
                      </div>
                    </div>
                  </div>
                  <template v-if="(cartItem?.additional_attributes || []).length">
                    <h2 class="modal__title modal__title_additionally">
                      {{ wordTranslation("cart_modal.additional_products") }}
                    </h2>
                    <div class="modal__list modal__list_additionally">
                      <div
                        v-for="additionalAttribute in cartItem.additional_attributes"
                        :key="`cart-additional-attribute-${additionalAttribute.id}`"
                        class="item item_additionally-order"
                      >
                        <div v-html="getTranslateJsonField('category', additionalAttribute)"
                             class="item__description item__description_additionally-order item__description_added">
                        </div>
                        <div class="item__price item__price_additionally-order">
                          <template v-if="additionalAttribute.is_individual">
                            {{ wordTranslation("price_help.individual_calculation") }}
                          </template>
                          <template v-else>
                            {{ getTranslateJsonField("price_prefix", additionalAttribute) ? getTranslateJsonField("price_prefix", additionalAttribute) : ""
                            }}
                            {{ nicePrice(getTranslateJsonField("price", additionalAttribute)) }}
                            {{ wordTranslation("price_help.currency") }}/
                            <template v-if="getTranslateJsonField('quantity_price', additionalAttribute)?.length">
                              {{ getTranslateJsonField("quantity_price", additionalAttribute) }}
                            </template>
                          </template>
                          <button
                            @click="removeAdditionalAttributesFromCart({greenhouse: cartItem, additionalAttribute})"
                            class="item__delete"></button>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="getAdditionallyAdditionalAttributes(cartItem)?.length">
                    <h2 class="modal__title modal__title_additionally-order"
                        :class="{'active': cartItem.isOpenAdditionally}"
                        @click="cartItem.isOpenAdditionally = !cartItem.isOpenAdditionally"
                        @click.once="cartItem.isOpenOnce = false"
                    >
                      {{
                        wordTranslation("cart_modal.you_can_order")
                          .replace("[[greenhouse]]", getTranslateJsonField("title", cartItem))
                      }}
                    </h2>
                    <slide-up-down
                      @click.once="cartItem.isOpenOnce = false"
                      v-model="cartItem.isOpenAdditionally"
                      :duration="durationTimeAdditionally"
                      :class="{active: cartItem.isOpenAdditionally, 'is-open-once': cartItem.isOpenOnce}"
                      class="modal__list-wrapper"
                    >
                      <div @click.stop class="modal__list modal__list_additionally">
                        <div
                          v-for="additionalAttribute in getAdditionallyAdditionalAttributes(cartItem)"
                          :key="`additional-attribute-${additionalAttribute.id}`"
                          class="item item_additionally-order"
                        >
                          <div class="item__description item__description_additionally-order">
                            <input type="checkbox" v-model="additionalAttribute.value" class="default__check">
                            <span class="custom__check"
                                  @click.stop="additionalAttribute.value = !additionalAttribute.value"></span>
                            <span v-html="getTranslateJsonField('category', additionalAttribute)"
                                  @click="additionalAttribute.value = !additionalAttribute.value">
                                      </span>
                            <div
                              v-if="additionalAttribute.hint"
                              class="item__wrapper item__wrapper_info-additionally-order"
                            >
                              <tippy>
                                <div class="item__info_additionally-order"></div>
                                <template #content>
                                  <div class="tippy-image">
                                    <img :src='$host + additionalAttribute.hint?.image'
                                         :alt='getTranslateJsonField("text", additionalAttribute.hint)'>
                                  </div>
                                  <p v-html="getTranslateJsonField('text', additionalAttribute.hint)"
                                     class="tippy-description">
                                  </p>
                                </template>
                              </tippy>
                            </div>
                          </div>
                          <div class="item__price item__price_additionally-order">
                            <template v-if="additionalAttribute.is_individual">
                              {{ wordTranslation("price_help.individual_calculation") }}
                            </template>
                            <template v-else>
                              {{ getTranslateJsonField("price_prefix", additionalAttribute) ? getTranslateJsonField("price_prefix", additionalAttribute) : ""
                              }}
                              {{ nicePrice(getTranslateJsonField("price", additionalAttribute)) }}
                              {{ wordTranslation("price_help.currency") }}/
                              <template v-if="getTranslateJsonField('quantity_price', additionalAttribute)?.length">
                                {{ getTranslateJsonField("quantity_price", additionalAttribute) }}
                              </template>
                            </template>
                          </div>
                        </div>
                      </div>
                      <button @click.stop="appendAdditionallyAdditionalAttributesToCart(cartItem)"
                              class="button button_add-to-order">
                        {{ wordTranslation("buttons.add_to_order") }}
                      </button>
                    </slide-up-down>
                  </template>
                </div>
              </div>
            </div>
            <div class="modal__sum">
              {{
                `${wordTranslation("price_help.result")} ${nicePrice(getFullPrice())} ${wordTranslation("price_help.currency")}`
              }}
            </div>
            <button @click="isOpenScreen = !isOpenScreen" v-if="deviceWidth < 640"
                    class="button button_modal button_modal-next">
              {{ wordTranslation("buttons.next") }}
            </button>
          </div>
        </div>
        <div class="modal__column" :class="{'active': isOpenScreen}">
          <div @click="close" class="modal__close modal__close_mobile"></div>
          <h2 class="modal__title modal__title_cart">
            {{ wordTranslation("cart_modal.title_modal") }}
          </h2>
          <div class="modal__list modal__list_form">
            <div class="item item_modal">
              <div class="item__title item__title_modal">
                {{ wordTranslation("cart_modal.fio_input") }}&nbsp;<span>*</span>
              </div>
              <input
                @input="fioError = ''"
                class="input input_modal input_modal-name"
                v-model="fio"
                type="text"
                name="fio"
                :placeholder="wordTranslation('cart_modal.fio_placeholder')"
              >
              <div class="error__placeholder" v-show="fioError.length">
                {{ fioError }}
              </div>
            </div>
            <div class="item item_modal">
              <div class="item__title item__title_modal">
                &nbsp;{{ wordTranslation("cart_modal.phone_input") }}<span>*</span>
              </div>
              <modalWindowMask
                @update:phone="phone = $event.phone, phoneObject = $event.phoneObject"
                @input="phoneError = ''"
                :phone="phone"
                :word-translation="wordTranslation"
                ref="modalWindowMask"
              />
              <div class="error__placeholder" v-show="phoneError.length">
                {{ phoneError }}
              </div>
            </div>
            <div class="item item_modal">
              <div class="item__title item__title_modal item__title_modal-mail">
                {{ wordTranslation("cart_modal.email_input") }}
                <div class="item__wrapper item__wrapper_info-modal item__wrapper_email">
                  <tippy trigger="click">
                    <div class="item__info item__info_modal"></div>
                    <template #content>
                      <p class="tippy-description">
                        {{ wordTranslation("cart_modal.email_hint") }}
                      </p>
                    </template>
                  </tippy>
                </div>
              </div>
              <input
                @input="emailError = ''"
                v-model="email"
                :placeholder="wordTranslation('cart_modal.email_placeholder')"
                class="input input_modal input_modal-mail"
                type="email"
                name="email"
              >
              <div class="error__placeholder" v-show="emailError.length">
                {{ emailError }}
              </div>
            </div>
            <div class="item item_modal">
              <div class="item__title item__title_modal item__title_modal-mail">
                {{ wordTranslation("cart_modal.address_input") }}
              </div>
              <input
                @input="addressError = ''"
                v-model="address"
                :placeholder="wordTranslation('cart_modal.address_placeholder')"
                class="input input_modal input_modal-mail"
                type="text"
                name="address"
              >
              <div class="error__placeholder" v-show="addressError.length">
                {{ addressError }}
              </div>
            </div>
            <div class="item item_modal">
              <div class="item__title item__title_modal">
                {{ wordTranslation("cart_modal.comment_input") }}
              </div>
              <textarea
                @input="commentError = ''"
                v-model="comment"
                :placeholder="wordTranslation('cart_modal.comment_placeholder')"
                class="textarea textarea_modal textarea_modal_cart"
              ></textarea>
              <div class="error__placeholder" v-show="commentError.length">
                {{ commentError }}
              </div>
            </div>
          </div>
          <div class="modal__wrapper modal__wrapper_buttons">
            <button v-if="deviceWidth < 640" @click="isOpenScreen = !isOpenScreen" class="button button_back">
              {{ wordTranslation("buttons.back") }}
            </button>
            <button class="button button_modal button_modal-cart button_green"
                    @click="makeOrder">
              {{ wordTranslation("buttons.order") }}
            </button>
          </div>
          <p class="modal__politic">
            {{ wordTranslation("cart_modal.politic_text") }}<br>
            <a class="link link_blue link_modal" download="user_agreement.pdf" href="/user_agreement.pdf">
              {{ wordTranslation("base_modal.personal_data") }}
            </a>
          </p>
        </div>
      </div>
      <div @click="close" class="modal__close modal__close_desktop"></div>
    </template>
    <template #modal-confirm>
      <div></div>
    </template>
  </modal-base>
</template>

<script>

import ModalBase from "@/components/modal/ModalBase";
import modalWindowMask from "@/components/modal/ModalWindowMask";
import SlideUpDown from "vue3-slide-up-down";

export default {
  name: "ModalCart",
  components: {
    ModalBase,
    modalWindowMask,
    SlideUpDown
  },
  props: {
    getTranslateField: Function,
    getTranslateJsonField: Function,
    wordTranslation: Function,
  },
  inject: ["nicePrice", "deviceWidth", "getFakeOldPrice"],
  data() {
    return {
      fio: "",
      fioError: "",
      phone: "",
      phoneError: "",
      email: "",
      emailError: "",
      address: "",
      addressError: "",
      comment: "",
      commentError: "",
      phoneObject: {},
      isOpenScreen: false,
      durationTime: 500,
      isOpenAdditionally: true,
      isOpenOnce: true,
      durationTimeAdditionally: 500,
    };
  },
  methods: {
    open() {
      return this.$refs.modalBase.open();
    },
    close() {
      return this.$refs.modalBase.close();
    },
    closeEvent() {
      this.code = "";
      this.codeError = "";
    },
    confirm(modalData) {
      return this.$refs.modalBase.confirm(modalData);
    },
    inputCode() {
      this.codeError = "";
    },
    getFullPrice() {
      const cartItemsPrice = (this.$store.getters.cart.greenhouses || []).map(cartItem => {
        const configurationPrices = (cartItem.variants_execution || []).map(variantExecution => {
          return (variantExecution.configurations || []).map(configuration => {
            return (this.getTranslateJsonField("price", configuration) || 0) * (configuration.count || 1);
          });
        }).flat();
        const additionalAttributePrices = cartItem.additional_attributes.map(additionalAttribute => {
          return this.getTranslateJsonField("price", additionalAttribute) || 0;
        });
        return [...configurationPrices.concat(additionalAttributePrices)].reduce((a, b) => a + b, 0);
      });
      return (cartItemsPrice || []).reduce((a, b) => a + b, 0);
    },
    removeConfiguration(data) {
      const {cart, cartItem, variantExecution, configurationId} = data;
      variantExecution.configurations = variantExecution.configurations.filter(configuration => {
        return configuration.id !== configurationId;
      });
      if (!variantExecution.configurations.length) {
        cartItem.variants_execution = cartItem.variants_execution.filter(variant => variant.id !== variantExecution.id);
        if (!cartItem.variants_execution.length) {
          cart.greenhouses = cart.greenhouses.filter(greenhouseFilter => greenhouseFilter.greenhouse_id !== cartItem.greenhouse_id);
        }
      }
      this.removeConfigurationFromLocalStorage(data);
    },
    removeConfigurationFromLocalStorage(data) {
      const {cartItem, variantExecution, configurationId} = data;
      const localStorageCart = JSON.parse(localStorage.getItem("cart") || "{}");
      const currentGreenhouse = localStorageCart.greenhouses.find(cartItemFind => cartItemFind.greenhouse_id === cartItem.greenhouse_id);
      const currentVariant = currentGreenhouse.variants_execution.find(variantExecutionFind => variantExecutionFind.id === variantExecution.id);
      currentVariant.configurations = currentVariant.configurations.filter(configuration => configuration.id !== configurationId);
      if (!currentVariant.configurations.length) {
        currentGreenhouse.variants_execution = currentGreenhouse.variants_execution.filter(variant => variant.id !== currentVariant.id);
        if (!currentGreenhouse.variants_execution.length) {
          localStorageCart.greenhouses = localStorageCart.greenhouses.filter(greenhouseFilter => greenhouseFilter.greenhouse_id !== currentGreenhouse.greenhouse_id);
        }
      }
      localStorage.setItem("cart", JSON.stringify(localStorageCart));
    },
    changeConfigurationCount(data) {
      const {configuration, change} = data;
      configuration.count += change;
      if (configuration.count <= 0) {
        return this.removeConfiguration({
          cartItem: data.cartItem,
          variantExecution: data.variantExecution,
          configurationId: data.configuration.id
        });
      }
      this.changeConfigurationCountLocalStorage(data);
    },
    changeConfigurationCountLocalStorage(data) {
      const {cartItem, variantExecution, configuration, change} = data;
      const localStorageCart = JSON.parse(localStorage.getItem("cart") || "{}");
      const currentGreenhouse = (localStorageCart.greenhouses || []).find(cartItemFind => cartItemFind.greenhouse_id === cartItem.greenhouse_id);
      const currentVariant = (currentGreenhouse.variants_execution || []).find(variantExecutionFind => variantExecutionFind.id === variantExecution.id);
      currentVariant.configurations = (currentVariant.configurations || []).map(configurationMap => {
        if (configurationMap.id === configuration.id) {
          configurationMap.count += change;
        }
        return configurationMap;
      });
      localStorage.setItem("cart", JSON.stringify(localStorageCart));
    },
    regexValidation(re, str) {
      return re.test(str);
    },
    fioValidation() {
      if (!this.regexValidation(/^[\w \-А-я]{1,128}$/, this.fio)) {
        this.fioError = this.wordTranslation("base_modal.fio_error");
        return false;
      }
      this.fioError = "";
      return true;
    },
    phoneValidation() {
      if (!this.phoneObject.valid) {
        this.phoneError = this.wordTranslation("base_modal.phone_error");
        return false;
      }
      this.phoneError = "";
      return true;
    },
    emailValidation() {
      if (this.email.length && !this.regexValidation(/^([\wА-я.-]+)@([\w.-]+)\.([A-z]{1,10})$/, this.email)) {
        this.emailError = this.wordTranslation("base_modal.email_error");
        return false;
      }
      this.emailError = "";
      return true;
    },
    addressValidation() {
      if (!this.regexValidation(/^[\wА-я.,–+=%?!()№*:;\r\n«»$"'\-/ ]{0,1024}$/, this.address)) {
        this.addressError = this.wordTranslation("base_modal.address_error");
        return false;
      }
      this.addressError = "";
      return true;
    },
    commentValidation() {
      if (!this.regexValidation(/^[\wА-я.,–+=%?!()№*:;\r\n«»$"'\-/ ]{0,1024}$/, this.comment)) {
        this.commentError = this.wordTranslation("base_modal.comment_error");
        return false;
      }
      this.commentError = "";
      return true;
    },
    makeOrder() {
      if (!this.isValidForm) return;
      const modalData = {
        fio: this.fio,
        phone: this.phoneObject.formatted,
        email: this.email,
        address: this.address,
        comment: this.comment,
        cart: this.$store.getters.cart,
        full_price: this.getFullPrice()
      };
      this.confirm(modalData);
    },
    appendAdditionallyAdditionalAttributesToCart(greenhouse) {
      // Get selected additional attributes
      const selectedAdditionalAttributes = this.getAdditionallyAdditionalAttributes(greenhouse)
        .filter(additionalAttribute => additionalAttribute.value);
      this.appendAdditionallyAdditionalAttributesToStorageCart({greenhouse, selectedAdditionalAttributes});
      this.appendAdditionallyAdditionalAttributesToLocalstorageCart({greenhouse, selectedAdditionalAttributes});
      this.$metrika.reachGoal("cartAddAdditionalProducts");
    },
    appendAdditionallyAdditionalAttributesToStorageCart(data) {
      const {greenhouse, selectedAdditionalAttributes} = data;

      const cart = this.$store.getters.cart;
      greenhouse.additional_attributes = (greenhouse.additional_attributes || []).concat(selectedAdditionalAttributes);
      this.$store.commit("updateCart", cart);
    },
    appendAdditionallyAdditionalAttributesToLocalstorageCart(data) {
      const {greenhouse, selectedAdditionalAttributes} = data;
      const localStorageCart = JSON.parse(localStorage.getItem("cart") || "{}");
      const currentGreenhouse = (localStorageCart.greenhouses || []).find(greenhouseFind => greenhouseFind.greenhouse_id === greenhouse.greenhouse_id);
      currentGreenhouse.additional_attributes = (currentGreenhouse.additional_attributes || []).concat(
        selectedAdditionalAttributes.map(additionalAttribute => {
          return {id: additionalAttribute.id};
        })
      );
      // save localstorage
      localStorage.setItem("cart", JSON.stringify(localStorageCart));
    },
    removeAdditionalAttributesFromCart(data) {
      const {greenhouse, additionalAttribute} = data;
      // append it in store
      greenhouse.additional_attributes = greenhouse.additional_attributes.filter(additionalAttributeFilter => additionalAttributeFilter.id !== additionalAttribute.id);
      // Оставляет те, которые не надо удалять

      // append to localstorage
      const localStorageCart = JSON.parse(localStorage.getItem("cart") || "{}");
      const currentGreenhouse = (localStorageCart.greenhouses || []).find(cartItemFind => cartItemFind.greenhouse_id === greenhouse.greenhouse_id);

      // Оставляет те, которые не надо удалять (у нас в local storage лежать только id, поэтому мы и делали additionalAttributesForRemove)
      currentGreenhouse.additional_attributes = currentGreenhouse.additional_attributes.filter(additionalAttributeFilter => {
        return additionalAttributeFilter.id !== additionalAttribute.id;
      });
      // save localstorage
      localStorage.setItem("cart", JSON.stringify(localStorageCart));
    },
    showPreloader() {
      return this.$refs.modalBase.showPreloader();
    },
    hidePreloader() {
      return this.$refs.modalBase.hidePreloader();
    },
    async showError() {
      return new Promise(resolve => {
        this.$refs.modalBase.showError();
        setTimeout(() => {
          this.$refs.modalBase.hideError();
          resolve();
        }, 3000);
      });
    },
    async showSuccess() {
      return new Promise(resolve => {
        this.$refs.modalBase.showSuccess();
        setTimeout(() => {
          this.fio = "";
          this.phone = "";
          this.email = "";
          this.address = "";
          this.comment = "";
          this.$refs.modalWindowMask.clearPhone();
          this.$refs.modalBase.hideSuccess();
          this.$refs.modalBase.close();
          resolve();
        }, 3000);
      });
    },
    getGreenhouseAdditional(greenhouse) {
      return greenhouse.additional_attributes;
      // // Проходимся по всем варианта исполнения.
      // // Затем по всем конфигурациям в этом варианте исполнения.
      // // Затем возвращаем additional_attributes у каждой конфигурации и распаковываем с помощью flat
      // return (greenhouse?.variants_execution || [])
      //   .map(variantExecution => (variantExecution?.configurations || [])
      //     .map(configuration => configuration.additional_attributes))
      //   .flat(Infinity)
      //   .filter((additionalAttribute, index, self) => {
      //     // Надо выбрать только уникальные.
      //     // проверям что значение, которые мы нашли (indexOf) === index текущего элемента.
      //     // Проще говоря у нас есть [1, 2, 1]. Индексы этих элемнтов будут такие: 0, 1, 2.
      //     // Проходясь в первый раз по по единице ее индекс будет 0. У двойки 1.
      //     // А вот у втрой единицы индекс будет так же 0. Так как мы indexOf возвращает индекс 1-го найденного элемента
      //     // И получается, что индекс единицы (0), не равен ее порядковому номеру в filter (2). И мы ее не возьмем.
      //     // Но так как у нас объекты, причем на самом деле разные, то у нас сраная проверка на кетгорию и цену.
      //     // Что скорее всего выстрели в ногу :)
      //     return self.findIndex(additionalAttributeFindIndex => {
      //       return this.getTranslateJsonField("category", additionalAttributeFindIndex) === this.getTranslateJsonField("category", additionalAttribute) &&
      //         this.getTranslateJsonField("price", additionalAttributeFindIndex) === this.getTranslateJsonField("price", additionalAttribute);
      //     }) === index;
      //   });

    },
    getGreenhouseAllAdditionalAttributes(greenhouse) {
      // iterating on greenhouse.variants_execution.configurations.additional_attributes and collecting in 1 array
      const additionalAttributes = (greenhouse?.variants_execution || [])
        .map(variantExecution => (variantExecution?.configurations || [])
          .map(configuration => configuration.additional_attributes))
        .flat(Infinity);
      // get only unique attributes
      return additionalAttributes.filter((additionalAttribute, index, selfArray) => {
        // if current index === findIndex in this array, it's additionalAttribute is unique
        return index === selfArray.findIndex(additionalAttributeFindIndex => additionalAttribute.id === additionalAttributeFindIndex.id);
      });
    },
    getAdditionallyAdditionalAttributes(greenhouse) {
      // Tak all selected additional attributes
      const alreadyAppended = greenhouse.additional_attributes;
      // filter all additional attributes, which not in "alreadyAppended".
      // After filter, sort on order
      return (this.getGreenhouseAllAdditionalAttributes(greenhouse) || []).filter(additionalAttribute => {
        return alreadyAppended.findIndex(additionalAttributeFindIndex => additionalAttributeFindIndex.id === additionalAttribute.id) === -1;
      }).sort((a, b) => {
        // Fucking sorting for three hundred bucks
        // If order's first additional attribute === orders' second, sort on is_individual price (not individual price and after individual price)
        if (a.order === b.order) {
          return a.is_individual - b.is_individual
        }
        return a.order - b.order
      });

    }
  },
  computed: {
    isValidForm() {
      this.fioValidation();
      this.phoneValidation();
      this.emailValidation();
      this.addressValidation();
      this.commentValidation();
      return !(
        this.fioError.length ||
        this.phoneError.length ||
        this.emailError.length ||
        this.addressError.length ||
        this.commentValidation.length ||
        this.isEmptyCart
      );
    },
    isEmptyCart() {
      return !this.$store.getters.cart.greenhouses?.length && !(this.$store.getters.cart.greenhouses || []).map(greenhouse => greenhouse.additional_attributes?.length || 0).reduce((a, b) => a + b, 0);
    }
  }
};

</script>

<style lang="scss">

.button {
  &_count {
    width: toRemMob(19) !important;
    min-width: toRemMob(19);
    max-width: toRemMob(19);
    height: toRemMob(19);
    min-height: toRemMob(19);
    max-height: toRemMob(19);
    border-radius: 50% !important;
    font-weight: 300 !important;
    font-size: toRemMob(18);
    color: #1D1D1D;
    background-color: #EDEDED !important;
    padding: 0;

    @include _desktop {
      width: toRem(21) !important;
      min-width: toRem(21);
      max-width: toRem(21);
      height: toRem(21);
      min-height: toRem(21);
      max-height: toRem(21);
      font-size: toRem(18);
    }
  }

  &_modal-cart {
    font-size: toRemMob(18);
    line-height: toRemMob(21);
    padding: toRemMob(15) toRemMob(34);
    @include _desktop {
      margin-top: toRem(27);
      padding: toRem(16) toRem(83);
      font-size: toRem(18);
      line-height: toRem(21);
    }
  }

  &_modal-additionally {
    min-width: toRemMob(98);
    padding: toRemMob(7) toRemMob(21);
    border: toRemMob(1) solid $color-grey-19 !important;
    border-radius: toRemMob(3);
    font-weight: 300;
    font-size: toRemMob(14);
    line-height: toRemMob(16);
    color: $color-grey-20;

    &.active, &:hover {
      border-color: $color-green-2;
    }

    @include _desktop {
      min-width: toRem(68);
      padding: toRem(7) toRem(21);
      border: toRem(1) solid $color-grey-19;
      border-radius: toRem(3);
      font-size: toRem(14);
      line-height: toRem(16);
    }
  }

  &_add-to-order {
    display: block;
    order: 7;
    padding: toRemMob(10) toRemMob(41);
    margin-left: auto;
    margin-top: toRemMob(40);
    font-size: toRemMob(14);
    line-height: toRemMob(19);
    text-align: center;
    color: #777777;
    border-radius: toRemMob(4) !important;
    border: toRemMob(1) solid #21A73F !important;
    @include _desktop {
      order: unset;
      padding: toRem(12) toRem(72);
      margin-left: auto;
      margin-top: toRem(40);
      margin-right: toRem(32);
      border-radius: toRem(4) !important;
      font-size: toRem(18);
      line-height: toRem(24);
    }

    &:hover {
      color: $color-white;
      background-color: $color-green-2;
    }
  }

  &_modal-next {
    order: 8;
    padding: toRemMob(12) toRemMob(38);
    margin: toRemMob(28) 0 0 auto;
    background-color: #43B548 !important;
    font-weight: 500;
    font-size: toRemMob(14);
    line-height: toRemMob(17);
    @include _desktop {
      display: none;
    }
  }

  &_back {
    display: block;
    font-weight: 400;
    font-size: toRemMob(16);
    line-height: toRemMob(19);
    text-align: center;
    color: #3A3A3A;
    @include _desktop {
      display: none;
    }

  }
}

.modal {
  &__empty {
    margin-left: toRemMob(10);
    margin-top: toRemMob(30);
    font-weight: 400;
    font-size: toRemMob(14);
    line-height: toRemMob(18);
    color: #1D1D1D;
    order: 3;

    @include _desktop {
      margin-left: toRem(4);
      margin-top: toRem(30);
      font-weight: 300;
      font-size: toRem(18);
      line-height: toRem(22);
      order: unset;
    }
  }

  &__columns {
    display: flex;
    overflow: hidden;
    @include _desktop {
      flex-direction: row;
    }
  }

  &_cart {
    .modal__body {
      padding: 0;
      margin: 0;

      @include _desktop {
        margin: unset;
        max-width: 90%;
        min-width: unset;
      }
    }

    .modal__header {
      display: none;
    }

    .modal__content {
      margin: 0 0 0 0 !important;
      @include _desktop {
        margin: 0 0 0 0 !important;
        overflow: auto;
        height: 100%;
      }

      ::-webkit-scrollbar {
        @include _desktop {
          z-index: 9999;
          width: toRem(4);
        }
      }

      ::-webkit-scrollbar-thumb {
        @include _desktop {
          background-color: #CFCFCF;
          z-index: 9999;
          border-radius: toRemMob(5);
          cursor: pointer;
        }
      }

      ::-webkit-scrollbar-thumb:hover {
        @include _desktop {
          background-color: #CFCFCF;
        }
      }

      ::-webkit-scrollbar-track {
        @include _desktop {
          background-color: #fff;
          border-radius: toRemMob(5);
        }
      }
    }
  }

  &__wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    @include _desktop {
      //height: toRem(686);
      padding: 0 toRem(6) toRem(10) toRem(35);
    }

    &_buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      height: unset;
      margin-top: toRemMob(27);
      @include _desktop {
        margin-top: unset;
      }
    }
  }

  &__column {
    padding: toRemMob(32) toRemMob(32) toRemMob(32);
    min-width: 100%;
    @include _desktop {
      padding: 0;
      min-width: unset;
    }

    &:first-child {
      @include _desktop {
        width: toRem(849);
        padding-right: toRem(30);
      }
    }

    &:last-child {
      min-width: 100%;
      left: 0;
      transition: all .2s ease;
      background-color: #fff;
      position: relative;
      @include _desktop {
        position: unset;
        display: block;
        padding: toRem(50) toRem(54) toRem(33) toRem(56);
        width: toRem(581);
        min-width: unset;
        box-shadow: 0 toRem(4) toRem(25) rgba(0, 0, 0, 0.1);
      }

      .modal__title_cart {
        margin-bottom: toRemMob(50);
        margin-left: 0;
        font-weight: 300;
        font-size: toRemMob(22);
        line-height: toRemMob(26);
        color: #3A3A3A;
        @include _desktop {
          margin-bottom: toRem(44);
          font-size: toRem(28);
          line-height: toRem(33);
          color: #000000;
        }
      }

      &.active {
        position: fixed;
        z-index: 9999;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        overflow: auto;
        //left: -100%;
        @include _desktop {
          position: unset;
          z-index: unset;
          left: unset;
          right: unset;
          top: unset;
          bottom: unset;
          margin: unset;
          //left: unset;
        }
      }
    }
  }

  &__list-wrapper {
    order: 6;

    &.is-open-once {
      height: unset !important;
    }
  }

  &__list {
    &_form {
      width: 100%;
    }

    &_additionally {
      display: flex;
      flex-direction: column;
      margin-top: toRemMob(30);
      margin-left: toRemMob(10);
      order: 4;

      @include _desktop {
        margin-top: toRem(24);
        margin-left: toRem(4);
        margin-right: toRem(31);
        order: 3;
      }

      + .modal__title {
        margin-top: toRemMob(52);
        @include _desktop {
          margin-top: toRem(52);
        }
      }
    }

    &_cart {
      order: 1;
    }

    + .modal__title_modal-additionally {
      margin-top: toRemMob(74);
      @include _desktop {
        margin-top: toRem(74);
      }
    }

    &_greenhouse-cart {
      @include _desktop {
        max-height: toRem(601);
        min-height: toRem(601);
        overflow: auto;
      }
    }
  }

  &__title {
    &_cart {
      margin-bottom: toRemMob(20);
      @include _desktop {
        margin-bottom: toRem(20);
      }
    }

    &_additionally {
      margin-top: toRemMob(36);
      margin-left: toRemMob(10);
      font-weight: 400;
      font-size: toRemMob(15);
      line-height: toRemMob(18);
      color: #1D1D1D;
      order: 3;

      @include _desktop {
        margin-left: toRem(4);
        font-size: toRem(18);
        line-height: toRem(22);
        order: 2;
      }
    }

    &_additionally-order {
      position: relative;
      order: 6;
      margin-top: toRemMob(24);
      margin-bottom: toRemMob(0) !important;
      padding-left: toRemMob(10);
      padding-right: toRemMob(18);
      width: 100%;
      font-weight: 400;
      font-size: toRemMob(15);
      line-height: toRemMob(18);
      color: #1D1D1D;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: toRemMob(12);
        height: toRemMob(12);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("../../assets/images/icons/specification.svg");
        transform: rotate(180deg);
        transition: all .2s ease;
        @include _desktop {
          width: toRem(30);
          height: toRem(30);
          background-size: 50%;
        }
      }

      &.active {
        &:before {
          transform: rotate(0);
          transition: all .2s ease;
        }
      }

      @include _desktop {
        width: max-content;
        margin-top: toRem(34);
        padding-left: toRem(4);
        padding-right: toRem(33);
        font-size: toRem(18);
        line-height: toRem(22);
        order: 5;
      }
    }

    &_cart-order {
      margin-bottom: toRemMob(18);
      font-size: toRemMob(22);
      line-height: toRemMob(26);
      @include _desktop {
        padding: toRem(50) 0 toRem(20) toRem(42);
        margin-bottom: 0;
        font-size: toRem(28);
        line-height: toRem(38);
      }
    }

  }

  &__sum {
    order: 8;
    display: flex;
    justify-content: flex-end;
    margin-top: toRemMob(64);
    font-weight: 400;
    font-size: toRemMob(19);
    line-height: toRemMob(23);
    color: #121212;
    @include _desktop {
      order: 4;
      margin-right: toRem(32);
      margin-top: toRem(60);
      margin-bottom: toRem(60);
      font-size: toRem(28);
      line-height: toRem(33);
      font-weight: 300;
    }
  }

  &__close {
    &_desktop {
      display: none;
      @include _desktop {
        display: block;
      }
    }

    &_mobile {
      display: block;
      position: relative;
      top: unset;
      right: unset;
      @include _desktop {
        display: none;
      }
    }
  }

}

.item {
  &__price {
    &-header {
      display: flex;
      justify-content: flex-end;
      font-size: toRemMob(14);
      line-height: toRemMob(19);
      white-space: nowrap;
      @include _desktop {
        min-width: toRem(125);
        font-size: toRem(14);
        line-height: toRem(16);
      }
    }
  }

  &__count {
    min-width: toRemMob(56);
    max-width: toRemMob(56);
    font-weight: 300;
    font-size: toRemMob(14);
    line-height: toRemMob(18);
    white-space: nowrap;
    color: #1D1D1D;
    text-align: center;

    @include _desktop {
      min-width: unset;
      max-width: unset;
      margin: 0 toRem(12);
      font-size: toRem(18);
      line-height: toRem(22);
    }
  }

  &__wrapper {
    &_left-position {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
    }

    &_info-modal {
      display: inline-block;
      margin-left: toRemMob(5);
      margin-right: toRemMob(4);
      margin-bottom: toRemMob(-3);
      @include _desktop {
        margin-left: toRem(4);
        margin-right: toRem(4);
        margin-bottom: unset;
      }
    }

    &_email {
      margin-bottom: unset;
    }

    &_info-modal-additionally {
      display: inline-block;
      margin-bottom: toRemMob(-3);
      @include _desktop {
        margin-bottom: toRem(-3);
        margin-left: toRem(3);
      }
    }
  }

  &__info {
    &_modal {
      width: toRemMob(18);
      height: toRemMob(18);
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/icons/info.svg");
      cursor: pointer;
      @include _desktop {
        width: toRem(18);
        height: toRem(18);
      }
    }

    &_modal-additionally {
      width: toRemMob(18);
      height: toRemMob(18);
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/icons/info.svg");
      cursor: pointer;
      @include _desktop {
        width: toRem(18);
        height: toRem(18);
      }
    }
  }

  &_cart {
    padding: toRemMob(36) 0 toRemMob(36) 0;
    border-bottom: toRemMob(1) solid #DDDDDD;
    @include _desktop {
      padding: toRem(26) 0 toRem(26) 0;
      border-bottom: toRem(1) solid #DDDDDD;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        width: 100%;
        padding: toRemMob(7) toRemMob(10) toRemMob(6);
        margin-bottom: toRemMob(10);
        list-style-type: none;
        font-size: toRemMob(15);
        line-height: toRemMob(19);
        font-weight: 300;
        color: #1D1D1D;
        border-radius: toRemMob(5);

        @include _desktop {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: toRem(10) toRem(32) toRem(9) toRem(20);
          margin-bottom: toRem(11);
          font-size: toRem(16);
          line-height: toRem(19);
          border-radius: toRem(5);
        }

        &:first-child {
          margin: toRemMob(7) 0 0 0;
          @include _desktop {
            margin: toRem(13) 0 0 0;
          }
        }

        &.item_flex {
          display: flex;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:nth-child(2n) {
          background-color: #FAFAFA;
        }

        > span {
          &:first-child {
            display: inline-flex;
            align-items: center;
          }

          > span {
            display: inline-block;
            margin-left: toRemMob(4);
            margin-right: toRemMob(4);
            margin-top: toRemMob(-2);
            @include _desktop {
              margin-left: toRem(4);
              margin-right: toRem(4);
              margin-top: unset;
            }
          }
        }
      }
    }

    &:not(:last-child) {

      .item__header_specification {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &__title {
    &_cart {
      font-weight: 400;
      font-size: toRemMob(24);
      line-height: toRemMob(28);
      color: #121212;

      @include _desktop {
        margin-bottom: toRem(5);
        margin-left: toRem(7);
        font-size: toRem(24);
        line-height: toRem(28);
      }
    }

    &_specification {
      position: relative;
      padding-right: toRemMob(18);
      font-weight: 300;
      cursor: pointer;
      font-size: toRemMob(15);
      line-height: toRemMob(19);
      max-width: toRemMob(148);
      min-width: toRemMob(148);
      @include _desktop {
        padding-right: toRem(33);
        font-size: toRem(18);
        line-height: toRem(22);
        white-space: nowrap;
        max-width: unset;
        min-width: unset;
      }

      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: toRemMob(12);
        height: toRemMob(12);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("../../assets/images/icons/specification.svg");
        transform: rotate(180deg);
        transition: all .2s ease;

        @include _desktop {
          width: toRem(30);
          height: toRem(30);
          background-size: 50%;
        }
      }

      &.active {
        &:before {
          transform: rotate(0);
          transition: all .2s ease;
        }
      }
    }
  }

  &__header {
    &_specification {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include _desktop {
        padding-right: toRem(36);
        margin-left: toRem(8);
      }
    }
  }

  &__delete {
    margin-left: toRemMob(12);
    width: toRemMob(12);
    min-width: toRemMob(12);
    max-width: toRemMob(12);
    height: toRemMob(15);
    padding: 0;
    border: unset;
    background-color: unset;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/icons/delete.svg");
    cursor: pointer;
    @include _desktop {
      width: toRem(12);
      min-width: toRem(12);
      max-width: toRem(12);
      height: toRem(15);
      margin-left: toRem(20);
    }
  }

  &__buttons {
    &_specification {
      display: flex;
      align-items: center;
      margin: 0 toRemMob(10);
      @include _desktop {
        margin: 0;
      }
    }
  }

  &__price {
    &_modal-additionally {
      min-width: toRemMob(115);
      max-width: toRemMob(115);
      text-align: right;
      white-space: nowrap;
      font-weight: 300;
      font-size: toRemMob(14);
      line-height: toRemMob(16);
      order: 2;
      @include _desktop {
        display: flex;
        align-items: center;
        min-width: toRem(150);
        max-width: unset;
        font-weight: 300;
        font-size: toRem(16);
        line-height: toRem(19);
      }
    }
  }

  &_greenhouse-cart {
    margin-top: toRemMob(60);
    @include _desktop {
      margin-top: toRem(90);
    }

    &:first-child {
      margin-top: 0;
    }
  }
}

.textarea_modal_cart {
  height: toRemMob(148);
  font-size: toRemMob(16);
  line-height: toRemMob(19);
  @include _desktop {
    height: toRem(102);
    font-size: toRem(16);
    line-height: toRem(19);
  }
}

</style>
