<template>
  <picture>
    <source
      :type="$options.imagesTypes[imageDesktop.split('.').pop()] || $options.imagesTypes.jpg" media="(min-width: 640px)"
      :srcset="getDesktopImages"
    >
    <source
      :type="$options.imagesTypes[imageMobile.split('.').pop()] || $options.imagesTypes.jpg"
      :srcset="getMobileImages"
    >
    <img
      @load="$emit('load-image')"
      :alt="alt"
      :src="getDefaultImage"
    >
  </picture>
</template>

<script>
export default {
  // Options
  imagesTypes: {
    jpg: "image/jpeg",
    png: "image/png"
  },

  name: "PictureElement",
  emits: {
    "load-image": null
  },
  props: {
    imageDesktop: String,
    imageMobile: String,
    defaultImage: String,
    alt: String
  },
  data() {
    return {
      desktopImageType: ""
    };
  },
  methods: {
    getFileExtension(url) {
      const urlSplit = url.split(".");
      const extension = urlSplit.pop();
      const urlJoin = urlSplit.join(".");
      return [urlJoin, extension]
    }
  },
  computed: {
    getDesktopImages() {

      const [url, extension] = this.getFileExtension(this.imageDesktop);
      const imagesArray = [3, 2, 1].map(index => {
        return `${url}@${index}x.${extension} ${index}x${index !== 1 ? ", " : ""}`;
      });
      return imagesArray.join("");
    },
    getMobileImages() {
      const [url, extension] = this.getFileExtension(this.imageMobile);
      const imagesArray = [3, 2, 1].map(index => {
        return `${url}@${index}x.${extension} ${index}x${index !== 1 ? ", " : ""}`;
      });
      return imagesArray.join("");
    },
    getDefaultImage() {
      const [url, extension] = this.getFileExtension(this.defaultImage);
      return `${url}@${1}x.${extension}`;
    },

  }
};
</script>

<style scoped>
.pswp__image {
  height: auto !important;
}
</style>