<template>
  <modal-base class="modal_country" ref="modalBase" :wordTranslation="wordTranslation" :is-modal-country="true">
    <template #modal-title>
      Выберите город:
      <!--      {{ wordTranslation("change_country_modal.select_country") }}-->
    </template>
    <template #modal-content>
      <div class="city__wrapper">
        <template v-if="currentCountry?.abbreviation !== 'ru'">
          <router-link
            :to="{...$route, name: $route.name || 'HomePage', params: {...$route.params, geoLocation: country.abbreviation}}"
            v-for="country in countries?.filter(countryFilter => countryFilter.abbreviation !== 'ru')"
            :key="country"
            class="city__item"
            :class="{'active': country.id === currentCountry?.id}"
            @click="confirm(country)"
          >
            {{ getTranslateJsonField("title", country) }}
          </router-link>
        </template>
        <template v-else>
          <router-link
            :to="{...$route, name: $route.name || 'HomePage', params: {...$route.params, geoLocation: city.abbreviation}}"
            v-for="city in countryCities"
            :key="city"
            class="city__item"
            :class="{'active': city.abbreviation === currentCity?.abbreviation, 'bold': city.is_main_city}"
            @click="confirm(city)"
          >
            {{ city.title }}
          </router-link>
                    <router-link
            :to="{...$route, name: $route.name || 'HomePage', params: {...$route.params, geoLocation: 'ru'}}"
            class="city__item"
            :class="{'active': $route.params.geoLocation === 'ru'}"
            @click="confirm({abbreviation: 'ru'})"
          >
            Другой город
          </router-link>
        </template>
      </div>
    </template>
    <template #modal-actions>
      <div></div>
    </template>
  </modal-base>
</template>

<script>

import ModalBase from "@/components/modal/ModalBase";
import {mapGetters} from "vuex";

export default {
  name: "ModalCountry",
  components: {
    ModalBase,
  },
  props: {
    getTranslateJsonField: Function,
    wordTranslation: Function,
  },
  computed: {
    ...mapGetters({
      currentCountry: "currentCountry",
      countries: "countries",
      currentCity: "currentCity"
    }),
    countryCities() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.currentCountry?.cities?.sort((a, b) => {
        if (a.is_main_city !== b.is_main_city) {
          return b.is_main_city - a.is_main_city ;
        }
        return a.order - b.order
      });
    }
  },
  data() {
    return {};
  },
  methods: {
    open() {
      return this.$refs.modalBase.open();
    },
    close() {
      return this.$refs.modalBase.close();
    },
    confirm(location) {
      this.$refs.modalBase.confirm({geoLocation: location.abbreviation});
    }
  },
};
</script>

<style lang="scss">

.city {

  &__item {
    padding-left: toRemMob(54);
    margin-right: toRemMob(54);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: toRemMob(20);
    line-height: toRemMob(44);
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    transition: background-color .2s ease;
    color: inherit;

    @include _desktop {
      margin-right: toRem(39);
      padding-left: toRem(33);
      font-size: toRem(20);
      line-height: toRem(44);
    }

    &:before {
      content: '';
      position: absolute;
      left: toRemMob(21);
      top: 0;
      bottom: 0;
      display: none;
      width: toRemMob(10);
      height: toRemMob(10);
      margin: auto;
      border-radius: 50%;
      transition: all .2s ease;
      background-color: $color-green-2;

      @include _desktop {
        left: toRem(12);
        width: toRem(10);
        height: toRem(10);
      }
    }

    &:hover {
      background-color: $color-grey;
      border-radius: toRemMob(10);
      @include _desktop {
        border-radius: toRem(10);
      }
    }

    &.active {
      &:before {
        display: unset;
      }
    }

    &.bold {
      font-weight: 400;
    }
  }

}

.modal {
  &_country {

    .modal__header {
      margin-bottom: unset;
    }

    .modal__title {
      @include _desktop {
        font-size: toRem(28);
        line-height: toRem(33);
      }
    }

    .modal__content {
      margin-bottom: unset;
      overflow: auto;
      height: calc(100vh - toRemMob(91));
      @include _desktop {
        min-height: unset;
        height: unset;
        //max-height: toRem(683);
        max-height: calc(100vh - toRem(285));
      }
    }

    .city__wrapper {
      overflow: auto;
      height: 100%;
    }

    .modal__body {
      overflow: hidden;
      padding-right: 0;
      @include _desktop {
        min-width: toRem(492);
        max-width: toRem(492);
        padding: toRem(70) toRem(15) toRem(56) toRem(55);
      }
    }

    ::-webkit-scrollbar {
      @include _desktop {
        z-index: 9999;
        width: toRem(4);
      }
    }

    ::-webkit-scrollbar-thumb {
      @include _desktop {
        background-color: #CFCFCF;
        z-index: 9999;
        border-radius: toRemMob(5);
        cursor: pointer;
      }
    }

    ::-webkit-scrollbar-thumb:hover {
      @include _desktop {
        background-color: #CFCFCF;
      }
    }

    ::-webkit-scrollbar-track {
      @include _desktop {
        background-color: #fff;
        border-radius: toRemMob(5);
      }
    }

  }
}

.item {
  &_country {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: toRemMob(31);
    padding-right: toRemMob(25);
    font-size: toRemMob(20);
    line-height: toRemMob(23);
    font-weight: 300;
    cursor: pointer;

    @include _desktop {
      margin-bottom: toRem(31);
      padding-right: toRem(25);
      font-size: toRem(20);
      line-height: toRem(23);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      right: toRemMob(9);
      top: toRemMob(6);
      width: toRemMob(10);
      height: toRemMob(10);
      margin: auto;
      border-radius: 50%;
      background-color: transparent;
      transition: all .2s ease;
      transform: scale(0);

      @include _desktop {
        right: toRem(9);
        top: toRem(6);
        width: toRem(10);
        height: toRem(10);
      }
    }

    &.active {
      &:before {
        background-color: $color-green-2;
        transition: all .2s ease;
        transform: scale(1);
      }
    }
  }

  &__image {
    &_country {
      width: toRemMob(30);
      height: toRemMob(20);
      margin-right: toRemMob(12);

      @include _desktop {
        width: toRem(30);
        height: toRem(20);
        margin-right: toRem(12);
      }
    }
  }
}

.image {
  &_country {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
