<template>
  <section class="section section_video">
    <div class="container">
      <h2 class="section__title section__title_video">
        {{ wordTranslation("videos_instruction_block.title") }}
      </h2>
      <div class="video-instructions__wrapper" v-if="filteredVideos">
        <swiper
          @active-index-change="changeActiveIndex"
          @swiper="swiperInit"
          :slides-per-view="1"
          :space-between="20"
          :modules="modules"
          :long-swipes="true"
          :lazy="$options.swiperLazyOption"
          :thumbs="{swiper: thumbsSwiper}"
          :auto-height="true"
        >
          <swiper-slide
            v-for="(videoItem, index) in filteredVideos"
            :key="index"
          >
            <div
              @click.stop="initYoutubePswp({index})"
              :class="{'vertical': isGreenhousePage && videoItem.is_mobile, 'preloader_start': !loadedVideos.includes(index)}"
              class="video-instructions__preview preloader"
            >
              <video
                @loadstart="playVideoAfterLoad($event, index)"
                :data-src="index === 0 ? null : $host + getVideoLink(videoItem)"
                :src="index === 0 ? $host + getVideoLink(videoItem) : null"
                muted
                loop
                playsinline
                type="video/mp4"
                preload="none"
                class="video-instructions__video swiper-lazy"
              />
              <video
                v-if="deviceWidth < 640"
                @webkitfullscreenchange="closeFullscreenVideo"
                :src="$host + getFullscreenVideoLink(videoItem)"
                ref="fullscreenVideo"
                type="video/mp4"
                preload="none"
                class="video-instructions__fullscreen"
                allowfullscreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              />
              <div
                :class="{nonactive: !loadedVideos.includes(index)}"
                class="video-instructions__button"
              ></div>
              <div
                :class="{nonactive: !loadedVideos.includes(index)}"
                class="video-instructions__help-text"
              >
                {{ getTranslateJsonField("title", videoItem) }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="video-thumbs__wrapper" v-if="filteredVideos.length > 1">
        <swiper
          @swiper="thumbsSwiperInit"
          :slides-per-view="'auto'"
          :modules="modules"
          :long-swipes="true"
          :lazy="$options.swiperLazyOption"
          :css-mode="true"
          :breakpoints='{
          "640": {
             cssMode: false,
            },
          }'
        >
          <swiper-slide
            v-for="(videoItem, index) in filteredVideos"
            :key="index"
            :class="{active: activeSlideIndex === index}"
            class="video-thumbs__item"
          >
            <div
              :class="{'preloader_start': !loadedThumbsSliders.includes(index)}"
              class="video-thumbs__image-wrapper preloader"
            >
              <img
                @load="loadedThumbsSlider(index)"
                :alt="getTranslateJsonField('title', videoItem)"
                :data-src="getSlideImageSrc(videoItem)"
                class="video-thumbs__image swiper-lazy"
              >
            </div>
            <p
              class="video-thumbs__title"
            >
              {{ getTranslateJsonField("title", videoItem) }}
            </p>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/lazy/lazy.min.css";
import "swiper/modules/effect-fade/effect-fade.min.css";

import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/thumbs/thumbs.min.css";

// import required modules
import {FreeMode, Thumbs, Lazy} from "swiper";

import {loadScript} from "@/assets/core/loadYoutubeScript";

export default {
  swiperLazyOption: {
    checkInView: true,
    loadPrevNext: true,
  },

  name: "v-video-review",
  props: {
    galleryInit: Function,
    getTranslateJsonField: Function,
    wordTranslation: Function,
    videos: Array,
    isGreenhousePage: {
      type: Boolean,
      default: false
    },
  },
  inject: ["deviceWidth"],
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiper: null,
      thumbsSwiper: null,
      modules: [Lazy, FreeMode, Thumbs],
      loadedVideos: [],
      loadedThumbsSliders: [],
      isControlled: false,
      activeSlideIndex: 0,
      isReadyInitGallery: true,
    };
  },
  mounted() {
    // Load script for watch video
    loadScript();
  },
  methods: {
    initYoutubePswp(data) {
      if (!this.isReadyInitGallery) return;
      // if mobile show local video
      if (this.deviceWidth < 640) {
        const {index} = data;
        const video = this.$refs.fullscreenVideo[index];
        video.webkitEnterFullScreen();
        video.play();
        this.lockScreenOrientation(true);
      } else {
        // If desktop show youtube
        this.galleryInit(
          this.galleryVideos,
          this.activeSlideIndex,
          this.swiper,
          ...this.getYoutubeSize(),
          true,
          {youtube: true}
        );
      }
    },
    lockScreenOrientation(isLock) {
      if (isLock) {
        screen.orientation.lock("portrait-primary");
      } else {
        screen.orientation.lock("any");
      }
    },
    closeFullscreenVideo(event) {
      // Check on is open video on fullscreen
      if (!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement)) {
        try {
          this.lockScreenOrientation(false);
          event.target.pause();
        } catch (error) {
          console.error(error);
        }
      }
    },
    getVideoLink(videoItem) {
      return videoItem.video;
    },
    getFullscreenVideoLink(videoItem) {
      return videoItem.fullscreen_video;
    },
    getYoutubeId(videoItem) {
      return videoItem.youtube_id;
    },
    getYoutubeSize() {
      return this.deviceWidth < 640 ? [414, 514] : [1920, 1080];
    },
    swiperInit(swiper) {
      this.swiper = swiper;
    },
    thumbsSwiperInit(swiper) {
      this.thumbsSwiper = swiper;
      this.thumbsSwiper.updateSlidesProgress();
      this.thumbsSwiper.lazy.load();
    },
    playVideoAfterLoad(event, videoIndex) {
      event.target.play();

      if (videoIndex === 0) {
        event.target.onloadeddata = () => {
          this.loadedVideos.push(videoIndex);
        };
        return;
      }
      this.loadedVideos.push(videoIndex);

    },
    loadedThumbsSlider(slideIndex) {
      this.loadedThumbsSliders.push(slideIndex);
    },
    changeActiveIndex(swiper) {
      this.isReadyInitGallery = false;
      this.activeSlideIndex = swiper.activeIndex;
      this.thumbsSwiper.slideTo(this.activeSlideIndex);
      setTimeout(() => {
        this.isReadyInitGallery = true;
      }, 300);
    },
    getSlideImageSrc(slide) {
      return this.$host + (this.deviceWidth > 640 ? slide.image_desktop : slide.image_mobile);
    },
  },
  computed: {
    filteredVideos() {
      return (this.videos || []).filter(videoItem => {
        if (this.deviceWidth < 640) {
          return !videoItem.is_desktop;
        } else {
          return !videoItem.is_mobile;
        }
      });
    },
    galleryVideos() {
      return this.filteredVideos.map(videoItem => {
        return {
          src: videoItem.youtube_id,
          isVertical: videoItem.is_mobile,
          is_youtube: true
        };
      });
    }
  },
};
</script>

<style scoped lang="scss">


.container {
  max-width: $breakpoint-maximum;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  @include _desktop {
    padding-left: toRem(175);
    padding-right: toRem(175);
  }
}

.section {
  &_video {
    position: relative;
    width: 100%;
    padding: toRemMob(30) 0 toRemMob(80) 0;
    background-color: $color-grey-7;
    @include _desktop {
      padding: toRem(60) 0 toRem(160);
    }
  }

  &__title {
    &_video {
      margin-bottom: toRemMob(30);
      margin-left: toRemMob(57);
      font: 500 toRemMob(28)/toRemMob(33) "Roboto";

      @include _desktop {
        margin-bottom: toRem(60);
        margin-left: unset;
        font: 300 toRem(45)/toRem(53) "Roboto";
        color: $color-black;
      }
    }
  }

  &__video {
    position: relative;
    width: 100%;
    height: toRemMob(233);
    @include _desktop {
      width: 100%;
      height: toRem(812);
    }
  }
}

.video-instructions {
  &__wrapper {

  }

  &__help-text {
    position: absolute;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .53);
    font-size: toRemMob(14);
    bottom: toRemMob(18);
    padding: toRemMob(3.6) toRemMob(7.6);
    color: $color-white;
    max-width: 90%;
    @include _desktop {
      bottom: toRem(32);
      padding: toRem(6) toRem(9);
      font-size: toRem(24);
      background: rgba(255, 255, 255, .63);
      color: $color-black;
      max-width: unset;
    }
    width: max-content;
    margin: 0 auto;
    border-radius: toRem(5);
    text-align: center;
    opacity: 1;
    transition: opacity .5s ease;

    &.nonactive {
      opacity: 0;
    }

  }

  &__preview {
    &.vertical {
      height: toRemMob(514);
    }

    position: relative;
    width: 100%;
    cursor: pointer;
    @include _desktop {
      height: toRem(812);
    }
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: toRemMob(236);
    @include _desktop {
      border-radius: toRem(10);
      min-height: unset;
    }
  }

  &__button {
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    left: 0;
    position: absolute;
    filter: grayscale(100%) contrast(200%) opacity(70%);
    width: toRemMob(70);
    height: toRemMob(70);
    //убрал нечеткость при hover filter
    -webkit-transform: translateZ(0);
    background: url("../assets/images/icons/youtube-play.svg") no-repeat center center;
    background-size: contain;
    z-index: 2;
    opacity: 1;
    cursor: pointer;
    transition: opacity .5s ease;

    &.nonactive {
      opacity: 0;
    }

    @include _desktop {
      width: toRem(120);
      height: toRem(120);

      &:hover {
        filter: none;
      }
    }
  }

  &__fullscreen {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 1px;
    height: 1px;
    min-height: unset;
  }
}

.video-thumbs {
  &__wrapper {
    margin-top: toRem(15);
    padding-left: toRemMob(50);
    @include _desktop {
      margin-left: toRem(-9);
      margin-top: toRem(15);
      padding-left: 0;
    }
  }

  &__item {
    max-width: toRemMob(165);
    padding: toRemMob(7) toRemMob(7) toRemMob(28);
    cursor: pointer;
    border-radius: toRemMob(10);
    transition: background-color .3s linear;
    @include _desktop {
      max-width: toRem(186);
      padding: toRem(9) toRem(9) toRem(37);
      border-radius: toRem(10);
      margin-right: toRem(3);
    }

    &.active {
      background-color: $color-grey-25;
    }
  }

  &__image-wrapper {
    width: toRemMob(151);
    height: toRemMob(100);
    margin-bottom: toRemMob(8);
    @include _desktop {
      width: toRem(169);
      height: toRem(111);
      margin-bottom: toRem(9);
    }

    &.preloader_start {
      .video-thumbs__image {
        opacity: 0;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: toRemMob(5);
    @include _desktop {
      border-radius: toRem(5);
    }
  }

  &__title {
    font-weight: 300;
    line-height: toRemMob(25);
    font-size: toRemMob(16.7961);
    @include _desktop {
      font-size: toRem(16.7961);
      line-height: toRem(25);
    }
  }
}
</style>