<template>
  <section class="section section_page-variants">
    <div class="circle_animation display-none"></div>
    <div class="container">
      <h2 class="section__title section__title_choose-variant">
        {{ getTranslateJsonField("title", greenhouse) }} - {{ getTranslateJsonField("title", activeVariant) }}
      </h2>
      <p class="section__price section__price_choose-variant">
        {{
          `${wordTranslation("price_help.price_prefix")} ${getMinVariantExecutionPrice(activeVariant)} ${wordTranslation("price_help.currency")}`
        }}
      </p>

      <h2 class="section__title section__title_page-variants">
        {{ wordTranslation("configuration_block.select_variants_execution") }}
      </h2>
      <div class="section__list section__list_page-variants">
        <div class="item item_page-variants"
             v-for="variantExecution in greenhouse?.variants_execution"
             @click="!variantExecution.is_disable && $emit('change-active-variant', variantExecution)"
             :key="`variant-execution-${variantExecution.id}`"
             :class="{'active': activeVariant === variantExecution, disable: variantExecution.is_disable}"
        >
          <tippy
            v-if="variantExecution.is_disable"
            class="tippy__orientation"
          >

            <div
              :style="`background-image: url(${$host + variantExecution.image})`"
              class="item__image item__image_page-variants"
            >
            </div>

            <template #content>
              <p class="tippy-description">
                Уточняйте информацию у менеджеров по телефону<br/>
              </p>
            </template>

          </tippy>

          <div
            v-else
            :style="`background-image: url(${$host + variantExecution.image})`"
            class="item__image item__image_page-variants"
          >
          </div>


          <div class="item__description item__description_page-variants">
            {{ getTranslateJsonField("title", variantExecution) }}
          </div>
        </div>
      </div>


      <div class="section__columns section__columns_choose-variant">
        <div class="section__column section__column_left">

          <h2 class="section__title section__title_table">
            {{ wordTranslation("configuration_block.select_greenhouse_width") }}:
          </h2>

          <div class="section__list section__list_width-variants ">
            <div class="item item_width-variants"
                 v-for="currentWidth in configurationsWidths"
                 @click="changeActiveWidth(currentWidth)"
                 :class="{ 'active': currentWidth === activeWidth }"
                 :key="currentWidth">
              <div class="item__description item__description_page-variants item_table">
                {{ currentWidth }}
              </div>
            </div>
          </div>


          <div class="section__list section__list_table">
            <div class="item item_table">
              <div>{{ wordTranslation("configuration_block.size") }}</div>
              <div v-for="configurationTitle in configurationsTitles" :key="configurationTitle">
                <span v-if="deviceWidth > 640" class="item__table-title">
                  {{ configurationTitle.titleFull }}
                </span>
                <span v-else class="item__table-title">
                  {{ configurationTitle.titleShort }}
                </span>
              </div>
            </div>
            <div
              v-for="size in configurationsSizes"
              :key="size"
              class="item item_table"
            >
              <div>
                {{ size }}
              </div>
              <div
                @click="changeActiveConfiguration(getConfiguration(size, configurationTitle.titleFull))"
                v-for="configurationTitle in configurationsTitles"
                :key="`configuration-title-${configurationTitle}`"
              >
                <span
                  :class="{
                  'active': activeConfiguration === getConfiguration(size, configurationTitle.titleFull),
                  'buy': checkAlreadyAddingConfiguration(getConfiguration(size, configurationTitle.titleFull)?.id),
                  'price-discount': getTranslateJsonField('discount',  getConfiguration(size, configurationTitle.titleFull))
                }"
                  class="item__table-price"
                >
                  <div v-if="getTranslateJsonField('discount',  getConfiguration(size, configurationTitle.titleFull))"
                       class="badge-discount-small">
                    {{ "-" + getTranslateJsonField("discount", getConfiguration(size, configurationTitle.titleFull)) + "%"
                    }}
                  </div>

                  <div class="discount-small"
                       v-if="getTranslateJsonField('discount',  getConfiguration(size, configurationTitle.titleFull))">
                        {{
                      nicePrice(getFakeOldPrice(
                        getTranslateJsonField("price", getConfiguration(size, configurationTitle.titleFull)),
                        getTranslateJsonField("discount", getConfiguration(size, configurationTitle.titleFull))
                      ))
                    }}
                  </div>

                  {{ nicePrice(getTranslateJsonField("price", getConfiguration(size, configurationTitle.titleFull))) }}
                </span>

              </div>

            </div>
          </div>

          <div class="section__phrase-model">
            <v-phrase :word-translation="wordTranslation"/>
          </div>

          <div v-if="deviceWidth < 640" class="section__image section__image_choose-variant"
               :class="{'visible': visible.el, 'preloader_stop': preload.img}">
            <div :class="{'preloader-mini-block': !preload.img}">
              <div class=" preloader-mini preloader-mini_start"
                   :class="{'visible': visible.el, 'preloader-mini_stop': preload.img, 'section__image': preload.img, 'section__image_choose-variant': preload.img}">
                <img class="unvisible-quick image image_contain" @load="onLoadPageVariant"
                     :src="$host + currentConfiguration.image_desktop"
                     :alt='`${getTranslateJsonField("title", greenhouse)} - ${getTranslateJsonField("title", activeVariant)}`'
                     :class="{'visible-quick visible': preload.img}"
                >
              </div>
            </div>
          </div>

          <div class="section__list section__list_specification">
            <div
              class="item item_specification"
              v-for="configurationAttribute in  majorAttributes"
              :key="`configuration-attribute-${configurationAttribute?.id}`"
            >
              <div class="preloader preloader_start"
                   :class="{ 'preloader_stop': preload.description}"
              >
                <div class="unvisible-quick item__description item__description_specification"
                     :class="{'item__description-flex': configurationAttribute.is_value_hint, 'visible-quick': preload.description}"
                >
                  {{ getTranslateJsonField("category", configurationAttribute) }}:
                  <div
                    v-if="configurationAttribute.hint !== null"
                    class="item__wrapper item__wrapper_info-specification"
                    :class="{'item__wrapper_right-position': configurationAttribute.is_value_hint}"
                  >
                    <tippy>
                      <div class="item__info_specification"></div>
                      <template #content>
                        {{ configurationAttribute.image }}
                        <div v-if="configurationAttribute.hint.image !== null" class="tippy-image">
                          <img
                            :src='$host + configurationAttribute.hint?.image'
                            :alt='getTranslateJsonField("text", configurationAttribute.hint)'>
                        </div>
                        <p v-if="configurationAttribute.hint.text !== null"
                           v-html="getTranslateJsonField('text', configurationAttribute.hint)"
                           class="tippy-description">
                        </p>
                      </template>
                    </tippy>
                  </div>
                  <template v-if="configurationAttribute.value !==null">
                    {{ getTranslateJsonField("value", configurationAttribute) }}
                  </template>
                </div>
              </div>
            </div>
          </div>
          <h2 class="unvisible-quick section__title section__title_table"
              :class="{'visible-quick': preload.description}"
          >
            {{ wordTranslation("configuration_block.equipment") }}
          </h2>
          <div class="section__list section__list_specification">
            <div
              class="item item_specification"
              v-for="configurationAttribute in minorAttributes"
              :key="`configuration-attribute-${configurationAttribute?.id}`"
            >
              <div class="preloader preloader_start"
                   :class="{ 'preloader_stop': preload.description}"
              >
                <div class="unvisible-quick item__description item__description_specification"
                     :class="{'item__description-flex': configurationAttribute.is_value_hint, 'visible-quick': preload.description}"
                >
                  <template v-if="configurationAttribute.hint === null || configurationAttribute.is_value_hint">
                    {{ getTranslateJsonField("category", configurationAttribute)?.trim() }}:&nbsp;
                  </template>
                  <template v-else>
                    {{ getTranslateJsonField("category", configurationAttribute)?.trim() }}
                  </template>
                  <div
                    v-if="configurationAttribute.hint !== null"
                    class="item__wrapper item__wrapper_info-specification"
                    :class="{'item__wrapper_right-position': configurationAttribute.is_value_hint}"
                  >
                    <tippy>
                      <div class="item__info_specification"></div>
                      <template #content>
                        {{ configurationAttribute.image }}
                        <div v-if="configurationAttribute.hint.image !== null" class="tippy-image">
                          <img
                            :src='$host + configurationAttribute.hint?.image'
                            :alt='getTranslateJsonField("text", configurationAttribute.hint)'>
                        </div>
                        <p v-if="configurationAttribute.hint.text !== null"
                           v-html="getTranslateJsonField('text', configurationAttribute.hint)"
                           class="tippy-description">
                        </p>
                      </template>
                    </tippy>
                  </div>


                  <span v-if="configurationAttribute.value !== null">
                    <template
                      v-if="configurationAttribute.hint !== null && !configurationAttribute.is_value_hint"
                    >:</template>
                    {{ getTranslateJsonField("value", configurationAttribute)?.trim() }}
                  </span>


                </div>


              </div>
            </div>
          </div>
          <div class="section__wrapper section__wrapper_total">

            <div class="prices">
              <div class="section__price section__price_total">
                {{ wordTranslation("price_help.result") }}
              </div>
              &ensp;
              <span v-if="getTranslateJsonField('discount', activeConfiguration)">
                <div class="section__price section__price_total discount-large">
                {{
                    nicePrice(getFakeOldPrice(
                      getTranslateField("price", activeConfiguration), getTranslateJsonField("discount", activeConfiguration)
                    ))
                  }}
                </div>
              </span>

              <div class="section__price section__price_total">
                {{
                  `${nicePrice(getTranslateJsonField("price", activeConfiguration))} ${wordTranslation("price_help.currency")}`
                }}
              </div>

            </div>


            <button
              v-if="!checkAlreadyAddingConfiguration(currentConfiguration.id)"
              @click="addConfigurationToCart(currentConfiguration),
              animationCart($event)"
              class="button button_page-cart button_page-cart-desktop"
              :class="{'disabled': !preload.description,
              'button-margin-left': getTranslateJsonField('discount', activeConfiguration)
              }"
            >
              {{ wordTranslation("buttons.add_to_cart") }}
            </button>

            <button
              v-else
              @click="openCartModal"
              class="button button_page-cart button_page-cart-desktop"
              :class="{'disabled': !preload.description,
              'button-margin-left': getTranslateJsonField('discount', activeConfiguration)
              }"
            >
              {{ wordTranslation("buttons.go_to_cart") }}
            </button>

            <button
              v-if="!checkAlreadyAddingConfiguration(currentConfiguration.id)"
              @click="addConfigurationToCart(currentConfiguration),
              animationCart($event)"
              class="button button_page-cart button_page-cart-mobile"
              :class="{'disabled': !preload.description}"
            >
              {{ wordTranslation("buttons.to_cart") }}
            </button>

            <button
              v-else
              @click="openCartModal"
              class="button button_page-cart button_page-cart-mobile"
              :class="{'disabled': !preload.description}"
            >
              {{ wordTranslation("buttons.go_to_cart") }}
            </button>


          </div>
          <template
            v-if="greenhouse.foundations?.length"
          >
          </template>
        </div>
        <div v-if="deviceWidth >= 640" class="section__column section__column_right section__column_image">
          <div :class="{'preloader-mini-block': !preload.img}">
            <div class=" preloader-mini preloader-mini_start"
                 :class="{'visible': visible.el, 'preloader-mini_stop': preload.img, 'section__image': preload.img, 'section__image_choose-variant': preload.img}">
              <img class="unvisible-quick image" @load="onLoadPageVariant"
                   :src="$host + currentConfiguration.image_desktop"
                   :alt='`${getTranslateJsonField("title", greenhouse)} - ${getTranslateJsonField("title", activeVariant)}`'
                   :class="{'visible-quick visible': preload.img}"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="section__columns section__columns_choose-variant">
        <div class="section__column section__column_left">
          <h3 class="section__title section__title_additionally-order">
            {{ wordTranslation("configuration_block.additional_attributes") }}
          </h3>
          <div class="section__list section__list_additionally-order">
            <div
              v-for="additionalAttribute in additionalAttributes"
              :key="`additional-attribute-${additionalAttribute.id}`"
              :class="{
                      'disable': checkAlreadyAddingAdditionalAttributesInCart({
                      greenhouse, objectId: additionalAttribute.id
                      })
                    }"
              class="item item_additionally-order"
            >
              <div class="preloader preloader_start"
                   :class="{ 'preloader_stop': preload.description}"
              >
                <div class="unvisible-quick item__description item__description_additionally-order"
                     :class="{'visible-quick': preload.description}"
                >
                  <input type="checkbox" v-model="additionalAttribute.value" class="default__check">
                  <span class="custom__check"
                        @click.stop="changeAdditionalAttributeValue(additionalAttribute)"></span>
                  <span v-html="getTranslateJsonField('category', additionalAttribute)"
                        @click="changeAdditionalAttributeValue(additionalAttribute)">
                  </span>
                  <span class="item__wrapper item__wrapper_info-additionally-order">
                    <tippy v-if="additionalAttribute.hint !== null">
                      <div class="item__info_additionally-order"></div>
                      <template #content>
                        <div class="tippy-image">
                          <img :src='$host + additionalAttribute.hint?.image'
                               :alt='getTranslateJsonField("text", additionalAttribute.hint)'>
                        </div>
                        <p v-html="getTranslateJsonField('text', additionalAttribute.hint)"
                           class="tippy-description">
                        </p>
                      </template>
                    </tippy>
                  </span>
                </div>
              </div>
              <div class="preloader preloader_start"
                   :class="{ 'preloader_stop': preload.description}"
              >
                <div class="unvisible-quick item__price item__price_additionally-order"
                     :class="{'visible-quick': preload.description}">
                  <template v-if="additionalAttribute.is_individual">
                    {{ wordTranslation("price_help.individual_calculation") }}
                  </template>
                  <template v-else>
                    {{ getTranslateJsonField("price_prefix", additionalAttribute) ? getTranslateJsonField("price_prefix", additionalAttribute) : ""
                    }}

                    {{ nicePrice(getTranslateJsonField("price", additionalAttribute)) }}
                    {{ wordTranslation("price_help.currency") }}/
                    <template v-if="getTranslateJsonField('quantity_price', additionalAttribute)?.length">
                      {{ getTranslateJsonField("quantity_price", additionalAttribute) }}
                    </template>
                  </template>
                </div>
              </div>
            </div>


          </div>
          <div class="section__wrapper section__wrapper_total">
            <button
              v-if="!checkAddingAllAdditionalAttributes(greenhouse)"
              @click="addAdditionalAttributeToCart()"
              class="button button_page-cart button_page-cart-desktop"
              :class="{'currentAdditionally':deviceWidth > 640, 'disabled': !preload.description}"
            >
              {{ wordTranslation("buttons.add_to_cart") }}
            </button>
            <button
              v-else
              @click="openCartModal"
              class="button button_page-cart button_page-cart-desktop"
              :class="{'disabled': !preload.description}"
            >
              {{ wordTranslation("buttons.go_to_cart") }}
            </button>
            <button
              v-if="!checkAddingAllAdditionalAttributes(greenhouse)"
              @click="addAdditionalAttributeToCart()"
              class="button button_page-cart button_page-cart-mobile"
              :class="{'currentAdditionally':deviceWidth < 640, 'disabled': !preload.description}"
              id="additionally"
            >
              {{ wordTranslation("buttons.to_cart") }}
            </button>
            <button
              v-else
              @click="openCartModal"
              class="button button_page-cart button_page-cart-mobile"
              :class="{'disabled': !preload.description}"
            >
              {{ wordTranslation("buttons.go_to_cart") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {Tippy} from "vue-tippy";
import VPhrase from "@/components/index-page/v-phrase";
import {mapGetters} from "vuex";
/*import GreenhousePreloader from "@/components/GreenhousePreloader";*/
// import SlideUpDown from "vue3-slide-up-down";

export default {
  props: {
    greenhouse: Object,
    getTranslateField: Function,
    wordTranslation: Function,
    getTranslateJsonField: Function,
    getCurrentGreenhouseFromLocalStorageCart: Function,
    getMinVariantExecutionPrice: Function,
    openCartModal: Function,
    activeVariant: Object,
    activeConfiguration: Object,
    currentConfiguration: Object,
    durationTime: Number,
    activeWidth: String,
    configurationsWidths: Array
  },
  components: {
    VPhrase,
    Tippy,
    /*GreenhousePreloader*/
    // SlideUpDown
  },
  emits: {
    "change-active-variant": null,
    "change-active-configuration": null,
    "init-toggle-configuration": null,
    "added-configuration-to-cart": null
  },
  inject: ["nicePrice", "deviceWidth", "getFakeOldPrice"],
  name: "vPageConfigurator",
  data() {
    return {
      isCopied: false,
      visible: {
        el: false,
      },
      preload: {
        img: false,
        description: false
      },
    };
  },
  methods: {
    animationCart(event) {

      let but;

      if (event) {
        but = event.target.getBoundingClientRect();
      } else {
        but = document.querySelector(".currentAdditionally").getBoundingClientRect();
      }
      const circle = document.querySelector(".circle_animation");
      const butX = but.left + window.pageXOffset;
      const butY = but.top + window.pageYOffset;

      let coordCircleX = butX;
      let coordCircleY = butY;

      const cart = document.querySelector(".header__cart").getBoundingClientRect();
      const lx = Math.abs(cart.left + window.pageXOffset - butX);
      const ly = Math.abs(cart.top + window.pageYOffset - butY);

      circle.style.display = "block";
      circle.style.top = butY + "px";
      circle.style.left = butX + "px";

      let badge = document.querySelector(".header__badge");

      function animateBadgeStart() {
        badge.style.transform = "translateY(-5px)";
      }

      function animateBadgeStop() {
        badge.style.transform = "translateY(0)";
      }


      const push = () => {
        coordCircleX += lx / 200;
        coordCircleY -= ly / 200;
        circle.style.top = coordCircleY + "px";
        circle.style.left = coordCircleX + "px";
        if (coordCircleX > cart.left) {
          circle.style.display = "none";
          clearInterval(interval);
          animateBadgeStart();
          setTimeout(animateBadgeStop, 200);

        }
      };

      const interval = setInterval(push, 1);

    },
    onLoadPageVariant() {
      this.preload.img = true;
    },
    copyConfiguratorCode(code, event) {
      if (this.isCopied) return;

      event.target.closest(".item__price_specification-decorate").classList.add("copied");
      this.isCopied = true;

      setTimeout(() => {
        event.target.closest(".item__price_specification-decorate").classList.remove("copied");
        this.isCopied = false;
      }, 1200);
      navigator.clipboard.writeText(code);
    },
    openCopiedTippy(value) {
      setTimeout(() => {
        value.hide();
      }, 1000);
    },
    addAdditionalAttributesToLocalStorage(selectedAttributes) {
      let {currentGreenhouse, localStorageCart} = this.getCurrentGreenhouseFromLocalStorageCart();

      const uniqueAttributes = selectedAttributes.filter(selectedAttribute => {
        return (currentGreenhouse.additional_attributes || [])
          .findIndex(selectedAttributeFindIndex => selectedAttributeFindIndex.id === selectedAttribute.id) === -1;
      });

      currentGreenhouse.additional_attributes = (currentGreenhouse.additional_attributes || []).concat(
        uniqueAttributes.map(attribute => {
          return {id: attribute.id};
        }));

      localStorage.setItem(
        "cart",
        JSON.stringify(
          localStorageCart
        )
      );

      selectedAttributes.forEach(attribute => {
        // Костыль!!! Мы "обвновляем" id у атрибута и checkAlreadyAddingInCart срабатываем снова.
        attribute.disable = true;
      });
    },
    addAdditionalAttributesToStorageCart(selectedAttributes) {
      const cart = this.$store.getters.cart;
      let currentGreenhouse = (cart.greenhouses || []).find(cartItem => cartItem.greenhouse_id === this.greenhouse.id);

      if (!currentGreenhouse) {
        currentGreenhouse = {...this.greenhouse, greenhouse_id: this.greenhouse.id, variants_execution: []};
        cart.greenhouses = (cart.greenhouses || []).concat(currentGreenhouse);
      }
      currentGreenhouse.variants_execution = currentGreenhouse.variants_execution || [];

      const uniqueAttributes = selectedAttributes.filter(selectedAttribute => {
        return (currentGreenhouse.additional_attributes || []).findIndex(item => item.id === selectedAttribute.id) === -1;
      });
      currentGreenhouse.additional_attributes = (currentGreenhouse.additional_attributes || []).concat(uniqueAttributes);

      this.$store.commit(
        "updateCart",
        cart
      );

      if (selectedAttributes?.length) {
        this.animationCart();
      }
    },
    addConfigurationLocalStorage(activeVariant, configuration) {
      let isNewVariantExecution = false;
      let {currentGreenhouse, localStorageCart} = this.getCurrentGreenhouseFromLocalStorageCart();
      let currentVariant = currentGreenhouse.variants_execution.find(variant => variant.id === activeVariant.id);
      if (!currentVariant) {
        isNewVariantExecution = true;
        currentVariant = {id: activeVariant.id, configurations: [{id: configuration.id, count: 1}]};
      } else if (currentVariant.filteredConfigurations.findIndex(findConfiguration => findConfiguration.id === configuration.id) === -1) {
        currentVariant.filteredConfigurations.push({id: configuration.id, count: 1});
      }

      if (isNewVariantExecution) {
        currentGreenhouse.variants_execution.push(currentVariant);
      }

      localStorage.setItem(
        "cart",
        JSON.stringify(
          localStorageCart
        )
      );
    },
    addConfigurationStorageCart(activeVariant, configuration) {
      let isNewGreenhouse = false;
      let isNewVariantExecution = false;
      let cart = this.$store.getters.cart;
      configuration.count = 1;
      let currentGreenhouse = (cart.greenhouses || []).find(cartItem => cartItem.greenhouse_id === this.greenhouse.id);

      if (!currentGreenhouse) {
        isNewGreenhouse = true;
        currentGreenhouse = {
          ...this.greenhouse, greenhouse_id: this.greenhouse.id, variants_execution: [],
          additional_attributes: [], all_additional_attributes: []
        };
      }
      currentGreenhouse.variants_execution = currentGreenhouse.variants_execution || [];
      currentGreenhouse.all_additional_attributes = (currentGreenhouse.all_additional_attributes || [])
        .concat(configuration.additional_attributes)
        .filter((additionalAttribute, index, selfArray) => index === selfArray.findIndex(additionalAttributeFindIndex => additionalAttributeFindIndex.id === additionalAttribute.id));

      let currentVariant = currentGreenhouse.variants_execution.find(variant => variant.id === activeVariant.id);
      if (!currentVariant) {
        isNewVariantExecution = true;
        currentVariant = {...activeVariant, configurations: [configuration]};
      } else if (currentVariant.filteredConfigurations.findIndex(findConfiguration => findConfiguration.id === configuration.id) === -1) {
        currentVariant.filteredConfigurations.push(configuration);
      }

      if (isNewVariantExecution) {
        currentGreenhouse.variants_execution.push(currentVariant);
      }

      if (isNewGreenhouse) {
        cart.greenhouses = (cart.greenhouses || []).concat(currentGreenhouse);
      }
      this.$store.commit(
        "updateCart",
        cart
      );
    },
    addAdditionalAttributeToCart() {
      const selectedAttributes = this.currentConfiguration?.additional_attributes.filter(attribute => {
        if (attribute.type === "bool") {
          return attribute.value;
        }
      });
      this.addAdditionalAttributesToLocalStorage(selectedAttributes);
      this.addAdditionalAttributesToStorageCart(selectedAttributes);
    },
    addConfigurationToCart() {
      this.addConfigurationStorageCart(this.activeVariant, this.currentConfiguration);
      this.addConfigurationLocalStorage(this.activeVariant, this.currentConfiguration);
      // Так как мы не можем обновить пропс здесь, мы делаем эмит. Он обновляем переменную, которую впринцепе мы и не используем)
      // но из-за реактивности vue обвноляет html, в котором проверка на уже exists in cart становиться true.
      this.$emit("added-configuration-to-cart");
    },
    checkAlreadyAddingInCart(fieldName, objectId) {
      return (this.$store.getters.cart[fieldName] || []).findIndex(item => item.id === objectId) !== -1;
    },
    checkAlreadyAddingAdditionalAttributesInCart(data) {
      const {greenhouse, objectId} = data;
      const currentGreenhouseInCart = (this.$store.getters.cart?.greenhouses || []).find(greenhouseFind => greenhouseFind.greenhouse_id === greenhouse.id);
      return (currentGreenhouseInCart?.additional_attributes || []).findIndex(item => item.id === objectId) !== -1;
    },
    checkAlreadyAddingConfiguration(objectId) {
      const currentGreenhouse = (this.$store.getters.cart.greenhouses || []).find(greenhouse => greenhouse.greenhouse_id === this.greenhouse.id);
      const allConfiguration = (currentGreenhouse?.variants_execution || []).map(variant => variant.configurations.map(config => config.id));
      return allConfiguration.flat().includes(objectId);
    },
    changeActiveConfiguration(variantConfiguration) {
      if (this.activeConfiguration !== variantConfiguration) {
        this.preload.img = false;
        this.preload.description = false;
        this.visible.el = false;
        this.$emit("change-active-configuration", variantConfiguration);
      }
    },
    checkAddingAllAdditionalAttributes(greenhouse) {

      const selectedAttributes = (this.currentConfiguration?.additional_attributes || []).filter(attribute => {
        if (attribute.type === "bool") {
          return attribute.value;
        }
      });
      return selectedAttributes?.length && selectedAttributes?.every(additionalAttribute => {
        return this.checkAlreadyAddingAdditionalAttributesInCart({greenhouse, objectId: additionalAttribute.id});
      });
    },
    getConfiguration(size, titleFull) {
      return (this.activeVariant?.configurations || []).find(configurataion => {
        return this.getTranslateJsonField("size", configurataion) === size &&
          this.getTranslateJsonField("title_full", configurataion) === titleFull;
      });
    },
    changeAdditionalAttributeValue(additionalAttribute) {
      if (!this.checkAlreadyAddingInCart("additional_attributes", additionalAttribute.id)) {
        additionalAttribute.value = !additionalAttribute.value;
      }
    },
    changeActiveWidth(width) {
      this.$emit("change-active-width", width);
    }
  },
  computed: {
    ...mapGetters(["currentCountry"]),
    configurationsTitles() {
      const allTitles = [];
      (this.activeVariant?.filteredConfigurations || []).forEach(configuraton => {
        if (allTitles.findIndex(title => title.titleFull === this.getTranslateJsonField("title_full", configuraton)) === -1) {
          allTitles.push({
            titleFull: this.getTranslateJsonField("title_full", configuraton),
            titleShort: this.getTranslateJsonField("title_short", configuraton),
          });
        }
      });
      return allTitles;
    },
    configurationsSizes() {
      const allSizes = [];
      (this.activeVariant?.filteredConfigurations || []).forEach(configuraton => {
        if (!allSizes.includes(this.getTranslateJsonField("size", configuraton))) {
          allSizes.push(this.getTranslateJsonField("size", configuraton));
        }
      });
      return allSizes;
    },
    majorAttributes() {
      return (this.currentConfiguration?.attributes || []).filter(configuration => configuration.is_main_equipment);
    },
    minorAttributes() {
      return (this.currentConfiguration?.attributes || []).filter(configuration => !configuration.is_main_equipment);
    },
    additionalAttributes() {
      (this.currentConfiguration?.additional_attributes || []).forEach(additionalAttribute => {
        additionalAttribute.value = this.checkAlreadyAddingAdditionalAttributesInCart({
          greenhouse: this.greenhouse,
          objectId: additionalAttribute.id
        });
      });
      return this.currentConfiguration?.additional_attributes || [];
    }
  },
  watch: {
    currentConfiguration: {
      handler() {
        this.preload.description = true;
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
.button-margin-left {
  @include _desktop {
    margin-left: toRem(21);
  }
}

.prices {
  display: flex;
}

.disabled {
  pointer-events: none;
}

.circle_animation {
  left: 0;
  top: 0;
  position: absolute;
  width: toRemMob(20);
  height: toRemMob(20);
  z-index: 999 !important;
  background-color: $color-green;
  background-image: url("../../assets/images/icons/cart-white.svg");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  @include _desktop {
    width: toRem(21);
    height: toRem(21);
  }
}

.default__check[type="checkbox"],
.default__check[type="radio"] {
  display: none;

  ~ .custom__check {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    height: toRemMob(22);
    min-width: toRemMob(22);
    max-width: toRemMob(22);
    border: 1px solid $color-green-2;
    transition: all 0.4s ease;
    border-radius: toRemMob(3);
    cursor: pointer;

    @include _desktop {
      height: toRem(22);
      min-width: toRem(22);
      max-width: toRem(22);
      border-radius: toRem(3);
    }

    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      height: 7px;
      width: 3px;
      margin-top: -1px;
      border-bottom: 2px solid $color-white;
      border-right: 2px solid $color-white;
      transform: rotate(45deg);
      visibility: hidden;
      transition: all .2s ease;
    }
  }

  &:checked {
    ~ .custom__check {
      background-color: $color-green;

      &:after {
        visibility: visible;
        transition: all .2s ease;
      }
    }
  }

  &:disabled {
    ~ .custom__check {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

}

.unvisible-quick {
  opacity: 0;
}

.visible-quick {
  opacity: 1;
}

.button {
  &_page-cart {
    width: 100%;
    padding: toRemMob(9) toRemMob(29);
    border-radius: toRemMob(5);
    font-size: toRemMob(16);
    line-height: toRemMob(19);
    color: $color-white;
    background-color: $color-green;
    border: toRemMob(1) solid $color-green;

    @include _desktop {
      width: max-content;
      padding: toRem(12) toRem(65);
      border: toRem(1) solid $color-green;
      border-radius: toRem(4);
      font-size: toRem(18);
      line-height: toRem(24);
      color: $color-grey-18;
      background-color: unset;
    }

    &-mobile {
      display: block;
      @include _desktop {
        display: none;
      }
    }

    &-desktop {
      display: none;
      @include _desktop {
        display: block;
      }
    }

    &:hover {
      @include _desktop {
        color: $color-white;
        background-color: $color-green;
      }
    }

    &.disable {
      &:hover {
        background-color: unset;
        color: $color-grey-18;
      }
    }
  }

  &_additionally-order {
    min-width: toRemMob(98);
    padding: toRemMob(7) toRemMob(21);
    border: toRemMob(1) solid $color-grey-19;
    border-radius: toRemMob(3);
    font-weight: 300;
    font-size: toRemMob(14);
    line-height: toRemMob(16);
    color: $color-grey-20;

    &.active, &:hover {
      border-color: $color-green-2;
    }

    @include _desktop {
      min-width: toRem(68);
      padding: toRem(7) toRem(21);
      border: toRem(1) solid $color-grey-19;
      border-radius: toRem(3);
      font-size: toRem(14);
      line-height: toRem(16);
    }
  }
}

.section {
  &__columns {
    &_choose-variant {
      display: flex;
      flex-direction: column;
      margin-bottom: toRemMob(70);
      @include _desktop {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: toRem(70);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__column {

    &_left {
      padding: 0 toRemMob(10);
      @include _desktop {
        width: toRem(589);
        padding: unset;
      }
    }

    &_right {
      margin-bottom: toRemMob(34);
      @include _desktop {
        width: toRem(857);
        margin-bottom: unset;
        padding: unset;
      }
    }

    &_image {
      height: toRemMob(252);
      @include _desktop {
        height: toRem(685);
      }
    }
  }

  &_page-variants {
    padding: toRemMob(49) toRemMob(30) toRemMob(70);
    background-color: $color-grey-16;
    @include _desktop {
      padding: toRem(62) 0 toRem(121);
    }
  }

  &__title {
    &_page-variants {
      margin-bottom: toRemMob(14);
      margin-left: toRemMob(12);
      font-weight: 300;
      font-size: toRemMob(16);
      line-height: toRemMob(22);
      @include _desktop {
        margin-bottom: toRem(17);
        margin-left: unset;
        font-size: toRem(16);
        line-height: toRem(22);
      }
    }

    &_choose-variant {
      margin-bottom: toRemMob(10);
      margin-left: toRemMob(12);
      font-weight: 400;
      font-size: toRemMob(26);
      line-height: toRemMob(33);
      color: $color-grey-17;

      @include _desktop {
        margin-bottom: toRem(17);
        margin-left: unset;
        font-weight: 300;
        font-size: toRem(45);
        line-height: toRem(53);
      }
    }

    &_foundation-options {
      margin-bottom: toRemMob(20);
      font-weight: 300;
      font-size: toRemMob(16);
      line-height: toRemMob(19);
      @include _desktop {
        margin-bottom: toRem(18);
        font-size: toRem(16);
        line-height: toRem(22);
      }
    }

    &_additionally-order {
      margin-bottom: toRemMob(30);
      font-weight: 400;
      font-size: toRemMob(16);
      line-height: toRemMob(22);
      color: $color-grey-20;
      @include _desktop {
        margin-bottom: toRem(35);
        font-size: toRem(18);
        line-height: toRem(22);
      }
    }

    &_table {
      margin-bottom: toRemMob(14);
      font-weight: 400;
      font-size: toRemMob(16);
      line-height: toRemMob(22);
      color: #1D1D1D;
      @include _desktop {
        margin-bottom: toRem(29);
        font-size: toRem(16);
        line-height: toRem(22);
      }

      + .section__list_specification {
        margin-bottom: toRemMob(40);
        @include _desktop {
          margin-bottom: toRem(75);
        }
      }
    }
  }

  &__wrapper {
    &_total {

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      @include _desktop {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &__list {
    &_page-variants {
      display: flex;
      justify-content: space-between;
      margin-bottom: toRemMob(34);
      @include _desktop {
        margin-bottom: toRem(60);
        justify-content: unset;
      }
    }

    &_width-variants {
      display: flex;
      justify-content: start;
      position: relative;
      left: -12px;
      margin-bottom: toRemMob(34);
      @include _desktop {
        left: 0;
        justify-content: space-between;
        margin-bottom: toRem(60);
        justify-content: unset;
      }
    }

    &_additionally-order {
      margin-bottom: toRemMob(40);
      @include _desktop {
        margin-bottom: toRem(75);
      }
    }

    &_table {
      @include _desktop {
        margin-bottom: toRem(71);
      }
    }

    &_specification {

      + .section__title_table {
        margin-top: toRemMob(22);

        @include _desktop {
          margin-top: toRem(30);
        }
      }
    }
  }

  span .discount-large {
    color: $color-grey-3;
    text-decoration: line-through;
    font-weight: 400;
    margin: 0 toRem(7);
    @include _desktop {
      font-weight: 300;
      white-space: nowrap;
      text-align: center;
      margin: 0 toRem(10);
    }
  }

  &__price {
    &_choose-variant {
      margin-left: toRemMob(12);
      margin-bottom: toRemMob(35);
      font-size: toRemMob(20);
      line-height: toRemMob(23);
      font-weight: 300;
      @include _desktop {
        margin-left: 0;
        margin-bottom: toRem(50);
        font-size: toRem(30);
        line-height: toRem(35);
      }
    }

    &_total {
      margin-bottom: toRemMob(30);
      font-size: toRemMob(20);
      line-height: toRemMob(21);
      font-weight: 500;
      white-space: nowrap;
      color: $color-grey-17;
      @include _desktop {
        margin-bottom: unset;
        font-weight: 300;
        font-size: toRem(28);
        line-height: toRem(33);
      }
    }
  }

  &__image {
    &_choose-variant {
      height: toRemMob(252);
      display: block;
      width: 100%;
      margin: 0 0 0 auto;
      @include _desktop {
        width: toRem(837);
        height: toRem(685);
      }
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
  }
}

.item {

  &_page-variants {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: toRemMob(72);
    padding: toRemMob(7) toRemMob(4) toRemMob(11);
    transition: all 0.2s ease;
    cursor: pointer;

    &:before {
      content: '';
      transform: scale(0);
      position: absolute;
      width: toRemMob(5);
      height: toRemMob(5);
      right: toRemMob(5);
      top: toRemMob(5);
      border-radius: 50%;
      background-color: transparent;
      transition: all .2s ease;
      @include _desktop {
        right: toRem(10);
        top: toRem(10);
        width: toRem(10);
        height: toRem(10);
      }
    }

    @include _desktop {
      padding: toRem(32) toRem(10) toRem(22) toRem(10);
      width: toRem(172);
    }


    &.active {
      &:before {
        background-color: $color-green-2;
        transform: scale(1);
      }

      &:hover {
        background-color: $color-white;
      }
    }

    &.active, &:hover {
      box-shadow: 0 toRemMob(2) toRemMob(5) rgba(0, 0, 0, 0.05);
      border-radius: toRemMob(5);
      background-color: $color-white;
      @include _desktop {
        box-shadow: 0 toRem(2) toRem(5) rgba(0, 0, 0, 0.05);
        border-radius: toRem(5);
      }
    }

    &:hover {
      background-color: #ffffff80;
    }

    &:nth-child(2) {
      .item__description_page-variants {
        padding-left: toRemMob(8);
        @include _desktop {
          padding-left: 0;
        }
      }
    }
  }

  &_width-variants {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: toRemMob(65);
    padding: toRemMob(7) toRemMob(14) toRemMob(7) toRemMob(16);
    transition: all 0.2s ease;
    cursor: pointer;

    &:before {
      content: '';
      transform: scale(0);
      position: absolute;
      width: toRemMob(4);
      height: toRemMob(5);
      right: toRemMob(5);
      top: toRemMob(5);
      border-radius: 50%;
      background-color: transparent;
      transition: all .2s ease;
      @include _desktop {
        right: toRem(7);
        top: toRem(7);
        width: toRem(10);
        height: toRem(10);
      }
    }

    @include _desktop {

      padding: toRem(13) toRem(0) toRem(13) toRem(0);
      width: toRem(91);
    }


    &.active {
      &:before {
        background-color: $color-green-2;
        transform: scale(1);
      }

      &:hover {
        background-color: $color-white;
      }
    }

    &.active, &:hover {
      box-shadow: 0 toRemMob(2) toRemMob(5) rgba(0, 0, 0, 0.05);
      border-radius: toRemMob(5);
      background-color: $color-white;
      @include _desktop {
        box-shadow: 0 toRem(2) toRem(5) rgba(0, 0, 0, 0.05);
        border-radius: toRem(5);
      }
    }

    &:hover {
      background-color: #ffffff80;
    }
  }

  &_specification {
    display: block;
    margin-bottom: toRemMob(22);
    font-size: toRemMob(15);
    line-height: toRemMob(19);
    font-weight: 300;
    @include _desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: toRem(30);
      font-size: toRem(16);
      line-height: toRem(19);
    }

    &:last-child {
      margin-bottom: 0;
    }

  }

  &_additionally-order {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-bottom: toRemMob(36);
    @include _desktop {
      margin-bottom: toRem(23);
      align-items: center;
      font-weight: 300;
      font-size: toRem(16);
      line-height: toRem(24);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.disable {

      .custom__check {
        background-color: $color-green-2 !important;

        &:after {
          visibility: visible;
        }
      }
    }
  }

  &_foundation-options {
    position: relative;
    display: block;
    padding-right: toRemMob(25);
    margin-bottom: toRemMob(10);
    width: max-content;
    font-size: toRemMob(16);
    line-height: toRemMob(19);
    font-weight: 300;
    color: $color-blue-3;

    @include _desktop {
      padding-right: toRem(25);
      margin-bottom: toRem(10);
      font-size: toRem(16);
      line-height: toRem(19);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: toRemMob(16);
      height: toRemMob(16);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../../assets/images/icons/pdf.svg");
      @include _desktop {
        width: toRem(16);
        height: toRem(16);
      }
    }
  }

  &__title {
    &_foundation-options {
      margin-bottom: toRemMob(20);
      font-size: toRemMob(16);
      line-height: toRemMob(19);
      font-weight: 300;
      @include _desktop {
        margin-bottom: toRem(18);
        font-size: toRem(16);
        line-height: toRem(22);
      }
    }
  }

  &__image {
    &_page-variants {
      width: toRemMob(50);
      height: toRemMob(50);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: toRemMob(6);

      @include _desktop {
        width: toRem(107);
        height: toRem(89);
        margin-bottom: toRem(15);
      }
    }

    &_specification {
      width: toRemMob(301);
      height: toRemMob(261);
      margin: toRemMob(11) auto toRemMob(15);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__description {
    &_page-variants {
      font: 300 toRemMob(11)/toRemMob(13) "Roboto";
      color: $color-black;
      @include _desktop {
        font: 300 toRem(16)/toRem(19) "Roboto";
      }
    }

    &_additionally-order {
      position: relative;
      display: inline-block;
      padding-left: toRemMob(32);
      width: toRemMob(193);
      font-size: toRemMob(15);
      line-height: toRemMob(19);
      font-weight: 300;
      @include _desktop {
        padding-left: toRem(43);
        width: toRem(438);
        font-weight: unset;
        font-size: unset;
        line-height: unset;
      }

      br {
        display: block;
        @include _desktop {
          display: none;
        }
      }

      span {
        cursor: pointer;
      }
    }

    &_added {
      padding: 0;

      br {
        display: none;
      }
    }

    &_specification {
      display: inline-block;
      white-space: nowrap;
      @include _desktop {
        display: flex;
        align-items: center;
      }

      &-cod {
        min-width: unset;
        max-width: unset;
      }
    }

    &-flex {
      display: flex;
    }
  }

  &__price {

    &_specification {
      &-decorate {
        position: relative;
        border: toRemMob(1) solid $color-grey-19;
        padding: toRemMob(6) toRemMob(39) toRemMob(5) toRemMob(26);
        border-radius: toRemMob(3);
        font-weight: 500;
        cursor: pointer;

        @include _desktop {
          padding: toRem(6) toRem(39) toRem(5) toRem(26);
          border-radius: toRem(3);
          border: toRem(1) solid $color-grey-19;
        }

        &.copied {
          background-color: #f2f2f2;
        }

        &:before {
          content: '';
          position: absolute;
          width: toRemMob(13);
          height: toRemMob(13);
          right: toRemMob(7);
          top: toRemMob(9);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("../../assets/images/icons/cod.svg");
          @include _desktop {
            width: toRem(13);
            height: toRem(13);
            right: toRem(7);
            top: toRem(9);
          }
        }
      }
    }

    &_additionally-order {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      order: 2;
      width: toRemMob(111);
      text-align: right;
      white-space: nowrap;
      font-weight: 300;
      font-size: toRemMob(15);
      line-height: toRemMob(19);
      @include _desktop {
        max-width: unset;
        min-width: unset;
        width: toRem(151);
        order: unset;
        font-weight: unset;
        font-size: unset;
        line-height: unset;
      }
    }

    &_total {
      font-size: toRemMob(18);
      line-height: toRemMob(21);
      font-weight: 300;
      white-space: nowrap;
      color: $color-grey-17;
      @include _desktop {
        font-size: toRem(28);
        line-height: toRem(33);
      }
    }
  }

  &__info {
    &_specification {
      width: toRemMob(18);
      height: toRemMob(18);
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/icons/info.svg");
      cursor: pointer;
      @include _desktop {
        width: toRem(18);
        height: toRem(18);
      }
    }

    &_additionally-order {
      position: relative;
      width: toRemMob(18);
      height: toRemMob(18);
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/icons/info.svg");
      cursor: pointer;
      @include _desktop {
        width: toRem(18);
        height: toRem(18);
      }

      &:before {
        content: '';
        position: absolute;
        left: toRemMob(-23);
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
        width: toRemMob(64);
        height: toRemMob(64);

        @include _desktop {
          display: none;
        }
      }
    }
  }

  &__wrapper {
    &_info-specification {
      display: inline-block;
      margin-left: toRemMob(2);
      margin-right: toRemMob(2);
      margin-bottom: toRemMob(-3);
      @include _desktop {
        margin-left: toRem(5);
        margin-right: toRem(2);
        margin-bottom: unset;
      }
    }

    &_right-position {
      order: 3;
      margin-left: toRemMob(3);
      @include _desktop {
        margin-left: toRem(3);
      }
    }

    &_info-additionally-order {
      display: inline-block;
      margin-bottom: toRemMob(-5);
      margin-left: toRemMob(5);
      @include _desktop {
        margin-bottom: toRem(-4);
        margin-left: toRem(5);
      }
    }

    &_total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: toRemMob(29);
      @include _desktop {
        margin-bottom: toRem(39);
      }
    }
  }

  &__buttons {
    &_additionally-order {
      order: 3;
      margin-top: toRemMob(10);
      @include _desktop {
        order: unset;
        margin-top: unset;
      }

      > .button {
        margin-left: toRemMob(4);
        @include _desktop {
          margin-left: toRem(4);
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &__list {

    &_foundation-options {
      margin-bottom: toRemMob(69);
      @include _desktop {
        margin-bottom: unset;
      }
    }

    &_specification {
      margin-bottom: toRemMob(45);
      @include _desktop {
        margin-bottom: toRem(45);
      }
    }
  }

  &_table {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: toRemMob(14);
    line-height: toRemMob(24);
    color: #000000;

    @include _desktop {
      font-size: toRem(18);
      line-height: toRem(24);
    }

    &:last-child {
      > div {
        border-bottom: unset;
      }
    }

    &:first-child {
      > div {
        align-items: flex-start;
        height: toRemMob(41);
        font-weight: 400;
        line-height: toRemMob(18);
        @include _desktop {
          padding-top: toRem(10);
          height: toRem(64);
          font-weight: 300;
          line-height: toRem(18);
        }
      }
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: toRemMob(82);
      max-width: toRemMob(82);
      height: toRemMob(53);
      border-right: toRemMob(1) solid #D3D3D3;
      border-bottom: toRemMob(1) solid #D3D3D3;
      @include _desktop {
        min-width: toRem(165);
        max-width: toRem(165);
        height: toRem(53);
        border-right: toRem(1) solid #D3D3D3;
        border-bottom: toRem(1) solid #D3D3D3;
      }

      &:first-child {
        justify-content: flex-start;
        min-width: toRemMob(60);
        max-width: toRemMob(60);
        font-weight: 400;
        @include _desktop {
          min-width: toRem(95);
          max-width: toRem(95);
          font-weight: 300;
        }
      }

      &:last-child {
        border-right: unset;
      }
    }

    .discount-small {
      color: $color-grey-3;
      text-decoration: line-through;
      text-align: center;
      font-size: toRemMob(8);
      line-height: toRemMob(9);
      @include _desktop {
        font-size: toRem(12);
        line-height: toRem(14);
        margin-top: toRem(1);
      }
    }

    .badge-discount-small {
      top: toRem(-4);
      right: toRem(-4);
      position: absolute;
      background-color: $color-red-2;
      color: $color-white;
      font-size: toRemMob(8);
      line-height: toRemMob(9);
      padding: toRemMob(1) toRemMob(2);
      border-radius: toRemMob(2);
      @include _desktop {
        top: toRem(3);
        right: toRem(3);
        border-radius: toRem(2);
        font-size: toRem(11);
        text-align: center;
        line-height: toRem(10);
        padding: toRem(2) toRem(3);
      }
    }

    .price-discount {
      padding: toRemMob(4.5) toRemMob(10);
      @include _desktop {
        padding: toRem(3) toRem(43);
      }
    }

  }

  &__table-price {
    position: relative;
    padding: toRemMob(9) toRemMob(10);
    border: toRemMob(1) solid transparent;
    border-radius: toRemMob(2);
    transition: all .2s ease;
    cursor: pointer;
    white-space: nowrap;
    @include _desktop {
      padding: toRem(10) toRem(43);
      border: toRem(1) solid transparent;
      border-radius: toRem(2);
    }

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      left: toRemMob(3);
      top: toRemMob(4);
      background-color: #3CB156;
      border-radius: 50%;
      transition: all .2s ease;
      @include _desktop {
        right: toRem(3);
        top: toRem(4);
      }
    }

    &.active {
      border: toRemMob(1) solid #21A73F;
      @include _desktop {
        border: toRem(1) solid #21A73F;
      }
    }

    &.buy {
      &:before {
        width: toRemMob(6);
        height: toRemMob(6);
        @include _desktop {
          width: toRem(8);
          height: toRem(8);
        }
      }
    }

    &:hover {
      @include _desktop {
        border: toRem(1) solid $color-green;
      }
    }

    &:active {
      @include _desktop {
        background: rgba(33, 167, 63, 0.1);
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
    }

    span {
      display: none;
      @include _desktop {
        display: inline-flex;
      }
    }
  }

  &__table-title {
    width: toRemMob(70);
    @include _desktop {
      width: toRem(130);
    }
  }
}

.placeholder__text {
  font-size: toRem(16);
  margin: toRem(10) toRem(10) 0;
}
</style>
