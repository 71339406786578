<template>
  <modal-base ref="modalBase" :wordTranslation="wordTranslation" :callback-close="dragAndDropRemoveEvent">
    <template #modal-title>
      {{ wordTranslation("create_comment_modal.title") }}
    </template>
    <template #modal-error></template>
    <template #modal-success>
      <div class="modal__success">
        <div class="modal__image modal__image_success"></div>
        <h2 class="modal__title modal__title_success">{{ wordTranslation("base_modal.hooray") }}</h2>
        <p
          class="modal__description modal__description_success"
          v-html="wordTranslation('create_comment_modal.success_text')"
        >
        </p>
      </div>
    </template>
    <template #modal-content>
      <div class="modal__list">
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            {{ wordTranslation("create_comment_modal.fio_input") }}
            <span>*</span>
          </div>
          <input
            @input="fioError = ''"
            :placeholder="wordTranslation('create_comment_modal.fio_placeholder')"
            class="input input_modal input_modal-name"
            v-model="fio"
            type="text"
            name="name"
          >
          <div class="error__placeholder" v-show="fioError">
            {{ fioError }}
          </div>
        </div>
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            {{ wordTranslation("create_comment_modal.location_input") }}
            <span>*</span>
          </div>
          <input
            @input="locationError = ''"
            :placeholder="wordTranslation('create_comment_modal.location_placeholder')"
            class="input input_modal input_modal-city"
            v-model="location"
            type="text"
            name="city"
          >
          <div class="error__placeholder" v-show="locationError">
            {{ locationError }}
          </div>
        </div>
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            {{ wordTranslation("create_comment_modal.phone_input") }}
            <span>*</span>
          </div>
          <modalWindowMask
            @update:phone="phone = $event.phone, phoneObject = $event.phoneObject"
            @input="phoneError = ''"
            :phone="phone"
            :word-translation="wordTranslation"
            ref="modalWindowMask"
          />
          <div class="error__placeholder" v-show="phoneError.length">
            {{ phoneError }}
          </div>
        </div>
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            {{ wordTranslation("create_comment_modal.comment_input") }}
          </div>
          <textarea
            @input="commentTextError = ''"
            v-model="commentText"
            :placeholder="wordTranslation('create_comment_modal.comment_placeholder')"
            class="textarea textarea_modal"
          ></textarea>
          <div class="error__placeholder" v-show="commentTextError.length">
            {{ commentTextError }}
          </div>
        </div>
      </div>

      <label class="button button_add-image" for="upload-file">
        {{ wordTranslation("create_comment_modal.add_image") }}
        <input id="upload-file" multiple type="file" name="file" ref="file" @change="handleUploadFile">
      </label>

      <div class="modal-image__list">
        <div
          v-for="file in files" :key="file.size"
          class="modal-image__item"
        >
          <img class="modal-image__image" :src="`${file.base_64}`" alt="">
          <div @click.stop="removeFile(file)" class="modal-image__remove"></div>
        </div>
      </div>


      <div class="modal__list">
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            {{ wordTranslation("create_comment_modal.youtube_input") }}
          </div>
          <input
            @input="youTubeLinkError = ''"
            :placeholder="wordTranslation('create_comment_modal.youtube_placeholder')"
            class="input input_modal input_modal-youtube"
            v-model="youTubeLink"
            type="text"
            name="youtube"
          >
          <div class="error__placeholder" v-show="youTubeLinkError">
            {{ youTubeLinkError }}
          </div>
        </div>
      </div>
    </template>
    <template #modal-confirm>
      <button class="button button_modal button_green" @click="modalValidation">
        {{ wordTranslation("buttons.send") }}
      </button>
      <p class="modal__politic">
        {{ wordTranslation("create_comment_modal.politic_text") }}<br>
        <a
          class="link link_blue link_modal"
          download="user_agreement.pdf"
          href="/user_agreement.pdf"
        >
          {{ wordTranslation("base_modal.personal_data") }}
        </a>
      </p>
    </template>
  </modal-base>
</template>

<script>

import ModalBase from "@/components/modal/ModalBase";

import modalWindowMask from "@/components/modal/ModalWindowMask";

export default {
  // 10 mb in bytes
  maxFilesSizes: 10 * 1024 * 1024,

  name: "ModalCreateComment",
  components: {
    ModalBase,
    modalWindowMask
  },
  data() {
    return {
      fio: "",
      fioError: "",
      location: "",
      locationError: "",
      phone: "",
      phoneError: "",
      phoneObject: {},
      commentText: "",
      commentTextError: "",
      youTubeLink: "",
      youTubeLinkError: "",
      files: [],
      fileSizeError: true
    };
  },
  props: {
    getTranslateField: Function,
    getTranslateJsonField: Function,
    wordTranslation: Function,
  },
  inject: ["nicePrice", "deviceWidth", "getFakeOldPrice"],
  methods: {
    open() {
      this.dragAndDropEventInit();
      return this.$refs.modalBase.open();
    },
    close() {
      return this.$refs.modalBase.close();
    },
    regexValidation(re, str) {
      return re.test(str);
    },
    fioValidation() {
      if (!this.regexValidation(/^[\w \-А-я]{1,128}$/, this.fio)) {
        this.fioError = this.wordTranslation("base_modal.fio_error");
        return false;
      }
      this.fioError = "";
      return true;
    },
    phoneValidation() {
      if (!this.phoneObject.valid) {
        this.phoneError = this.wordTranslation("base_modal.phone_error");
        return false;
      }
      this.phoneError = "";
      return true;
    },
    commentValidation() {
      if (!this.regexValidation(/^[\wА-я.,–+=%?!()№*:;\r\n«»$"'\-/ ]{0,1024}$/, this.commentText)) {
        this.commentTextError = this.wordTranslation("base_modal.comment_error");
        return false;
      }
      this.commentTextError = "";
      return true;
    },
    locationValidation() {
      if (!this.regexValidation(/^[\wА-я.,–+=%?!()№*:;\r\n«»$"'\-/ ]{1,128}$/, this.location)) {
        this.locationError = this.wordTranslation("base_modal.location_error");
        return false;
      }
      this.locationError = "";
      return true;
    },
    modalValidation() {
      if (this.isValidForm) {
        this.confirmModal();
      }
    },
    handleUploadFile(event) {
      const files = [...(event.dataTransfer?.files || event.target?.files || [])];
      files.forEach(file => this.uploadFile(file));

    },
    uploadFile(file) {
      if (!this.isCanWriteNewFile(file.size)) {
        this.showWarning(this.wordTranslation("create_comment_modal.warning_text"));
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.files.push({
          filename: file.name,
          size: file.size,
          base_64: reader.result
        });
      };
    },
    confirmModal() {
      return this.$refs.modalBase.confirm({
        fio: this.fio,
        location: this.location,
        phone: this.phoneObject.formatted,
        text: this.commentText,
        youtube_link: this.youTubeLink,
        files: this.files
      });
    },
    async showError() {
      return new Promise(resolve => {
        this.$refs.modalBase.showError();
        setTimeout(() => {
          this.$refs.modalBase.hideError();
          resolve();
        }, 3000);
      });
    },
    async showWarning(warningText) {
      return new Promise(resolve => {
        this.$refs.modalBase.showWarning(warningText);
        setTimeout(() => {
          this.$refs.modalBase.hideWarning();
          resolve();
        }, 1500);
      });
    },
    async showSuccess() {
      return new Promise(resolve => {
        console.log("show success");
        this.$refs.modalBase.showSuccess();
        setTimeout(() => {
          this.fio = "";
          this.location = "";
          this.phone = "";
          this.text = "";
          this.youtube_link = "";
          this.files = [];
          this.$refs.modalWindowMask.clearPhone();
          this.$refs.modalBase.hideSuccess();
          this.close();
          resolve();
        }, 3000);
      });
    },
    confirm(modalData) {
      return this.$refs.modalBase.confirm(modalData);
    },
    showPreloader() {
      return this.$refs.modalBase.showPreloader();
    },
    hidePreloader() {
      return this.$refs.modalBase.hidePreloader();
    },
    preventDefaults(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    showDragAndDropArea() {
      document.querySelector(".modal-upload").classList.add("active");
    },
    hideDragAndDropArea() {
      document.querySelector(".modal-upload").classList.remove("active");
    },
    dragAndDropEventInit() {
      const dropAreaInit = document.body;
      const dropArea = document.querySelector(".modal-upload");

      ["dragenter", "dragover", "dragleave", "drop"].forEach(eventName => {
        dropAreaInit.addEventListener(eventName, this.preventDefaults, false);
        dropArea.addEventListener(eventName, this.preventDefaults, false);
      });

      ["dragenter"].forEach(eventName => {
        dropAreaInit.addEventListener(eventName, this.showDragAndDropArea, false);
      });

      ["dragleave", "drop"].forEach(eventName => {
        dropArea.addEventListener(eventName, this.hideDragAndDropArea, false);
      });
      dropArea.addEventListener("drop", this.handleUploadFile, false);

    },
    dragAndDropRemoveEvent() {
      const dropAreaInit = document.body;
      const dropArea = document.querySelector(".modal-upload");

      ["dragenter", "dragover", "dragleave", "drop"].forEach(eventName => {
        dropAreaInit.removeEventListener(eventName, this.preventDefaults);
        dropArea.removeEventListener(eventName, this.preventDefaults);
      });

      ["dragenter"].forEach(eventName => {
        dropAreaInit.removeEventListener(eventName, this.showDragAndDropArea);
      });

      ["dragleave", "drop"].forEach(eventName => {
        dropArea.removeEventListener(eventName, this.hideDragAndDropArea);
      });
      dropArea.removeEventListener("drop", this.handleUploadFile);
    },
    isCanWriteNewFile(newFileSize) {
      return newFileSize + this.files.reduce((a, b) => a + b.size, 0) < this.$options.maxFilesSizes && this.files.length < 10;
    },
    removeFile(file) {
      this.files = this.files.filter(fileFilter => fileFilter !== file);
    },
  },
  computed: {
    isValidForm() {
      this.fioValidation();
      this.phoneValidation();
      this.commentValidation();
      this.locationValidation();
      return !this.phoneError.length && !this.fioError.length && !this.commentTextError.length && !this.locationError.length;
    }
  }
};

</script>

<style lang="scss" scoped>

#upload-file {
  display: none;
}

.modal-image {
  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    width: toRemMob(82.5);
    height: toRemMob(75);
    margin-right: toRemMob(6.5);
    margin-bottom: toRemMob(5);

    &:nth-child(4n+4) {
      margin-right: 0;
    }

    @include _desktop {
      width: toRem(95);
      height: toRem(90);
      margin-right: toRem(14);
      margin-bottom: toRem(10);

    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__remove {
    position: absolute;
    top: 0;
    right: 0;
    background: #00000066;
    width: toRemMob(16);
    height: toRemMob(16);
    cursor: pointer;

    @include _desktop {
      width: toRem(16);
      height: toRem(16);
    }

    &::before, &::after {
      content: '';
      position: absolute;
      top: toRemMob(0.45);
      right: toRemMob(7);
      height: toRemMob(15);
      width: toRemMob(2);
      background-color: $color-white;

      @include _desktop {
        top: toRem(0.5);
        right: toRem(7);
        height: toRem(15);
        width: toRem(2);
      }
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}
</style>
