<template>
  <div v-show="deviceWidth<640" class="item item_completed">
    <div class="item__wrapper-comment item__wrapper-comment_completed item__wrapper-comment_completed-top">
      <swiper
        :slidesPerView="1"
        :space-between="0"
        :calculateHeight="true"
        :pagination="{
        clickable: true,
        type: 'bullets',
      }"
      >
        <swiper-slide
          v-for="(comment,commentIndex) in comments.slice(0, 1)" :key="comment"
          class="swiper-slide_completed"
        >
          <div class="item__wrapper item__wrapper_completed item__wrapper_completed-top">

            <div v-if="deviceWidth >= 640">
              <div class="item__wrapper item__wrapper_completed item__wrapper_completed-center cursor-context">
                <h2 class="item__title item__title_completed">
                  {{ getCommentTitle(comment) }}
                </h2>
                <div class="item__date item__date_mobile">{{ this.formatDate(comment.created) }}</div>
                <p class="item__description item__description_completed"
                   v-html="getTranslateJsonField('text', comment)">
                </p>
              </div>
              <div class="item__wrapper item__wrapper_completed item__wrapper_completed-bottom">
                <button class="button button_read-more">{{ wordTranslation("completed_works_block.more") }}...</button>
                <div class="item__date item__date_mobile">{{ this.formatDate(comment.created) }}</div>
              </div>
            </div>

            <swiper
              :css-mode="true"
              :slides-per-view="1"
              :space-between="0"
              :pagination='{
                  el: ".completed-pagination"+commentIndex,
                  "type": "fraction",
            }'
              :breakpoints='{
                  "640": {
                    initialSlide:0,
                    slidesPerView:3,
                    init:false,
                    enabled:false,
                  },
          }'
              @swiper="(swiper) => swiperInit(swiper, commentIndex)"
            >
              <swiper-slide
                v-for="(photo, photoIndex) in comment.files"
                :key="photo"
                @click.stop="galleryInit(
                  getFilesSrc($host, comment),
                  photoIndex,
                  swiperArray[commentIndex],
                  this.deviceWidth < 640 ? 414: 1444,
                  this.deviceWidth < 640 ? 414: 1081,
                  this.deviceWidth < 640
                )"
              >

                <div v-if="(photoIndex < 3 && deviceWidth >= 640) ||  deviceWidth < 640"
                     class="item__image item__image_completed">
                  <div class="item__more" v-if="photoIndex === 2 && deviceWidth >= 640">
                    <span>
                      {{ wordTranslation("completed_works_block.more") }}
                    </span>
                  </div>
                  <div class="completed-columns__img-preloader">
                    <img v-if="(deviceWidth >= 640)" class="image image-completed__contain fit-content"
                         :src='$host + photo.desktop_thumb_filename'>
                    <img v-if="(deviceWidth < 640)" class="image image-completed__contain"
                         :src='$host + photo.mobile_thumb_filename'>
                  </div>
                </div>
              </swiper-slide>
            </swiper>

            <div class="item__wrapper item__wrapper_control item__wrapper_control-completed">
              <div class="item__pagination item__pagination_completed"
                   :class="{...getClassWithIndex('completed-pagination', commentIndex)}"
              ></div>
            </div>


            <div class="mini-circle-wrapp">
              <div v-if="comment.files && deviceWidth<640" class="mini-circle-container">
                <div class="mini-circle"
                     v-for="(_, index) in comment.files" :key="index"
                     :class="{'mini-circle-active': getSwiperActiveIndex(commentIndex) === index}"
                     @click="changeSlide({swiperIndex: commentIndex, slideIndex: index})"
                ></div>
              </div>
            </div>


          </div>

          <div v-if="( deviceWidth < 640)">
            <div
              class="item__wrapper item__wrapper_completed item__wrapper_completed-center">
              <div class="item__title-wrapper">
                <h2 class="item__title item__title_completed">
                  {{ getCommentTitle(comment) }}
                </h2>
                <div class="item__date item__date_mobile">{{ this.formatDate(comment.created) }}</div>
              </div>

              <p v-html="getTranslateJsonField('text', comment)" @click="toggleComment(comment)"
                 class="item__description item__description_completed">

              </p>

              <!--              <slide-up-down tag="p" class="item__description item__description_completed height-min"-->
              <!--                             @close-start="toggleComment(comment)" @open-start="toggleComment(comment)"-->
              <!--                             v-model="comment.isOpen" :duration="durationTime">-->
              <!--                <p v-html="getTranslateJsonField('text', comment)" @click="toggleComment(comment)">-->

              <!--                </p>-->

              <!--              </slide-up-down>-->

              <!--              <div class="opacity-block">-->
              <!--                <div class="gradient" v-show="!comment.isShowGradient" @click.stop="toggleComment(comment)"></div>-->
              <!--                <div class="opacity-close"-->
              <!--                     v-if="!comment.isShowGradient"-->
              <!--                     @click="toggleComment(comment)"-->
              <!--                >-->
              <!--                  {{ wordTranslation("completed_works_block.expand") }}-->
              <!--                </div>-->

              <!--                <div v-else-->
              <!--                     class="opacity-close"-->
              <!--                     @click.stop="toggleComment(comment)"-->
              <!--                >-->
              <!--                  {{ wordTranslation("completed_works_block.collapse") }}-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
  <!--  <div v-for="item in 3" :key=item>-->
  <!--    {{item}}-->
  <!--  </div>-->
  <div v-show="deviceWidth>=640" class="completed-columns__wrapper" v-if="comments.length>0">
    <div v-for="item in comments.slice(0, 3)" :key=item>
      <div class="completed-columns__column">
        <div class="completed-columns__img-preloader" v-if="item?.files">
          <img class="completed-columns__img" :src='$host + item?.files[0]?.desktop_thumb_filename' alt="">
        </div>
        <div class="completed-columns__text">
          <div>
            <div class="item__title-wrapper">
              <h2 class="item__title item__title_completed">{{ getCommentTitle(item) }}</h2>
              <div class="item__date item__date_mobile">{{ this.formatDate(item.created) }}</div>
            </div>
          </div>
          <div class="text-slider">
            <p
              v-html="getTranslateJsonField('text', item)"
              class="completed-columns__description item__description item__description_completed"
            >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue";
// const readmore = require("readmore-js");
// import SlideUpDown from "vue3-slide-up-down";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from "swiper";

// install Swiper modules
SwiperCore.use([Pagination]);

export default {
  props: {
    galleryInit: Function,
    wordTranslation: Function,
    getTranslateJsonField: Function,
    comments: Array,
    getFilesSrc: Function,
    formatDate: Function,
    getCommentTitle: Function,
    getClassWithIndex: Function,
    toggleComment: Function
  },
  components: {
    // SlideUpDown,
    Swiper,
    SwiperSlide
  },
  emits: {"changed-slide": null},
  inject: ["deviceWidth"],
  name: "vCompletedItem",
  data() {
    return {
      swiperOuter: null,
      currentComment: 0,
      durationTime: 300,
      swiperArray: [],
    };
  },
  methods: {
    changeSlide(data) {
      const {swiperIndex, slideIndex} = data;
      this.swiperArray[swiperIndex]?.slideTo(slideIndex, this.durationTime);
    },
    swiperInit(swiper, index) {
      this.swiperArray[index] = swiper;
    },
    getSwiperActiveIndex(swiperIndex) {
      return this.swiperArray[swiperIndex]?.activeIndex || 0;
    },
  },
};
</script>
<style lang="scss" scoped>

.section {
  &_comleted {
    padding: toRemMob(30) 0 0 0;
  }
}

//.swiper-backface-hidden {
//  padding-bottom: toRem(90);
//}

//.swiper-initialized{
//  padding-bottom: toRem(40);
//}

.cursor-context {
  cursor: auto;
}

.swiper-pagination-bullets {
  @include _desktop {
    display: none;
  }
}

.completed-columns {
  &__description {
    padding-top: 0 !important;
  }

  &__text {
    padding: 0;
  }

  &__img-preloader {
    height: toRemMob(414);
    width: 100%;
    background-color: #ebebeb;
    @include _desktop {
      height: toRem(335.8);
      margin-bottom: toRem(20);
    }
  }

  &__img {
    display: block;
    margin-bottom: 0;
  }

  &__column {
    width: toRem(450);
    margin-right: toRem(48);
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  &__wrapper {
    display: flex;
    align-items: start;
    justify-content: start;
    margin-right: toRem(-48);
  }
}

.image {
  &-completed__contain {
    display: block;
    width: 100%;
    height: toRemMob(414);
    object-fit: cover;
  }
}

.gradient {
  position: absolute;
  top: toRemMob(-54);
  left: 0;
  width: 100%;
  height: toRemMob(108);
  background: linear-gradient(0deg, $color-grey-7 50%, rgba(255, 255, 255, 0) 106.25%);
}

.opacity-block {
  position: relative;
}

.opacity-close {
  font-weight: 300;
  font-size: toRemMob(16);
  color: #66afe9;
  text-align: right;
  position: absolute;
  top: toRemMob(20);
  right: 0;
}

.height-min {
  min-height: 100px;
}

.button {
  &_read-more {
    padding: 0;
    border: 0;
    font: 500 toRemMob(18)/toRemMob(18) "Roboto";
    color: $color-blue;
    cursor: pointer;
    background: unset;
    @include _desktop {
      font: 500 toRem(18)/toRem(18) "Roboto";
    }
  }
}

.item {
  &_completed {
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  &__more {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba($color-black, .5);
    font-size: toRemMob(19);
    line-height: toRemMob(23);
    color: $color-white;

    @include _desktop {
      font-size: toRem(19);
      line-height: toRem(23);
    }

    & + .image {
      pointer-events: all;
    }

    span {
      position: absolute;
      display: block;
      padding-right: toRemMob(22);
      @include _desktop {
        padding-right: toRem(22);
      }

      &:after {
        content: '';
        position: absolute;
        width: toRemMob(8);
        height: toRemMob(12);
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-image: url("../assets/images/icons/more.svg");
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 1;
        transition: all .2s ease;
        @include _desktop {
          width: toRem(8);
          height: toRem(12);
        }
      }
    }

    &:hover {
      span {
        &:after {
          right: toRemMob(-5);
          transition: all .2s ease;
          @include _desktop {
            right: toRem(-5);
          }
        }
      }
    }
  }

  &__wrapper-comment {
    &_completed {

      &-top {
        position: relative;
        margin-left: toRemMob(-15);
        margin-right: toRemMob(-15);
        margin-bottom: toRemMob(32);
        @include _desktop {
          margin-left: unset;
          margin-right: unset;
          margin-bottom: unset;
          order: 2;
        }
      }
    }
  }

  &__wrapper {
    &_completed {

      &-top {
        position: relative;
        margin-bottom: toRemMob(47);
        @include _desktop {
          margin-left: unset;
          margin-right: unset;
          margin-bottom: unset;
          order: 2;
        }
      }

      &-center {
        margin-bottom: toRemMob(0);
        padding: 0 toRemMob(55);
        @include _desktop {
          margin-bottom: toRem(39);
          padding: unset;
          order: 1;
        }
      }

      &-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 toRemMob(42);
        @include _desktop {
          display: none;
        }
      }
    }

    &_control {
      &-completed {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        right: toRemMob(31);
        top: toRemMob(31);
        z-index: 1;
        @include _desktop {
          display: none;
        }
      }
    }
  }

  &__pagination {
    &_completed {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: toRemMob(23);
      padding-right: toRemMob(5);
      height: toRemMob(23);
      min-width: toRemMob(57);
      border-radius: toRemMob(5);
      font-size: toRemMob(14);
      line-height: toRemMob(15);
      font-weight: 300;
      color: $color-white;
      background: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(2px);
      @include _desktop {
        padding-left: toRem(23);
        padding-right: toRem(5);
        height: toRem(23);
        min-width: toRem(57);
        border-radius: toRem(5);
        font-size: toRem(14);
        line-height: toRem(15);
      }

      &:before {
        content: "";
        position: absolute;
        width: toRemMob(14);
        height: toRemMob(14);
        left: toRemMob(5);
        top: 0;
        bottom: 0;
        margin: auto;
        background-image: url("../assets/images/icons/photo.svg");
        background-size: contain;
        background-repeat: no-repeat;
        @include _desktop {
          width: toRem(14);
          height: toRem(14);
          left: toRem(5);
        }
      }
    }
  }

  &__image {
    &_completed {
      width: toRemMob(414);
      height: toRemMob(414);


      .fit-content {
        pointer-events: none;
        @include _desktop {
          height: 100%;
        }
      }

      @include _desktop {
        width: toRem(482);
        height: toRem(361);
      }
    }
  }

  &__title {
    &-wrapper {
      margin-bottom: toRemMob(20);
      @include _desktop {
        margin-bottom: toRem(22);
      }
    }

    &_completed {
      display: block;
      margin-bottom: toRemMob(10);
      font-weight: 400;
      font-size: toRemMob(22);
      line-height: toRemMob(26);
      @include _desktop {
        margin-bottom: toRem(10);
        font: 500 toRem(22)/toRem(22) "Roboto";
        line-height: toRem(26);
      }
    }
  }

  &__description {
    &_completed {
      font: 300 toRemMob(16)/toRemMob(24) "Roboto";
      @include _desktop {
        font: 300 toRem(18)/toRem(27) "Roboto";
        padding-top: toRem(20);
      }
    }
  }

  &__date {
    display: inline;
    color: $color-grey-9;
    font: 300 toRemMob(14)/toRemMob(17) "Roboto";
    @include _desktop {
      font: 300 toRem(18)/toRem(24) "Roboto";
    }
  }
}

</style>