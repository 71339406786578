import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import VueYandexMetrika from "vue-v3-yandex-metrika";
import store from "@/store";


import { Axios } from "@/assets/core/http-common";

import { plugin as VueTippy } from "vue-tippy";
import "tippy.js/dist/tippy.css"; // optional for styling
import "./assets/styles/tippy.scss";

const countries = [
  "ru",
  "gb",
  "de",
  "es",
  "ua",
  "ca"
];

const {defaultLanguage} = require("./cfg")

const host = process.env.NODE_ENV === "production" ? "/" : process.env.VUE_APP_HOST ? process.env.VUE_APP_HOST : "http://localhost:8000/";
const serverHost = process.env.NODE_ENV === "production" ? "/" : process.env.VUE_APP_HOST ? process.env.VUE_APP_HOST : "https://greendi.com/";
const initApp = ({ translates, cities, currentCountry, countries, currentCity }) => {
  const app = createApp(App);
  app.config.globalProperties.$host = host;
  app.config.globalProperties.$serverHost = serverHost;
  app.config.globalProperties.$countries = countries;
  app.config.unwrapInjectedRef = true;
  store.commit("updateLocales", translates);
  store.commit("updateCities", cities);
  store.commit("updateCountries", countries);
  store.commit("changeCurrentCity", currentCity);
  store.commit("changeCurrentCountry", currentCountry);
  app
    .use(router)
    .use(store)
    .use(VueYandexMetrika, {
      id: 88159038,
      router: router,
      env: process.env.NODE_ENV
    })
    .use(
      VueTippy,
      // optional
      {
        directive: "tippy", // => v-tippy
        component: "tippy", // => <tippy/>
        componentSingleton: "tippy-singleton", // => <tippy-singleton/>,
        defaultProps: {
          placement: "top",
          allowHTML: true
        } // => Global default options * see all props
      }
    )
    .mount("#app");
};

const runApp = async () => {
  try {
    const currentLocation = document.location.pathname.split('/')[1]

    const params = {
      "country-geo": currentLocation.length === 2 ? currentLocation : null,
      "city-geo": currentLocation.length === 3 ? currentLocation : null
    };
    const userLocationInfo = await Axios.get(`/locations/`, { params: params }).then(({ data }) => data);
    // Await all requests
    const translates = {};

    const language = userLocationInfo.current_country?.abbreviation || defaultLanguage

    try {
      const url = `translates/${language}/`;
      // await request and get data from him
      const response = await Axios.get(`${process.env.VUE_APP_DOMAIN}/api/v1/` + url);
      const responseData = response.data;

      if (responseData?.errors) {
        return console.error(`Error with get translate: ${responseData.message}.`);
      }
      translates[language] = responseData[language];
    } catch (error) {
      console.error(error);
    }
    return initApp(
      {
        translates,
        currentCity: userLocationInfo.current_city,
        cities: userLocationInfo.country_cities,
        currentCountry: userLocationInfo.current_country,
        countries: userLocationInfo.countries
      }
    );
  } catch (error) {
    console.error(error);
  }
};


runApp();


export { store, countries };
