<template>
  <modal-base ref="modalBase" :wordTranslation="wordTranslation">
    <template #modal-error></template>
    <template #modal-success>
      <div class="modal__success">
        <div class="modal__image modal__image_success"></div>
        <h2 class="modal__title modal__title_success">{{ wordTranslation("base_modal.hooray") }}</h2>
        <p class="modal__description modal__description_success">
          {{ wordTranslation("preorder_modal.pre_order_info") }}
        </p>
      </div>
    </template>
    <template #modal-title>
      <template v-if="greenhouse.is_can_order">
        {{ wordTranslation("preorder_modal.title_order") }}
      </template>
      <template v-else>
        {{ wordTranslation("preorder_modal.title_preorder") }}
      </template>
      <template v-if="greenhouse.is_for_sale">
        <!--      Тут стоит span из-за пробела. Он почему-то нормально не ставился :)-->
        <span>&nbsp;{{ wordTranslation("preorder_modal.greenhouse") }}</span>
        {{ getTranslateJsonField("title", greenhouse) }}
      </template>
    </template>
    <template #modal-content>
      <div class="modal__list">
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            {{ wordTranslation("preorder_modal.fio_input") }}&nbsp;<span>*</span>
          </div>
          <input
            @input="fioError = ''"
            class="input input_modal input_modal-name"
            v-model="fio"
            type="text"
            name="fio"
            :placeholder="wordTranslation('preorder_modal.fio_placeholder')"
          >
          <div class="error__placeholder" v-show="fioError.length">
            {{ fioError }}
          </div>
        </div>
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            &nbsp;{{ wordTranslation("preorder_modal.phone_placeholder") }}<span>*</span>
          </div>
          <modalWindowMask
            @update:phone="phone = $event.phone, phoneObject = $event.phoneObject"
            @input="phoneError = ''"
            :phone="phone"
            :word-translation="wordTranslation"
            ref="modalWindowMask"
          />
          <div class="error__placeholder" v-show="phoneError.length">
            {{ phoneError }}
          </div>
        </div>
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            {{ wordTranslation("preorder_modal.variants_execution") }}&nbsp;<span>*</span>
          </div>
          <div class="item__list item__list_variants">
            <div class="item__variants"
                 v-for="variantExecution in variantsExecutions"
                 @click="activeVariant = variantExecution.id"
                 :key="`variant-execution-${variantExecution.id}`"
                 :class="{'active': activeVariant === variantExecution.id}"
            >
              <div
                :style="`background-image: url(${$host + variantExecution.modal_image})`"
                class="item__image item__image_variants"
              >
              </div>
              <div class="item__description item__description_variants">
                {{ getTranslateJsonField("title", variantExecution) }}
              </div>
            </div>
          </div>
        </div>
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            {{ wordTranslation("preorder_modal.comment") }}
          </div>
          <textarea
            @input="commentError = ''"
            v-model="comment"
            :placeholder="wordTranslation('preorder_modal.comment_placeholder')"
            class="textarea textarea_modal"
          ></textarea>
          <div class="error__placeholder" v-show="commentError.length">
            {{ commentError }}
          </div>
        </div>
      </div>
    </template>
    <template #modal-confirm>
      <button class="button button_modal button_green" @click="modalValidation">
        {{ wordTranslation("buttons.place") }}
      </button>
      <p class="modal__politic">
        {{ wordTranslation("preorder_modal.politic_text") }}<br>
        <a
          class="link link_blue link_modal"
          download="user_agreement.pdf"
          href="/user_agreement.pdf"
        >
          {{ wordTranslation("base_modal.personal_data") }}
        </a>
      </p>
    </template>
  </modal-base>
</template>

<script>

import ModalBase from "@/components/modal/ModalBase";

import modalWindowMask from "@/components/modal/ModalWindowMask";

export default {
  name: "ModalPreorder",
  components: {
    ModalBase,
    modalWindowMask
  },
  props: {
    getTranslateJsonField: Function,
    wordTranslation: Function,
  },
  data() {
    return {
      fio: "",
      fioError: "",
      phone: "",
      phoneError: "",
      comment: "",
      commentError: "",
      phoneObject: {},
      greenhouse: {},
      activeVariant: null,
      variantsExecutions: []
    };
  },
  methods: {
    open(greenhouse) {
      this.greenhouse = greenhouse;
      try {
        if (typeof greenhouse?.variants_execution === "object") {
          this.variantsExecutions = greenhouse.variants_execution;
        } else {
          this.variantsExecutions = JSON.parse(greenhouse?.variants_execution || "[]");
          if (typeof greenhouse.title !== "object") this.greenhouse.title = JSON.parse(greenhouse.title || "{}");
        }
        if (this.variantsExecutions.length) {
          this.activeVariant = this.variantsExecutions[0].id;
        }
      } catch (error) {
        this.activeVariant = 1;
        console.error(error);
      }
      return this.$refs.modalBase.open();
    },
    close() {
      return this.$refs.modalBase.close();
    },
    regexValidation(re, str) {
      return re.test(str);
    },
    fioValidation() {
      if (!this.regexValidation(/^[\w \-А-я]{1,128}$/, this.fio)) {
        this.fioError = this.wordTranslation("base_modal.fio_error");
        return false;
      }
      this.fioError = "";
      return true;
    },
    phoneValidation() {
      if (!this.phoneObject.valid) {
        this.phoneError = this.wordTranslation("base_modal.phone_error");
        return false;
      }
      this.phoneError = "";
      return true;
    },
    commentValidation() {
      if (!this.regexValidation(/^[\wА-я.,–+=%?!()№*:;\r\n«»$"'\-/ ]{0,1024}$/, this.comment)) {
        this.commentError = this.wordTranslation("base_modal.comment_error");
        return false;
      }
      this.commentError = "";
      return true;
    },
    modalValidation() {
      this.fioValidation();
      this.phoneValidation();
      this.commentValidation();
      if (!this.phoneError.length && !this.fioError.length && !this.commentError.length) {
        return this.$refs.modalBase.confirm({
          fio: this.fio, phone: this.phoneObject.formatted, text: this.comment,
          variants_execution_id: this.activeVariant
        });
      }
    },
    async showError() {
      return new Promise(resolve => {
        this.$refs.modalBase.showError();
        setTimeout(() => {
          this.$refs.modalBase.hideError();
          resolve();
        }, 3000);
      });
    },
    async showSuccess() {
      return new Promise(resolve => {
        this.$refs.modalBase.showSuccess();
        setTimeout(() => {
          this.fio = "";
          this.phone = "";
          this.comment = "";
          this.$refs.modalWindowMask.clearPhone();
          this.$refs.modalBase.hideSuccess();
          this.$refs.modalBase.close();
          resolve();
        }, 3000);
      });
    },
    showPreloader() {
      return this.$refs.modalBase.showPreloader();
    },
    hidePreloader() {
      return this.$refs.modalBase.hidePreloader();
    }
  }
};
</script>

<style lang="scss">
.item {
  &__list {
    &_variants {
      display: flex;
      justify-content: space-between;
      margin-top: toRemMob(13);
      @include _desktop {
        margin-top: toRem(11);
      }
    }
  }

  &__variants {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .2s ease;
    cursor: pointer;
    position: relative;
    border-radius: toRemMob(2);
    padding: toRemMob(3) toRemMob(8) toRemMob(10) toRemMob(8);
    width: 25%;

    @include _desktop {
      align-items: center;
      width: toRem(101);
      border-radius: toRem(2);
      padding: toRem(3) toRem(10) toRem(10) toRem(10);
    }

    &:before {
      content: '';
      position: absolute;
      width: toRemMob(5);
      height: toRemMob(5);
      right: toRemMob(5);
      top: toRemMob(5);
      border-radius: 50%;
      transform: scale(0);
      background-color: transparent;
      transition: all .2s ease;
      @include _desktop {
        width: toRem(5);
        height: toRem(5);
        right: toRem(5);
        top: toRem(5);
      }
    }


    &.active, &:hover {
      box-shadow: 0 0 toRemMob(5) rgba(0, 0, 0, 0.2);
      border-radius: toRemMob(2);

      @include _desktop {
        box-shadow: 0 0 toRem(5) rgba(0, 0, 0, 0.2);
        border-radius: toRem(2);
      }
    }

    &.active {
      &:before {
        transform: scale(1);
        background-color: $color-green-2;
      }
    }

    &:active {
      background: rgba($color-green, 0.1);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

  }

  &__description {
    &_variants {
      font: 300 toRemMob(12)/toRemMob(14) "Roboto";
      color: $color-black;
      @include _desktop {
        font: 300 toRem(14)/toRem(16) "Roboto";
      }
    }
  }

  &__image {
    &_variants {
      width: toRemMob(60);
      height: toRemMob(60);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      @include _desktop {
        width: toRem(72);
        height: toRem(72);
        margin-bottom: toRem(2);
      }
    }
  }
}
</style>
