<template>
  <div v-show="isOpen" @click="close" ref="modal" class="modal">
    <div @click.stop class="modal__body" :class="{'hidden': isHiddenModal}" ref="modalBody">
      <slot name="modal-error" v-if="isWarning">
        <div class="modal__error">
          <div class="modal__image modal__image_warning"></div>
          <h2 class="modal__title modal__title_warning">{{ wordTranslation("base_modal.oops") }}</h2>
          <p class="modal__description modal__description_warning">
            {{ warningText || wordTranslation("base_modal.error_text") }}
          </p>
        </div>
      </slot>
      <slot name="modal-warning" v-if="isError">
        <div class="modal__error">
          <div class="modal__image modal__image_error"></div>
          <h2 class="modal__title modal__title_error">{{ wordTranslation("base_modal.ouch") }}</h2>
          <p class="modal__description modal__description_error">
            {{ wordTranslation("base_modal.error_text") }}
          </p>
        </div>
      </slot>
      <slot name="modal-success" v-if="isSuccess"></slot>
      <slot name="modal-preloader" v-if="isShowPreloader">
        <GreenhousePreloader></GreenhousePreloader>
      </slot>
      <div class="modal__header">
        <slot :close="close" name="modal-close">
          <div class="modal__close" @click="close"></div>
        </slot>
        <h2 class="modal__title" v-if="!isModalCountry">
          <slot name="modal-title">

          </slot>
        </h2>
      </div>
      <div class="modal__content">
        <slot name="modal-content">
        </slot>
      </div>
      <div class="modal__footer">
        <slot name="modal-actions">
          <slot
            :confirm="confirm"
            name="modal-confirm"
          >
            {{ wordTranslation("base_modal.send") }}
          </slot>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import GreenhousePreloader from "@/components/GreenhousePreloader";

export default {
  currentModalController: null,
  name: "ModalBase",
  emits: {
    "close": null,
    "open": null
  },
  props: {
    wordTranslation: Function,
    isModalCountry: {default: false, type: Boolean},
    callbackClose: Function,
  },
  inject: ["deviceWidth"],
  data() {
    return {
      isOpen: false,
      isError: false,
      isWarning: false,
      warningText: "",
      isSuccess: false,
      isShowPreloader: false,
      isHiddenModal: false,
      isShowText: false,
      modalFullText: "",
    };
  },
  components: {
    GreenhousePreloader
  },
  methods: {
    async open() {
      let resolve;
      let reject;
      const modalPromise = new Promise((ok, fail) => {
        resolve = ok;
        reject = fail;
      });
      this.$options.currentModalController = {resolve, reject};
      this.isOpen = true;
      this.toggleBlockScroll(true);
      this.$emit("open");
      return modalPromise;
    },
    close() {
      this.toggleBlockScroll(false);
      this.$options.currentModalController.resolve(false);
      this.isOpen = false;
      this.callbackClose && this.callbackClose();
      this.$emit("close");
    },
    confirm(modalData = null) {
      return this.$options.currentModalController.resolve(modalData);
    },
    showError() {
      this.isError = true;
      this.toggleBlockModal();
    },
    hideError() {
      this.isError = false;
      this.toggleBlockModal();
    },
    showWarning(warningText) {
      this.isWarning = true;
      this.warningText = warningText;
      this.toggleBlockModal();
    },
    hideWarning() {
      this.isWarning = false;
      this.warningText = "";
      this.toggleBlockModal();
    },
    showSuccess() {
      this.isSuccess = true;
      this.toggleBlockModal();
    },
    hideSuccess() {
      this.isSuccess = false;
      this.toggleBlockModal();
    },
    showPreloader() {
      this.isShowPreloader = true;
      this.toggleBlockModal();
    },
    hidePreloader() {
      this.isShowPreloader = false;
      this.toggleBlockModal();
    },
    toggleBlockModal() {
      this.isHiddenModal = !this.isHiddenModal;
      this.scrollToTop();
    },
    scrollToTop() {
      if (this.$refs.modalBody) {
        this.$refs.modalBody.scrollTo(0, 0);
      }
    },
    showModalFullText(text) {
      this.isShowText = true;
      this.modalFullText = text;
    },
    hideModalFullText() {
      this.isShowText = false;
      this.modalFullText = "";
    },
    toggleBlockScroll(isBlock) {
      const modal = this.$refs.modal;
      const htmlElement = document.documentElement;
      // Padding need if header have style position: fixed.
      const header = document.querySelector("header.header-move");
      const section = document.querySelectorAll(".content .section");
      const lockPaddingValue = window.innerWidth - document.body.offsetWidth + "px";

      if (isBlock) {
        modal.style.paddingLeft = "0";
        htmlElement.style.overflow = "hidden";
        if (this.deviceWidth < 640) {
          section.forEach(item => {
            item.style.visibility = "hidden";
            item.style.overflow = "hidden";
          });
        }
        htmlElement.style.paddingRight = lockPaddingValue;
        if (header) {
          header.style.paddingRight = lockPaddingValue;
        }
      } else {
        modal.style.paddingLeft = lockPaddingValue;
        htmlElement.style.overflow = "";
        if (this.deviceWidth < 640) {
          section.forEach(item => {
            item.style.visibility = "visible";
            item.style.overflow = "";
          });
        }
        htmlElement.style.paddingRight = "0px";
        if (header) {
          header.style.paddingRight = "0px";
        }
      }
    }
  }
};
</script>

<style lang="scss">


textarea, input {
  &::placeholder {
    color: $color-grey-13;
  }
}

.button {
  &_modal {
    display: block;
    margin-left: auto;
    margin-right: 0;
    padding: toRemMob(16) toRemMob(28);
    color: $color-white;
    font-size: toRemMob(18);
    line-height: toRemMob(18);
    @include _desktop {
      padding: toRem(16) toRem(28);
      font-size: toRem(18);
      line-height: toRem(18);
      border-radius: toRem(5);
    }

    &:hover {
      opacity: .8;
    }
  }

  &_add-image {
    display: block;
    padding: 0;
    margin: toRemMob(29) auto toRemMob(41);
    font-size: toRemMob(16);
    line-height: toRemMob(19);
    font-weight: 400;
    text-align: center;
    color: #408CFF;
    @include _desktop {
      margin-top: toRem(31);
      margin-bottom: toRem(31);
    }

    &:active {
      background: unset !important;
      -webkit-tap-highlight-color: unset !important;
    }
  }
}


.modal {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  min-height: 100%;
  background: rgba($color-black, .39);
  z-index: 9998;

  &__title {
    color: $color-black;
    font-size: toRemMob(28);
    line-height: toRemMob(38);
    font-weight: 300;
    @include _desktop {
      font-size: toRem(28);
      line-height: toRem(38);
    }

    &_error {
      margin-bottom: toRemMob(11);
      font-weight: 700;
      font-size: toRemMob(50) !important;
      line-height: toRemMob(59) !important;

      @include _desktop {
        margin-bottom: toRem(11);
        font-size: toRem(50) !important;
        line-height: toRem(59) !important;
      }
    }

    &_warning {
      margin-bottom: toRemMob(11);
      font-weight: 700;
      font-size: toRemMob(50) !important;
      line-height: toRemMob(59) !important;

      @include _desktop {
        margin-bottom: toRem(11);
        font-size: toRem(50) !important;
        line-height: toRem(59) !important;
      }
    }

    &_success {
      margin-bottom: toRemMob(11);
      font-weight: 700;
      font-size: toRemMob(50);
      line-height: toRemMob(59);

      @include _desktop {
        margin-bottom: toRem(11);
        font-size: toRem(50) !important;
        line-height: toRem(59) !important;
      }
    }
  }

  &__description {
    &_error {
      margin: 0 toRemMob(70);
      font-weight: 300;
      font-size: toRemMob(18);
      line-height: toRemMob(24);
      text-align: center;

      @include _desktop {
        margin: 0 toRem(133);
        font-size: toRem(18);
        line-height: toRem(24);
      }
    }

    &_warning {
      margin: 0 toRemMob(70);
      font-weight: 300;
      font-size: toRemMob(18);
      line-height: toRemMob(24);
      text-align: center;

      @include _desktop {
        margin: 0 toRem(133);
        font-size: toRem(18);
        line-height: toRem(24);
      }
    }


    &_success {
      margin: 0 toRemMob(42);
      font-weight: 300;
      font-size: toRemMob(18);
      line-height: toRemMob(24);
      text-align: center;

      @include _desktop {
        margin: 0 toRem(106);
        font-size: toRem(18);
        line-height: toRem(24);
      }

      &-presentation {
        margin: 0 toRem(44);
        @include _desktop {
          margin: 0 toRem(52);
        }
      }
    }
  }

  &__body {
    position: fixed;
    z-index: 9999;
    background: $color-white;
    height: 100%;
    width: 100%;
    padding: toRemMob(55) toRemMob(32) toRemMob(36);
    overflow: auto;
    @include _desktop {
      padding: toRem(54) toRem(46) toRem(29) toRem(55);
      min-width: toRem(541);
      max-width: toRem(541);
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      border-radius: toRem(10);
      margin: unset;
      max-height: 90%;
      width: auto;
      height: auto;
    }

    &.hidden {
      overflow: hidden !important;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: toRemMob(60);
    @include _desktop {
      margin-bottom: toRem(47);
    }
  }

  &__content {
    margin-bottom: toRemMob(25);
    @include _desktop {
      margin-bottom: toRem(30);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__close {
    width: toRemMob(20);
    height: toRemMob(20);
    background-image: url("../../assets/images/icons/close-modal.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: toRemMob(25);
    top: toRemMob(25);
    @include _desktop {
      width: toRem(20);
      height: toRem(20);
      right: toRem(25);
      top: toRem(25);
    }

  }

  &__politic {
    margin-top: toRemMob(29);
    font-size: toRemMob(12);
    line-height: toRemMob(14);
    text-align: center;
    font-weight: 300;

    @include _desktop {
      margin-top: toRem(65);
      font-size: toRem(12);
      line-height: toRem(14);
    }
  }

  &__error {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $color-white;
    font-size: toRemMob(28);
    line-height: toRemMob(38);
    font-weight: 300;
    color: $color-black;

    @include _desktop {
      font-size: toRem(28);
      line-height: toRem(38);
    }
  }

  &__image {
    &_error {
      margin-bottom: toRemMob(26);
      width: toRemMob(88);
      height: toRemMob(87);
      background-image: url("../../assets/images/modal/modal-error.png");
      background-repeat: no-repeat;
      background-size: contain;

      @include _desktop {
        margin-bottom: toRem(26);
        width: toRem(88);
        height: toRem(87);
      }
    }

    &_warning {
      margin-bottom: toRemMob(26);
      width: toRemMob(88);
      height: toRemMob(87);
      background-image: url("../../assets/images/modal/modal-warning.png");
      background-repeat: no-repeat;
      background-size: contain;

      @include _desktop {
        margin-bottom: toRem(26);
        width: toRem(88);
        height: toRem(87);
      }
    }

    &_success {
      margin-bottom: toRemMob(36);
      width: toRem(126);
      height: toRem(93);
      background-image: url("../../assets/images/modal/modal-success.png");
      background-repeat: no-repeat;
      background-size: contain;

      @include _desktop {
        margin-bottom: toRem(36);
        width: toRem(126);
        height: toRem(93);
      }
    }
  }

  &__success {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $color-white;
    font-size: toRemMob(28);
    line-height: toRemMob(38);
    font-weight: 300;
    color: $color-black;

    @include _desktop {
      font-size: toRem(28);
      line-height: toRem(38);
    }
  }

}

.item {
  &_modal {
    margin-bottom: toRemMob(32);
    @include _desktop {
      margin-bottom: toRem(30);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    &_modal {
      padding-left: toRemMob(2);
      margin-bottom: toRemMob(6);
      font-size: toRemMob(16);
      line-height: toRemMob(16);
      font-weight: 400;

      @include _desktop {
        padding-left: unset;
        margin-bottom: toRem(5);
        font-size: toRem(16);
        line-height: toRem(19);
      }

      span {
        color: $color-red;
      }

      &-mail {
        display: flex;
        align-items: center;
      }
    }
  }
}

.input {
  &_modal {
    &-name, &-code, &-mail, &-presentation, &-city, &-youtube, &-presentation__item {
      position: relative;
      width: 100%;
      padding: toRemMob(4) toRemMob(2);
      background: $color-white;
      border-bottom: toRemMob(1) solid $color-grey-22;
      font-weight: 300;
      font-size: toRemMob(16);
      line-height: toRemMob(16);
      text-align: left;
      @include _desktop {
        border-bottom: toRem(1) solid $color-grey-22;
        padding: toRem(4) toRem(4) toRem(4) toRem(1);
        font-size: toRem(16);
        line-height: toRem(19);
      }
    }

    &-phone {
      position: relative;
      background: $color-white;
      font-weight: 300;
      font-size: toRemMob(16);
      line-height: toRemMob(19);
      padding: toRemMob(4) toRemMob(4) toRemMob(4) toRemMob(1);
      width: 100%;
      text-align: left;
      @include _desktop {

      }
    }
  }
}

.textarea {
  &_modal {
    width: 100%;
    height: toRemMob(150);
    border: toRemMob(1) solid $color-grey-22;
    padding: toRemMob(7) toRemMob(11);
    resize: none;
    font-weight: 300;
    font-size: toRemMob(16);
    line-height: toRemMob(19);
    outline: 0;
    @include _desktop {
      height: toRem(150);
      padding: toRem(10);
      border: toRem(1) solid $color-grey-22;
    }
  }
}

.error__placeholder {
  position: absolute;
  margin: 5px;
  color: red;
  font-size: toRem(12);
}

.warning__placeholder {
  position: absolute;
  margin: 5px;
  color: #000000;
  font-size: toRem(12);
}

</style>