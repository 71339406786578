<template>
  <v-main-wrapper
    :get-translate-field="getTranslateField"
    :word-translation="wordTranslation"
    :get-translate-json-field="getTranslateJsonField"
    :gallery-init="galleryInit"
    :open-sign-up-on-presentation="openSignUpOnPresentation"
    :open-download-presentation="openDownloadPresentation"
    :videos="videos"
    :sample-sales="sampleSales"
    :another-countries="anotherCountries"
    :get-comment-title="getCommentTitle"
  />
</template>

<script>

import {mapGetters} from "vuex";

const {changeMeta} = require("@/assets/core/seo");
import VMainWrapper from "@/components/index-page/v-main-wrapper";
import {Axios} from "@/assets/core/http-common";

export default {
  name: "HomePage",
  inject: ["showPagePreloader"],
  emits: {
    "init-change-seo": null
  },
  props: {
    getTranslateField: Function,
    wordTranslation: Function,
    getTranslateJsonField: Function,
    galleryInit: Function,
    getMinVariantExecutionPrice: Function,
    openSignUpOnPresentation: Function,
    sampleSales: Array,
    anotherCountries: Array,
    openDownloadPresentation: Function,
    getCommentTitle: Function
  },
  components: {
    VMainWrapper
  },
  created() {
    this.showPagePreloader();
    this.changeSeo();
    this.$emit("init-change-seo", this.changeSeo);
    this.getVideos();
  },
  data() {
    return {
      videos: []
    };
  },
  computed: {
    ...mapGetters(["currentCity", "currentCountry", "locales"])
  },
  methods: {
    changeSeo() {
      const currentSeo = this.locales[this.currentCountry?.abbreviation][this.$route.name];
      if (!currentSeo) {
        return;
      }
      let {title, description} = currentSeo;
      if (this.currentCity) {
        title = currentSeo.city_title;
        description = currentSeo.city_description;
      }
      title = title
        .replace("[[country]]", this.getTranslateJsonField("title", this.currentCountry))
        .replace("[[cityTitle]]", this.currentCity?.seo_title);
      description = description
        .replace("[[country]]", this.getTranslateJsonField("title", this.currentCountry))
        .replace("[[cityTitle]]", this.currentCity?.seo_title);
      changeMeta({title, description});
    },
    async getVideos() {
      const params = {
        'current-page': 'HomePage',
        'country-id': this.currentCountry?.id
      }

      this.videos = await Axios.get('videos/', {params}).then(({data}) => data.videos);
    }
  }
};
</script>
