<template>
  <div class="preloader">
    <div id="loader">
      <svg width="59" height="51" viewBox="0 0 59 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M27.7209 49.759C28.4458 50.3197 29.5205 50.2217 30.1235 49.5275L57.7949 17.671C58.3343 17.0501 58.3109 16.1538 57.7143 15.5741L43.1076 1.1111L43.0967 1.0988C42.7652 0.724299 42.2868 0.5 41.7852 0.5H15.8452C15.3613 0.5 14.9005 0.700001 14.5685 1.0383L0.81182 15.6023C0.27482 16.1811 0.242406 17.0408 0.752406 17.6486L27.3745 49.3772L27.3871 49.3951C27.4835 49.5328 27.5946 49.6488 27.7209 49.759ZM5.86401 15.0643L16.6017 3.6992H19.267L14.7643 15.0643H5.86401ZM18.3845 15.0643L22.8778 3.6992H34.7367L39.2299 15.0643H18.3845ZM39.219 18.2636L28.8138 44.0313L18.3961 18.2636H39.219ZM34.1149 39.8877L42.8411 18.2636H52.9031L34.1149 39.8877ZM42.8511 15.0643L38.3605 3.6992H41.0491L52.5297 15.0643H42.8511ZM5.57032 18.2636H14.7738L23.3213 39.4194L5.57032 18.2636Z"
              fill="#3CB156"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "GreenhousePreloader"
};
</script>

<style scoped lang="scss">

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  overflow-x: hidden;
}

#loader {
  position: relative;
  display: block;
  width: toRemMob(100);
  height: toRemMob(100);
  @include _desktop {
    width: toRem(150);
    height: toRem(150);
  }

  svg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: toRemMob(30);
    height: toRemMob(30);
    @include _desktop {
      width: toRem(60);
      height: toRem(60);
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: toRemMob(5);
    left: toRemMob(5);
    right: toRemMob(5);
    bottom: toRemMob(5);
    border-radius: 50%;
    border-width: toRemMob(2);
    border-style: solid;
    border-color: transparent;
    border-top-color: $color-green-2;
    border-left-color: $color-green-2;
    border-bottom-color: $color-green-2;
    animation: spin 3s linear infinite;
    @include _desktop {
      top: toRem(5);
      left: toRem(5);
      right: toRem(5);
      bottom: toRem(5);
      border-width: toRem(2);
    }
  }
}


</style>