<template>
  <section class="section section_greenhouse">
    <div class="container">
      <!--      <button-->
      <!--        @click="openModalCode"-->
      <!--        class="button button_cod"-->
      <!--      >-->
      <!--        {{ wordTranslation("buttons.configuration_code") }}-->
      <!--      </button>-->
      <div class="section__list section__list_greenhouse">
        <v-catalog-item
          :get-translate-field="getTranslateField"
          :send-modal-preorder="sendModalPreorder"
          :open-preorder="openPreorder"
          :word-translation="wordTranslation"
          :get-translate-json-field="getTranslateJsonField"
          :gallery-init="galleryInit"
        />
        <div class="section__phrase-main">
          <v-phrase :word-translation="wordTranslation"/>
        </div>
        <div class="section__sample-sales" v-if="sampleSales">
          <v-sample-sales
            :word-translation="wordTranslation"
            :nice-price="nicePrice"
            :get-fake-old-price="getFakeOldPrice"
            :sample-sales="sampleSales"
          />
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import vCatalogItem from "@/components/index-page/v-catalog-item";
import vPhrase from "@/components/index-page/v-phrase"
import vSampleSales from "@/components/index-page/v-sample-sales"

export default {
  name: "vCatalog",
  props: {
    getTranslateField: Function,
    sendModalPreorder: Function,
    openPreorder: Function,
    wordTranslation: Function,
    getTranslateJsonField: Function,
    galleryInit: Function,
    openModalCode: Function,
    sampleSales: Array,
  },
  components: {
    vCatalogItem,
    vPhrase,
    vSampleSales
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.button {
  &_cod {
    padding: toRemMob(8) toRemMob(22) toRemMob(7);
    margin-bottom: toRemMob(30);
    border: toRemMob(1) solid $color-grey-4;
    border-radius: toRemMob(5);
    font: 300 toRemMob(16)/toRemMob(24) "Roboto";
    color: $color-grey-3;
    background-color: transparent;
    @include _desktop {
      padding: toRem(8) toRem(22) toRem(7);
      margin-bottom: toRem(77);
      border: toRem(1) solid $color-grey-4;
      border-radius: toRem(5);
      font: 300 toRem(16)/toRem(24) "Roboto";
      color: $color-grey-3;
    }

    &:hover {
      background-color: $color-grey-2;
      color: $color-white;
      border-color: $color-grey-2;
    }
  }
}

.section {
  &_greenhouse {
    padding: 0 0 toRemMob(20) 0;
    min-height: toRem(2726);
    @include _desktop {
      padding: toRem(78) 0 toRem(41);
      min-height: toRem(3642);
    }
  }

  &__phrase-main {
    .phrase {
      &__container {
        max-width: toRem(300);
        @include _desktop {
          max-width: 100%;
          top: toRem(-100);
        }
      }

      &__text {
        font-size: toRem(35);
        line-height: toRem(41);
      }
    }

    .placeholder {
      @include _desktop {
        width: toRem(25);
        height: toRem(25);
      }
    }

    .tippy__orientation {
      top: toRem(-28);
      @include _desktop {
        left: 0;
        top: toRem(10);
      }
    }
  }

  &__sample-sales {
    max-width: toRem(600);
    @include _desktop {
      max-width: 100%;
    }
  }
}
</style>

