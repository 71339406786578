const { Axios } = require("@/assets/core/http-common");
const { createStore } = require("vuex");


// Create a new store instance.
const store = createStore({
  state() {
    return {
      locales: {},
      isNotFound: false,
      greenhouses: [],
      baseGreenhouse: {},
      cart: {},
      cities: [],
      countries: [],
      currentCity: null,
      currentCountry: null,
      cartAdditionalAttributes: []
    };
  }, getters: {
    locales(state) {
      return state.locales;
    }, cart(state) {
      return state.cart;
    }, greenhouses(state) {
      return state.greenhouses;
    }, baseGreenhouse(state) {
      return state.baseGreenhouse;
    }, isNotFound(state) {
      return state.isNotFound;
    }, cities(state) {
      return state.cities;
    }, currentCity(state) {
      return state.currentCity;
    }, currentCountry(state) {
      return state.currentCountry;
    }, countries(state) {
      return state.countries;
    }
  }, mutations: {
    updateLocales(state, languagesObject) {
      state.locales = Object.assign(state.locales, languagesObject);
    }, updateIsNotFound(state, isNotFoundFlag) {
      state.isNotFound = isNotFoundFlag;
    }, updateGreenhouses(state, greenhouses) {
      state.baseGreenhouse = greenhouses;
    }, updateBaseGreenhouse(state, greenhouse) {
      state.baseGreenhouse = greenhouse;
    }, addItemToCart(state, newCartElement) {
      state.cart.push(newCartElement);
    }, updateCart(state, cart) {
      state.cart = cart;
    }, updateCities(state, cities) {
      state.cities = cities;
    }, changeCurrentCity(state, city) {
      state.currentCity = city;
    }, changeCurrentCountry(state, country) {
      state.currentCountry = country;
    }, updateAllAdditionalAttributes(state, allAdditionalAttributes) {
      state.allAdditionalAttributes = allAdditionalAttributes;
    },
    updateCartAdditionalAttributes(state, cartAdditionalAttributes) {
      state.cartAdditionalAttributes = cartAdditionalAttributes;
    },
    updateCountries(state, countries) {
      state.countries = countries;
    }
  }, actions: {
    getCart({ commit }) {
      const localStorageCart = JSON.parse(localStorage.getItem("cart") || "{}");
      Axios.post("cart/", { cart: localStorageCart }).then(({ data }) => {
        const cart = {};
        // Added additional_attributes which was added to cart
        (data?.cart || []).forEach(greenhouse => {
          // get current greenhouse from localstorage

          const currentGreenhouse = (localStorageCart.greenhouses || [])
            .find(greenhouseFind => greenhouseFind.greenhouse_id === greenhouse.greenhouse_id);
          if (!currentGreenhouse) return;

          // get all additional attributes for current greenhouse
          const additionalAttributes = (greenhouse?.variants_execution || [])
            .map(variantExecution => (variantExecution?.configurations || [])
              .map(configuration => configuration.additional_attributes))
            .flat(Infinity);
          // get attributes which was in cart (localstorage)
          const uniqueAddedAdditionalAttributes = additionalAttributes.filter((additionalAttribute, index, selfArray) => {
            return index === selfArray.findIndex(additionalAttributeFindIndex => additionalAttribute.id === additionalAttributeFindIndex.id)
              && (currentGreenhouse.additional_attributes || []).findIndex(item => item.id === additionalAttribute.id) !== -1;
          });
          // sort with index in localstorage
          uniqueAddedAdditionalAttributes.sort((a, b) => {
            return (currentGreenhouse.additional_attributes || [])
                .findIndex(additionalAttributeFindIndex => additionalAttributeFindIndex.id === a.id) -
              (currentGreenhouse.additional_attributes || [])
                .findIndex(additionalAttributeFindIndex => additionalAttributeFindIndex.id === b.id);
          });
          // save added to cart additional_attributes to greenhouse
          greenhouse.additional_attributes = uniqueAddedAdditionalAttributes;
        });

        cart.greenhouses = data.cart;

        commit("updateCart", cart);
      });
    }
  }
});

module.exports = store;
