<template>
  <modal-preorder
    :get-translate-json-field="getTranslateJsonField"
    :get-translate-field="getTranslateField"
    :word-translation="wordTranslation"
    ref="modalPreorder"
  ></modal-preorder>
  <modal-code
    :get-translate-json-field="getTranslateJsonField"
    :word-translation="wordTranslation"
    ref="modalCode"
  />
  <v-first-screen
    :send-modal-preorder="sendModalPreorder"
    :open-sign-up-on-presentation="openSignUpOnPresentation"
    :open-preorder="openPreorder"
    :word-translation="wordTranslation"
    :another-countries="anotherCountries"
    :open-download-presentation="openDownloadPresentation"
  />
  <v-catalog
    :get-translate-field="getTranslateField"
    :send-modal-preorder="sendModalPreorder"
    :open-preorder="openPreorder"
    :word-translation="wordTranslation"
    :get-translate-json-field="getTranslateJsonField"
    :gallery-init="galleryInit"
    :open-modal-code="openModalCode"
    :sample-sales="sampleSales"
  />
  <v-video-review
    :gallery-init="galleryInit"
    :videos="videos"
    :word-translation="wordTranslation"
    :get-translate-json-field="getTranslateJsonField"
  />
  <v-additional
    :word-translation="wordTranslation"
    :get-translate-field="getTranslateField"
  />
  <v-completed
    :get-translate-json-field="getTranslateJsonField"
    :word-translation="wordTranslation"
    :get-translate-field="getTranslateField"
    :gallery-init="galleryInit"
    :get-comment-title="getCommentTitle"
  />
</template>

<script>
import vCatalog from "./v-catalog";
import vAdditional from "./v-additional";
import vCompleted from "../v-completed";
import VFirstScreen from "@/components/index-page/v-first-screen";
import {Axios} from "@/assets/core/http-common";
import ModalPreorder from "@/components/modal/ModalPreorder";
import ModalCode from "@/components/modal/ModalCode";
import vVideoReview from "../v-video-review";

export default {
  preorderUrl: "/preorders/",
  configurationFindUrl: "/configurations/",

  name: "v-main-wrapper",
  props: {
    getTranslateField: Function,
    wordTranslation: Function,
    getTranslateJsonField: Function,
    galleryInit: Function,
    openSignUpOnPresentation: Function,
    videos: Array,
    sampleSales: Array,
    anotherCountries: Array,
    openDownloadPresentation: Function,
    getCommentTitle: Function
  },
  components: {
    vVideoReview,
    ModalPreorder,
    VFirstScreen,
    vCatalog,
    vAdditional,
    vCompleted,
    ModalCode,
  },
  data() {
    return {};
  },
  methods: {
    async sendModalPreorder(modalData) {
      try {
        const response = await Axios.post(this.$options.preorderUrl, modalData);
        return response.data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async openPreorder(greenhouse = null) {
      // Await resolve from modal (confirm, close ...)
      const modalResult = await this.$refs.modalPreorder.open(greenhouse);
      // check on close or confirm event
      if (modalResult && Object.entries(modalResult).length) {
        this.$refs.modalPreorder.showPreloader();

        const data = {
          ...modalResult,
          greenhouse_id: greenhouse?.id,
          page_url: document.location.href,
          country_id: this.$store.getters.currentCountry.id
        };
        // Send form to backend
        const result = await this.sendModalPreorder(data);
        this.$refs.modalPreorder.hidePreloader();
        // if success back response show success and close modal, else show error
        if (result && Object.entries(result).length) {
          this.sendPreorderMetrika(greenhouse?.slug);
          await this.$refs.modalPreorder.showSuccess();
        } else {
          await this.$refs.modalPreorder.showError();
          // Тут костыль небольшой. Что бы повторить все эти действия, мы вызываем сам себя :)
          this.openPreorder(greenhouse);
        }
      }
    },
    async sendModalCode(modalData) {
      try {
        const {code} = modalData;
        const response = await Axios.get(`${this.$options.configurationFindUrl}${code}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async openModalCode() {
      // Await resolve from modal (confirm, close ...)
      const modalResult = await this.$refs.modalCode.open();
      // check on close or confirm event
      if (modalResult && Object.entries(modalResult).length) {
        this.$refs.modalCode.showPreloader();
        const data = {
          ...modalResult,
        };
        // Send form to backend
        const result = await this.sendModalCode(data);
        this.$refs.modalCode.hidePreloader();
        // if success back response show success and close modal, else show error
        if (result && Object.entries(result).length) {
          await this.$refs.modalCode.close();
          this.$router.push({
            name: "GreenhousePage",
            params: {...this.$route.params, greenhouseSlug: result.greenhouse.slug},
            query: {code: data.code}
          });
        } else {
          await this.$refs.modalCode.showError();
          // Тут костыль небольшой. Что бы повторить все эти действия, мы вызываем сам себя :)
          this.openModalCode();
        }
      }
    },
    sendPreorderMetrika(greenhouseSlug) {
      this.$metrika.reachGoal(`placeOrder_${greenhouseSlug}`);
    }
  },
};
</script>
