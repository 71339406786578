import axios from "axios";

export const Axios = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? "/api/v1/" : `${process.env.VUE_APP_HOST ? process.env.VUE_APP_HOST : "http://localhost:8000/"}api/v1/`,
  headers: {
    post: {
      "Content-Type": "application/json; charset = utf-8"
    }
  },
  transformRequest: [function(data) {
    if (process.env.NODE_ENV === "production") {
      const csrfToken = document.querySelector("[csrf-token]")?.getAttribute("csrf-token") || "11111111111";
      return JSON.stringify(Object.assign(data || {}, { "csrf-token": csrfToken }));
    }
    return JSON.stringify(data);
  }]
});
