<template>
  <section class="section section_presentation">
    <div class="section__image section__image_elipse"></div>
    <div class="container">
      <div class="section__wrapper section__wrapper_presentation">
        <div class="section__image section__image_phone"></div>
        <div class="section__columns section__columns_presentation">
          <div class="section__column section__column_presentation">
            <h2 class="section__title section__title_presentation section__title_presentation">
              {{ wordTranslation("greenhouse_video_presentation_block.title") }}
            </h2>
          </div>
          <div class="section__column section__column_presentation">
            <p class="section__description section__description_presentation">
              {{ wordTranslation("greenhouse_video_presentation_block.description") }}
            </p>
            <!--            <a-->
            <!--              class="section__phone section__phone_presentation"-->
            <!--              :href="`tel:${cleanPhone(wordTranslation('greenhouse_video_presentation_block.phone'))}`"-->
            <!--            >-->
            <!--              {{ wordTranslation("greenhouse_video_presentation_block.phone") }}-->
            <!--            </a>-->
            <button
              @click.prevent="openDownloadPresentation"
              class="button button_video-presentation">
              {{ wordTranslation("buttons.sign_up_on_presentation_mobile") }}
            </button>
          </div>
          <div class="section__column section__column_presentation">
            <div class="section__list section__list_presentation-bottom">
              <div class="item item_presentation-bottom">
                <h3 class="item__title item__title_presentation-bottom">
                  {{ wordTranslation("greenhouse_video_presentation_block.social_network") }}:
                </h3>
                <div class="item__list item__list_social">
                  <a :href="wordTranslation('greenhouse_video_presentation_block.instagram_link')"
                     class="element element_social element_social-instagram">
                  </a>
                </div>
              </div>
              <div class="item item_presentation-bottom">
                <h3 class="item__title item__title_presentation-bottom">E-mail:</h3>
                <a :href="`mailto:${wordTranslation('greenhouse_video_presentation_block.email')}`" class="item__email">
                  {{ wordTranslation("greenhouse_video_presentation_block.email") }}
                </a>
              </div>
              <div class="item item_presentation-bottom">
                {{ wordTranslation("greenhouse_video_presentation_block.invite_sellers") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "vPresentation",
  props: {
    sendModalPresentation: Function,
    wordTranslation: Function,
    openDownloadPresentation: Function
  },
  inject: ["deviceWidth"],
  methods: {
    cleanPhone(rawPhone) {
      return rawPhone.replace(/\D/g, "");
    },
  }
};
</script>

<style scoped lang="scss">

.button {
  &_video-presentation {
    width: max-content;
    padding: toRemMob(10) toRemMob(48);
    border-radius: toRemMob(4);
    border: 0;
    cursor: pointer;
    color: $color-white;
    background-color: $color-green;
    font-weight: 300;
    font-size: toRemMob(16);
    line-height: toRemMob(16);
    text-align: center;
    z-index: 2;
    @include _desktop {
      z-index: unset;
      padding: toRem(15) toRem(58);
      border-radius: toRem(4);
      font-size: toRem(20);
      line-height: toRem(20);
    }

    span {
      &:last-child {
        display: none;
        @include _desktop {
          display: inline-block;
        }
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.section {
  &_presentation {
    position: relative;
    overflow: hidden;
    background-color: $color-grey;
    padding: toRemMob(50) 0 toRemMob(80);
    @include _desktop {
      padding: 0;
    }
  }

  &__wrapper {
    &_presentation {
      position: relative;
      display: flex;
      align-items: center;
      @include _desktop {
        min-height: toRem(890);
        padding-bottom: toRem(25);
      }
    }
  }

  &__columns {
    &_presentation {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      @include _desktop {
        width: toRem(530);
        padding-bottom: toRem(11);
      }
    }
  }

  &__column {
    &_presentation {
      &:nth-child(1) {
        margin-bottom: toRemMob(31);
        @include _desktop {
          margin-bottom: unset;
        }
      }

      &:nth-child(2) {
        width: 52%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-bottom: toRemMob(75);

        @include _desktop {
          width: 100%;
          margin-left: unset;
          margin-bottom: toRem(107);
        }
      }

      &:nth-child(3) {
        margin-left: toRemMob(42);
        @include _desktop {
          margin-left: unset;
        }
      }
    }
  }

  &__image {
    &_elipse {
      position: absolute;
      width: toRemMob(927.34);
      height: toRemMob(662.19);
      top: toRemMob(159);
      left: toRemMob(-130);
      background-size: contain;
      background-image: url("../assets/images/presentation/element-mob.png");
      background-repeat: no-repeat;
      @include _desktop {
        width: toRem(1349.43);
        height: toRem(1141.32);
        left: -21%;
        top: -22%;
        background-image: url("../assets/images/presentation/element-desktop.png");
      }
    }

    &_phone {
      position: absolute;
      z-index: 1;
      width: toRemMob(300);
      height: toRemMob(300);
      left: toRemMob(-16);
      top: toRemMob(92);
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../assets/images/presentation/phone.png");
      @include _desktop {
        width: toRem(776);
        height: toRem(568);
        left: toRem(12);
        top: toRem(114);
        background-image: url("../assets/images/presentation/desktop.png");
      }
    }
  }

  &__title {
    &_presentation {
      font: 700 toRemMob(28)/toRemMob(33) "Roboto";
      padding: 0 toRemMob(42);
      @include _desktop {
        margin-bottom: toRem(35);
        padding: 0;
        font: 700 toRem(55)/toRem(64) "Roboto";
      }
    }
  }

  &__description {
    &_presentation {
      max-width: toRemMob(181);
      font: 300 toRemMob(16)/toRemMob(24) "Roboto";
      padding-right: toRemMob(27);
      margin-bottom: toRemMob(27.46);
      @include _desktop {
        max-width: unset;
        padding-right: unset;
        width: 85%;
        margin-bottom: toRem(50);
        font: 300 toRem(20)/toRem(32) "Roboto";
      }
    }
  }

  &__phone {
    &_presentation {
      display: block;
      margin-bottom: toRemMob(9);
      color: $color-black;
      font: 300 toRemMob(20)/toRemMob(20) "Roboto";

      @include _desktop {
        margin-bottom: toRem(21);
        font: 300 toRem(46)/toRem(54) "Roboto";
      }
    }
  }

}

.item {
  &_presentation-bottom {
    display: flex;
    align-items: center;
    font: 300 toRemMob(16)/toRemMob(19) "Roboto";
    margin-bottom: toRemMob(16);
    @include _desktop {
      font: 300 toRem(16)/toRem(19) "Roboto";
      margin-bottom: toRem(22);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    &_presentation-bottom {
      font: 300 toRemMob(16)/toRemMob(19) "Roboto";
      @include _desktop {
        font: 300 toRem(16)/toRem(19) "Roboto";
      }
    }
  }

  &__list {
    &_social {
      display: flex;
      align-items: center;
      margin-left: toRemMob(12);
      @include _desktop {
        margin-left: toRem(12);
      }
    }
  }

  &__email {
    margin-left: toRemMob(7);
    color: $color-black;
    transition: all .2s ease;
    @include _desktop {
      margin-left: toRem(7);
    }

    &:hover {
      transition: all .2s ease;
      color: $color-green;
    }
  }
}

.element {
  &_social {
    width: toRemMob(26);
    height: toRemMob(26);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: toRemMob(13);
    @include _desktop {
      width: toRem(26);
      height: toRem(26);
      margin-left: toRem(13);
    }

    &:first-child {
      margin-left: 0;
    }

    &-instagram {
      background-image: url("../assets/images/icons/instagram.svg");
    }

    &-facebook {
      background-image: url("../assets/images/icons/facebook.svg");
    }
  }
}

</style>
