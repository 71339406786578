<template>
  <header class="header"
          :class="{'header-move': scrollShow}">
    <div class="container">
      <div class="header__wrapper" :class="{'header__wrapper-move': scrollShow}">

        <router-link
          v-if="!isNotFound"
          :to="{name: 'HomePage', params: {geoLocation: $route.params.geoLocation || 'msk'}}"
          @click.prevent="$emit('to-home-page')"
          class="header__logo"
          :class="{'header__logo-move': scrollShow}"
        />
        <a v-else
           href="/"
           class="header__logo"
           :class="{'header__logo-move': scrollShow}"
        >
        </a>
        <div class="header__menu" :class="{'header__menu-move': scrollShow}">
          <div
            v-if="!isNotFound"
            @click.prevent="changedCurrentCity({toHome: false, additionalParams: null})" class="header__country"
            :class="{'header__country-move': scrollShow}">
            <template v-if="currentCity">
              <div class="header__name-country header__name-icon" :class="{'header__name-country-move': scrollShow}">
                {{ currentCity.title }}
              </div>
            </template>
            <template v-else>
              <div class="header__name-country header__name-icon" :class="{'header__name-country-move': scrollShow}">
                {{ getTranslateJsonField("title", $store.getters.currentCountry) }}
              </div>
            </template>
          </div>
          <a
            v-if="anotherCountries.includes(currentCountry?.abbreviation)"
            class="header__phone email"
            href="mailto:mng@greendi.com"
            :class="{'header__phone-move': scrollShow}"
          >
            mng@greendi.com
          </a>
          <a
            v-else
            class="header__phone"
            href="tel:88002346818"
            :class="{'header__phone-move': scrollShow}"
          >
            8-800-234-68-18
          </a>
        </div>
        <div @click.stop="openCartModal" class="header__cart" :class="{'header__cart-move': scrollShow}">
          <div class="header__badge header__badge_cart" :class="{'header__badge_cart-move': scrollShow}">{{ cartCount }}
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "v-header",
  emits: {
    "to-home-page": null
  },
  inject: ["deviceWidth"],
  props: {
    openCartModal: Function,
    changedCurrentCity: Function,
    getTranslateJsonField: Function,
    wordTranslation: Function,
    scrollShow: Boolean,
    anotherCountries: Array,
  },
  computed: {
    ...mapGetters({
      currentCity: "currentCity",
      allCities: "cities",
      isNotFound: "isNotFound",
      currentCountry: "currentCountry"
    }),
    cartCount() {
      const configurationsCount = (this.$store.getters.cart.greenhouses || []).map(greenhouse => {
        return (greenhouse.variants_execution || [])
          .map(variant => variant.configurations.length)
          .concat((greenhouse.additional_attributes || []).length);
      })
        .flat()
        .reduce((a, b) => a + b, 0);

      return configurationsCount + (this.$store.getters.cart.additional_attributes?.length || 0);
    }
  },
};
</script>

<style lang="scss">

.header {
  position: static;
  width: 100%;
  top: toRemMob(-97);
  left: 0;
  z-index: 40;
  background-color: $color-white;
  padding: toRemMob(34) 0 toRemMob(23);
  box-shadow: 0 toRemMob(2) toRemMob(5) rgba(0, 0, 0, 0.07);
  @include _desktop {
    //top: 0;
    padding: toRemMob(32) 0 toRemMob(32);
    background-color: $color-grey;
    box-shadow: none;
    //position: fixed;
  }

  &-move {
    padding: toRemMob(23) 0 toRemMob(20);
    transition: top 0.5s;
    position: fixed;
    top: 0;
    @include _desktop {
      padding: toRem(19) 0 toRem(19);
      //transition: unset;
      top: 0;
      background-color: $color-grey;
      box-shadow: none;
      position: fixed;

      .header {
        &__logo {
          min-width: toRem(179);
          width: toRem(179);
          height: toRem(33);
          margin-left: 0;

        }

        &__description-country {
          font-size: toRem(15);
          line-height: toRem(19);
        }

        &__country {
          font-size: toRem(15);
          line-height: toRem(19);
          padding-right: toRem(20);

          &::after {
            width: toRem(10);
            height: toRem(13);
          }
        }

        &__image-country {
          margin-left: toRem(21);
          width: toRem(17);
          height: toRem(15);
        }

        &__phone {
          margin-left: toRem(36);
          font-size: toRem(15);
          line-height: toRem(19);
        }

        &__cart {
          width: toRem(18);
          height: toRem(19);
          margin-left: toRem(-6);
          margin-right: 0;
        }

        &__badge_cart {
          min-width: toRem(17);
          width: toRem(17);
          height: toRem(17);
          font-size: toRem(11);
          line-height: toRem(14);
        }

        &__menu {
          padding-right: toRem(40);
        }
      }

    }
  }

  &__wrapper {
    display: grid;
    padding: 0 toRemMob(12);

    &-move {
      padding: 0 toRemMob(5);
      display: flex;
      align-items: center;
      @include _desktop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-template-rows:  unset;
        padding: 0;
      }
    }

    @include _desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-template-rows:  unset;
      padding: 0;
    }
  }

  &__logo {
    width: toRemMob(190);
    min-width: toRemMob(190);
    max-width: toRemMob(190);
    height: toRemMob(34);
    background-image: url("../assets/images/icons/logo-mob.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &-move {
      display: none;
      @include _desktop {
        display: inherit;
        background-image: url("../assets/images/icons/logo.svg");
        width: toRem(270);
        height: toRem(51);
        min-width: toRemMob(270);
        max-width: toRemMob(270);
      }
    }

    @include _desktop {
      margin: 0;
      background-image: url("../assets/images/icons/logo.svg");
      width: toRem(270);
      height: toRem(51);
      min-width: toRem(270);
      max-width: toRem(270);
    }
  }

  &__menu {
    display: flex;
    align-items: flex-end;
    margin-top: toRemMob(11);
    order: 2;

    &-move {
      margin-top: 0;
      justify-content: space-between;
      order: 0;
      width: 100%;
      margin-right: toRemMob(15);
      @include _desktop {
        order: unset;
        justify-content: space-between;
        align-items: center;
        margin-top: unset;
        margin-left: auto;
        margin-right: unset;
        padding-right: toRem(10);
        width: unset;
      }
    }

    @include _desktop {
      order: unset;
      justify-content: space-between;
      align-items: center;
      margin-top: unset;
      margin-left: auto;
      margin-right: toRem(10);
    }
  }

  &__country {
    position: relative;
    display: flex;
    padding-right: toRemMob(25);
    margin-left: toRemMob(4);
    font-weight: 300;
    font-size: toRemMob(16);
    line-height: toRemMob(16);
    cursor: pointer;

    &-move {
      margin-left: toRemMob(10);
      margin-right: auto;
      font-size: toRemMob(14);
      @include _desktop {
        margin-left: unset;
        padding-right: toRem(21);
        font-size: toRem(19);
        line-height: toRem(22);
      }
    }

    @include _desktop {
      padding-right: toRem(24);
      font-size: toRem(19);
      line-height: toRem(22);
      margin-left: unset;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: toRemMob(2);
      bottom: 0;
      width: toRemMob(12);
      height: toRemMob(12);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("../assets/images/icons/header-arrow.svg");
      @include _desktop {
        width: toRem(13);
        height: toRem(13);
      }
    }
  }

  &__image-country {
    display: none;
    @include _desktop {
      display: block;
      width: toRem(21);
      height: toRem(21);
      margin-left: toRem(14);
    }

  }

  &__name-country {
    white-space: nowrap;
    margin-left: toRemMob(8);
    @include _desktop {
      margin-left: toRem(4);
    }

    &-move {
      margin-left: toRem(5);
      @include _desktop {
        margin-left: toRem(4);
      }
    }
  }

  &__name-icon {
    position: relative;
    margin: 0;
    padding-left: toRemMob(24);
    @include _desktop {
      padding-left: toRem(24);
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: toRemMob(1);
      bottom: 0;
      margin: auto;
      width: toRemMob(12);
      height: toRemMob(18);
      background-image: url("../assets/images/icons/location.svg");
      background-size: contain;
      background-repeat: no-repeat;
      opacity: .6;
      @include _desktop {
        top: 0;
        opacity: 1;
        width: toRem(16);
        height: toRem(24);
      }
    }
  }

  &__phone {
    position: relative;
    display: block;
    margin-left: auto;
    margin-top: toRemMob(15);
    padding-left: toRemMob(15);
    color: $color-black;
    font-weight: 300;
    font-size: toRemMob(16);
    line-height: toRemMob(16);
    white-space: nowrap;

    &.email {
      @include _desktop {
        font-weight: 300;
        font-size: toRem(16.6);
      }
    }

    &-move {
      font-size: toRemMob(14);
      margin-left: auto;
      margin-top: 0;
      @include _desktop {
        margin-left: toRem(25);
        margin-top: unset;
        padding-left: unset;
        font-weight: 500;
        font-size: toRem(19);
        line-height: toRem(22);
        letter-spacing: toRem(0.4);
      }
    }

    @include _desktop {
      margin: 0 0 0 toRem(25);
      margin-top: unset;
      padding-left: unset;
      font-weight: 500;
      font-size: toRem(19);
      line-height: toRem(22);
      letter-spacing: toRem(0.4);
    }

    &:not(.email) {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: toRemMob(0);
        bottom: 0;
        margin: auto;
        width: toRemMob(12);
        height: toRemMob(12);
        background-size: contain;
        background-image: url("../assets/images/icons/phone-mobile.svg");
        background-repeat: no-repeat;
        @include _desktop {
          display: none;
        }
      }
    }

  }

  &__cart {
    margin-top: toRemMob(-30);
    margin-right: toRemMob(7);
    position: relative;
    width: toRemMob(27);
    max-width: toRemMob(27);
    min-width: toRemMob(27);
    height: toRemMob(24);
    margin-left: auto;
    background-size: contain;
    background-image: url("../assets/images/icons/cart_mobile.svg");
    background-repeat: no-repeat;
    cursor: pointer;

    &-move {
      margin-top: 0;
      width: toRemMob(19);
      height: toRemMob(18);
      margin-right: toRemMob(7);
      @include _desktop {
        margin-right: 0;
        width: toRem(25);
        height: toRem(24);
        margin-left: toRem(22);
        background-image: url("../assets/images/icons/cart.svg");
      }
    }

    @include _desktop {
      margin-right: 0;
      margin-top: 0;
      width: toRem(25);
      height: toRem(24);
      margin-left: toRem(22);
      background-image: url("../assets/images/icons/cart.svg");
    }
  }

  &__badge {
    &_cart {
      position: absolute;
      display: flex;
      right: toRemMob(-9);
      top: toRemMob(-7);
      padding: 0 toRemMob(3);
      align-items: center;
      justify-content: center;
      min-width: toRemMob(22);
      height: toRemMob(22);
      border-radius: 50%;
      background-color: $color-red-2;
      font-weight: 700;
      font-size: toRemMob(12);
      line-height: toRemMob(12);
      transition: all .2s ease;
      color: $color-white;


      @include _desktop {
        right: toRem(0);
        top: toRem(-7);
        min-width: toRem(21);
        height: toRem(21);
        font-size: toRem(14);
        line-height: toRem(14);
      }

      &-move {
        min-width: toRemMob(19);
        height: toRemMob(19);
        right: toRemMob(-4);
        @include _desktop {
          right: toRem(5);
        }
      }

    }
  }
}

.image {
  &-country {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

</style>
