<template>
  <greenhouse-preloader
    v-show="isShowPagePreloader"
  />
  <modal-country
    :get-translate-json-field="getTranslateJsonField"
    :word-translation="wordTranslation"
    ref="modalCountry"
  >
  </modal-country>
  <modal-cart
    :get-translate-json-field="getTranslateJsonField"
    :word-translation="wordTranslation"
    ref="modalCart"
  />
  <modal-create-comment
    :get-translate-json-field="getTranslateJsonField"
    :word-translation="wordTranslation"
    ref="modalCreateComment"
  />
  <modal-presentation ref="modalPresentation" :word-translation="wordTranslation"></modal-presentation>
  <modal-callback ref="modalCallback" :word-translation="wordTranslation"></modal-callback>
  <modal-download-presentation ref="modalDownloadPresentation"
                               :word-translation="wordTranslation"></modal-download-presentation>
  <v-header
    @to-home-page="toHomePage"
    :changed-current-city="changedCurrentCity"
    :get-translate-json-field="getTranslateJsonField"
    :word-translation="wordTranslation"
    :open-cart-modal="openCartModal"
    :another-countries="anotherCountries"
    :scroll-show="this.scrollShow"
  />
  <div class="content" :class="{'content__padding': scrollShow === true}">
    <local-pages
      @init-change-seo="initChangeSeo"
      :get-translate-field="getTranslateField"
      :word-translation="wordTranslation"
      :to-home-page="toHomePage"
      :get-translate-json-field="getTranslateJsonField"
      :gallery-init="galleryInit"
      :get-min-variant-execution-price="getMinVariantExecutionPrice"
      :open-sign-up-on-presentation="openSignUpOnPresentation"
      :open-download-presentation="openDownloadPresentation"
      :open-cart-modal="openCartModal"
      :sample-sales="sampleSales"
      :another-countries="anotherCountries"
      :open-modal-create-comment="openModalCreateComment"
      :get-comment-title="getCommentTitle"
      ref="localePage"
    />
    <contacts-block
      v-if="currentCity?.address && !$store.getters.isNotFound && $route.name !== 'CompletedWorks'"
      :word-translation="wordTranslation"
      :open-callback-modal="openCallbackModal"
      :open-sign-up-on-presentation="openSignUpOnPresentation"
    />
    <v-presentation
      v-if="!$store.getters.isNotFound && !anotherCountries.includes(currentCountry?.abbreviation)"
      :word-translation="wordTranslation"
      :open-download-presentation="openDownloadPresentation"
      :open-sign-up-on-presentation="openSignUpOnPresentation"
    />

  </div>
  <v-footer
    :word-translation="wordTranslation"
    :gallery-init="galleryInit"
  />
  <pswp
    ref="pswp"
    :index="pswpIndex"
    :images="pswpImages"
    :width="pswpWidth"
    :height="pswpHeight"
    :swiper="pswpCurrentSwiper"
    :animation="pswpAnimation"
    :is-youtube="pswpIsYoutube"
  />
  <admin-button/>
</template>

<script>
import LocalPages from "@/components/LocalPages";
import VHeader from "./components/v-header";
import VFooter from "./components/v-footer";
import pswp from "@/components/pswp";
import ModalCountry from "@/components/modal/ModalCountry";
import ModalCallback from "@/components/modal/ModalCallback";
import GreenhousePreloader from "@/components/GreenhousePreloader";
import ModalPresentation from "@/components/modal/ModalPresentation";
import ModalDownloadPresentation from "@/components/modal/ModalDownloadPresentation";
import ModalCreateComment from "@/components/modal/ModalCreateComment";
import AdminButton from "@/components/AdminButton";
import ContactsBlock from "@/components/ContactsBlock";
import VPresentation from "@/components/v-presentation";

import {nextTick} from "vue";
import {Axios} from "@/assets/core/http-common";
import ModalCart from "@/components/modal/ModalCart";
import {mapGetters} from "vuex";

const objectPath = require("object-path");
import {computed} from "vue";
import {store} from "@/main";

const {defaultLanguage} = require("./cfg");

export default {
  presentationUrl: "/presentations/",
  downloadPresentationUrl: "/download-presentations/",
  callbackUrl: "/callback/",

  name: "App",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ContactsBlock,
    ModalCart,
    LocalPages,
    VHeader,
    VFooter,
    pswp,
    ModalCountry,
    GreenhousePreloader,
    ModalPresentation,
    AdminButton,
    VPresentation,
    ModalCallback,
    ModalDownloadPresentation,
    ModalCreateComment,
  },
  data() {
    return {
      pswpAnimation: true,
      scrollShow: false,
      pswpImages: [],
      pswpIndex: 0,
      pswpWidth: 600,
      pswpHeight: 400,
      pswpCurrentSwiper: null,
      pswpIsYoutube: false,
      isShowPagePreloader: true,
      deviceWidth: window.innerWidth,
      isVerticalVideo: false,
      sampleSales: null,
      anotherCountries: ["us", "ca"],
      changeSeo: () => {
      }
    };
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      hidePagePreloader: this.hidePagePreloader,
      showPagePreloader: this.showPagePreloader,
      nicePrice: this.nicePrice,
      getCompressImage: this.getCompressImage,
      getFakeOldPrice: this.getFakeOldPrice,
      deviceWidth: computed(() => this.deviceWidth),
    };
  },
  created() {
    if (localStorage.getItem("isClearCartV1")) {
      this.$store.dispatch("getCart");
    } else {
      localStorage.setItem("cart", JSON.stringify({}));
      localStorage.setItem("isClearCartV1", JSON.stringify(true));
      this.$store.dispatch("getCart");
    }
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUpdate() {
    // don't use in mounted. Because of that mounted call before end preloader and end backend requests
    const urlParams = new URLSearchParams(document.location.search);
    if (urlParams.get("modal") === "presentation") {
      this.openSignUpOnPresentation();
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...mapGetters([
      "currentCity",
      "currentCountry",
      "countries",
      "locales"
    ]),
  },
  methods: {
    getTranslateField(fieldName, object) {
      try {
        const language = this.currentCountry?.abbreviation || defaultLanguage;
        const jsonField = JSON.parse(object[fieldName] || "{}");
        return jsonField[language] || "";
      } catch (error) {
        return this.getTranslateJsonField(fieldName, object);
      }
    },
    onScroll() {
      let header = document.querySelector("header");
      if (this.deviceWidth > 640) {
        this.scrollShow = window.scrollY > header.offsetHeight;
      } else {
        this.scrollShow = window.scrollY > header.offsetHeight + 50;
      }
    },
    getTranslateJsonField(fieldName, object) {
      try {
        const language = this.currentCountry?.abbreviation || defaultLanguage;
        if (Object.entries(object || "")?.length) {
          return object[fieldName][language];
        }
        return "";
      } catch (error) {
        console.error(error);
        return "";
      }
    },
    wordTranslation(pathToWord) {
      if (this.$store.getters.locales && Object.entries(this.$store.getters.locales).length) {
        return objectPath.get(this.$store.getters.locales, [(this.currentCountry?.abbreviation || defaultLanguage), ...pathToWord.split(".")], "");
      } else {
        return "";
      }
    },
    async changedCurrentCity() {
      const modalResult = await this.$refs.modalCountry.open();
      const {geoLocation} = modalResult;
      if (!geoLocation) {
        this.$refs.modalCountry.close();
        return;
      }
      // If we in 404 page => change route name and updateIsNotFound on false
      if (this.$store.getters.isNotFound) {
        this.$store.commit("updateIsNotFound", false);
      }

      const currentCity = this.currentCountry?.cities.find(cityItem => cityItem.abbreviation === geoLocation);
      if (geoLocation.length === 2) {
        const currentCountry = this.countries.find(country => country.abbreviation === geoLocation);
        const language = currentCountry?.abbreviation;
        if (!this.locales[language]) {
          try {
            const url = `translates/${language}/`;
            // await request and get data from him
            const newTranslate = await Axios.get("https://teplichki.com/api/v1/" + url).then(({data}) => data);
            const newLocales = Object.assign({[language]: newTranslate[language]}, this.locales);
            store.commit("updateLocales", newLocales);
          } catch (error) {
            console.log(error);
          }
        }
        store.commit("changeCurrentCountry", currentCountry);
      }
      this.$store.commit("changeCurrentCity", currentCity);
      this.$refs.modalCountry.close();
      this.changeSeo();
    },
    galleryInit(images, index = 0, swiper = null, width = 600, height = 400, animation = true, additionalOption = {}) {
      // event.target.closest('img').currentSrc
      this.pswpImages = images;
      this.pswpIndex = index;
      this.pswpCurrentSwiper = swiper;
      this.pswpWidth = width;
      this.pswpHeight = height;
      this.pswpAnimation = animation;
      const {youtube = false} = additionalOption;

      this.pswpIsYoutube = youtube;
      nextTick(() => {
        this.$refs.pswp.init(swiper);
      });
    },
    getMinVariantExecutionPrice(variantExecution) {
      const priceArray = (variantExecution?.configurations || []).map(configuration => {
        return this.getTranslateJsonField("price", configuration);
      }).flat();
      return this.nicePrice(Math.min(...priceArray));
    },
    toHomePage() {
      this.$router.push({name: "HomePage", params: {...this.$route.params}}).then(() => {
        this.changeSeo();
        this.$store.commit("updateIsNotFound", false);
      });
    },
    showPagePreloader(time) {
      if (time) {
        setTimeout(() => {
          this.toggleBlockScroll(true);
          this.isShowPagePreloader = true;
        }, time);
        return true;
      }
      this.toggleBlockScroll(true);
      this.isShowPagePreloader = true;
      return true;
    },
    hidePagePreloader(time = 0) {
      if (time) {
        setTimeout(() => {
          this.toggleBlockScroll(false);
          this.isShowPagePreloader = false;
        }, time);
        return true;
      }
      this.toggleBlockScroll(false);
      this.isShowPagePreloader = false;
      return true;
    },
    async sendModalPresentation(modalData) {
      try {
        const response = await Axios.post(this.$options.presentationUrl, modalData);
        return response.status >= 200 && response.status < 300;

      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async sendModalDownLoadPresentation(modalData) {
      try {
        const response = await Axios.post(this.$options.downloadPresentationUrl, modalData);
        return response.status >= 200 && response.status < 300;

      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async sendModalCallback(modalData) {
      try {
        const response = await Axios.post(this.$options.callbackUrl, modalData);
        return response.status >= 200 && response.status < 300;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async sendModalCart(modalData) {

      try {
        await Axios.post("orders/", modalData);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async openSignUpOnPresentation(pagePosition = "") {
      // Await resolve from modal (confirm, close ...)
      const modalResult = await this.$refs.modalPresentation.open();
      // check on close or confirm event
      if (modalResult && Object.entries(modalResult).length) {
        this.$refs.modalPresentation.showPreloader();
        // Fake send form to backend
        const data = {
          ...modalResult,
          page_position: pagePosition,
          page_url: window.location.href,
          country_id: this.$store.getters.currentCountry.id
        };
        const result = await this.sendModalPresentation(data);
        this.$refs.modalPresentation.hidePreloader();
        // if success back response show success and close modal, else show error
        if (result) {
          await this.$refs.modalPresentation.showSuccess();
        } else {
          await this.$refs.modalPresentation.showError();
          // Тут костыль небольшой. Что бы повторить все эти действия, мы вызываем сам себя :)
          this.openSignUpOnPresentation(pagePosition);
        }
      }
    },
    async openDownloadPresentation() {
      // Await resolve from modal (confirm, close ...)
      const modalResult = await this.$refs.modalDownloadPresentation.open();
      // check on close or confirm event
      if (modalResult && Object.entries(modalResult).length) {
        this.$refs.modalDownloadPresentation.showPreloader();
        // Fake send form to backend
        const data = {
          ...modalResult,
          page_url: window.location.href,
          country_id: this.$store.getters.currentCountry.id
        };
        const result = await this.sendModalDownLoadPresentation(data);
        this.$refs.modalDownloadPresentation.hidePreloader();
        // if success back response show success and close modal, else show error
        if (result) {
          this.downLoadPresentation(modalResult.file);
          await this.$refs.modalDownloadPresentation.showSuccess();
        } else {
          await this.$refs.modalDownloadPresentation.showError();
          // Тут костыль небольшой. Что бы повторить все эти действия, мы вызываем сам себя :)
          this.openDownloadPresentation();
        }
      }
    },
    downLoadPresentation(file) {
      const link = document.createElement("a");
      const fileExpansion = file.url.split(".")[file.url.split.length - 1];
      const fileName = `${file.label}.${fileExpansion}`;
      link.setAttribute("href", this.$host + file.url);
      link.setAttribute("download", fileName);
      link.click();
    },
    async openCallbackModal() {
      // Await resolve from modal (confirm, close ...)
      const modalResult = await this.$refs.modalCallback.open();
      // check on close or confirm event
      if (modalResult && Object.entries(modalResult).length) {
        this.$refs.modalCallback.showPreloader();
        // Fake send form to backend
        const data = {
          ...modalResult,
          page_url: window.location.href,
          country_id: this.$store.getters.currentCountry.id
        };
        const result = await this.sendModalCallback(data);
        this.$refs.modalCallback.hidePreloader();
        // if success back response show success and close modal, else show error
        if (result) {
          await this.$refs.modalCallback.showSuccess();
        } else {
          await this.$refs.modalCallback.showError();
          // Тут костыль небольшой. Что бы повторить все эти действия, мы вызываем сам себя :)
          this.openCallbackModal();
        }
      }
    },
    async sendModalCreateComment(modalData) {
      try {
        const response = await Axios.post('/completed-works/', modalData);
        return response.status >= 200 && response.status < 300;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async openModalCreateComment() {
      // Await resolve from modal (confirm, close ...)
      const modalResult = await this.$refs.modalCreateComment.open();
      // check on close or confirm event
      if (modalResult && Object.entries(modalResult).length) {
        this.$refs.modalCreateComment.showPreloader();
        // Fake send form to backend
        const data = {
          ...modalResult,
          country_id: this.$store.getters.currentCountry.id
        };
        const result = await this.sendModalCreateComment(data);
        this.$refs.modalCreateComment.hidePreloader();
        // if success back response show success and close modal, else show error
        if (result) {
          await this.$refs.modalCreateComment.showSuccess();
        } else {
          await this.$refs.modalCreateComment.showError();
          // Тут костыль небольшой. Что бы повторить все эти действия, мы вызываем сам себя :)
          this.openModalCreateComment();
        }
      }
    },

    async openCartModal(data) {
      // Await resolve from modal (confirm, close ...)
      const modalResult = await this.$refs.modalCart.open();
      const pageUrl = data.pageUrl || document.location.href;
      // check on close or confirm event
      if (modalResult && Object.entries(modalResult).length) {
        this.$refs.modalCart.showPreloader();
        // Fake send form to backend
        const data = {
          ...modalResult,
          page_url: pageUrl,
          country_id: this.$store.getters.currentCountry.id
        };
        const result = await this.sendModalCart(data);
        this.$refs.modalCart.hidePreloader();
        // if success back response show success and close modal, else show error
        if (result) {
          this.$metrika.reachGoal("orderCreate");
          await this.$refs.modalCart.showSuccess();
          this.$store.commit("updateCart", {});
          localStorage.setItem("cart", JSON.stringify({}));
        } else {
          await this.$refs.modalCart.showError();
          // Тут костыль небольшой. Что бы повторить все эти действия, мы вызываем сам себя :)
          this.openCartModal(data);
        }
      }
    },
    initChangeSeo(changeSeo) {
      this.changeSeo = changeSeo;
    },
    nicePrice(price) {
      return new Intl.NumberFormat(this.$route.params.locale || "ru").format(price);
    },
    getCompressImage(rawUrl, compressNumber = 1) {
      const [url, extension] = rawUrl.split(".");
      return `${url}@${compressNumber || 1}x.${extension}`;
    },
    onResize() {
      this.deviceWidth = window.innerWidth;
    },
    ceilPrice(price, ceilNumber = 100) {
      return Math.ceil(price / ceilNumber) * ceilNumber;
    },
    getFakeOldPrice(originalPrice, discount) {
      // ceil orig price
      const ceilOriginalPrice = this.ceilPrice(originalPrice);
      // calculating FAKE old price
      const fakeOldPrice = (ceilOriginalPrice / (100 - discount)) * 100;
      // ceil price to hundred and subtraction 1 for nice price
      return this.ceilPrice((fakeOldPrice));
    },
    toggleBlockScroll(isBlock) {
      const htmlElement = document.documentElement;
      // Padding need if header have style position: fixed.
      const header = document.querySelector("header.header-move");
      const section = document.querySelectorAll(".content .section");
      const lockPaddingValue = window.innerWidth - document.body.offsetWidth + "px";

      if (isBlock) {
        htmlElement.style.overflow = "hidden";
        if (this.deviceWidth < 640) {
          section.forEach(item => {
            item.style.visibility = "hidden";
            item.style.overflow = "hidden";
          });
        }
        htmlElement.style.paddingRight = lockPaddingValue;
        if (header) {
          header.style.paddingRight = lockPaddingValue;
        }
      } else {
        htmlElement.style.overflow = "";
        if (this.deviceWidth < 640) {
          section.forEach(item => {
            item.style.visibility = "visible";
            item.style.overflow = "";
          });
        }
        htmlElement.style.paddingRight = "0px";
        if (header) {
          header.style.paddingRight = "0px";
        }
      }
    },
    async getSampleSales() {
      try {
        const params = {
          "city-id": this.currentCity?.id, "country-id": this.currentCountry?.id
        };
        this.sampleSales = await Axios.get(`sample-sales/`, {params: params}).then(({data}) => data?.sample_sales);
      } catch (error) {
        this.sampleSales = null;
        console.error(error);
      }
    },
    getCommentTitle(comment) {
      if (!comment.is_completed_work) {
        return `${comment.fio} ${this.getTranslateJsonField("location", comment)}`;
      }
      return `
         ${this.getTranslateJsonField("location", comment) ? this.getTranslateJsonField("location", comment) + " |" : ""}
         ${this.getTranslateJsonField("greenhouse_title", comment).replace("«Green Diamond» ", "")} -
         ${this.getTranslateJsonField("variant_execution_title", comment)},
         ${this.getTranslateJsonField("configuration_title_full", comment)}
     `;
    },
  },
  watch: {
    currentCity() {
      this.getSampleSales();
    }
  }
};
</script>

<style lang="scss">
@import url(~normalize.css);
@import url(assets/fonts/font.css);
@import url(assets/styles/tippy.scss);

html {
  font-size: $font-size;
  height: 100%;
  @include _desktop {
    font-size: 16px;
    font-size: $font-size-desktop;
  }
  @include _max {
    font-size: 16px;
  }
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 16px;
  background-color: $color-white;

  &.active {
    @include _desktop {
      overflow: hidden;
    }
  }
}

#app {
  margin: 0 auto;
  min-height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 1 auto;
  //@include _desktop {
  //  padding-top: toRem(110);
  //}

  &__padding {
    padding-top: toRem(165);
    @include _desktop {
      padding-top: toRem(110);
    }
  }

  /*padding*/
}

.container {
  max-width: $breakpoint-maximum;
  margin-left: auto;
  margin-right: auto;
  padding-left: toRemMob(15);
  padding-right: toRemMob(15);
  @include _desktop {
    padding-left: toRem(175);
    padding-right: toRem(175);
  }
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

p {
  margin: 0;
}

a {
  border: 0;
  text-decoration: none;
}

p {
  a {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

input {
  border: 0;
  border-radius: 0;
  outline: 0;
  transition: all .2s ease;
}

.image {
  &_contain {
    background-size: contain;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.button {
  width: max-content;
  cursor: pointer;
  white-space: nowrap;
  transition: all .2s ease;
  border-radius: 0.3125rem;
  border: 0;
  font-weight: 400;
  background-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &_green {
    background-color: $color-green;
  }

  &:active {
    background: rgba($color-green, 0.1);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}


.link {
  &_blue {
    color: $color-blue-2;
  }
}


/*------- Стандартные классы -------*/

.disable {
  user-select: none !important;
  opacity: 0.5 !important;
  background-color: unset !important;
  border-color: #CFCFCF !important;
  color: #CFCFCF !important;
  cursor: default !important;
  box-shadow: none !important;

  > * {
    cursor: default !important;

    > * {
      cursor: default !important;
    }
  }
}


.preloader {
  @-webkit-keyframes skeleton {
    0% {
      opacity: 0.2;
    }
    85%, 100% {
      opacity: 1;
    }
  }
  @keyframes skeleton {
    0% {
      opacity: 0.2;
    }
    85%, 100% {
      opacity: 1;
    }
  }

  &_start {
    background-color: $color-grey;
    animation-name: skeletonAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation: skeleton 1s ease-in-out forwards infinite;
    animation: skeleton 1s ease-in-out forwards infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }

  &_stop {
    background-color: unset;
    animation-name: unset;
    animation-duration: unset;
    animation-iteration-count: unset;
    animation-timing-function: unset;
    -webkit-animation: unset;
    animation: unset;
    -webkit-animation-direction: unset;
    animation-direction: unset;
  }
}

.preloader-mini-block {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 40%;
}

.preloader-mini {
  display: inline-block;

  &_start {
    content: " ";
    display: block;

    width: toRem(80);
    height: toRem(80);
    border-radius: 50%;
    border: toRem(6) solid $color-grey-3;
    border-color: $color-grey-3 $color-grey-3 $color-grey-3 transparent;
    animation: preloader-mini 1.2s linear infinite;
  }

  &_stop {
    content: unset;
    display: unset;
    width: unset;
    height: unset;
    margin: unset;
    border-radius: unset;
    border: unset;
    border-color: unset;
    animation: unset;
    -webkit-animation-direction: unset;
    animation-direction: unset;
  }

  @keyframes preloader-mini {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.display-none {
  display: none;
}

.unvisible {
  transition: opacity 1s ease;
  opacity: 0;
}

.visible {
  transition: opacity 1s ease;
  opacity: 1;
}

.visible-more {
  transition: opacity 900ms ease;
  opacity: 1;
}

.visible-less {
  transition: opacity 200ms ease;
  opacity: 1;
}

.swiper-slide {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}

jdiv {
  z-index: 40 !important;
}

</style>
