const changeMeta = (meta) => {
  const { title, description } = meta;

  if (title) {
    document.title = title;
  }
  if (description) {
    const des = document.querySelector("meta[name=\"description\"]");
    if (des) {
      des.content = description;
    }
  }
};


module.exports = { changeMeta }