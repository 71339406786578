<template>
  <div class="pswp" tabIndex="-1" role="dialog" aria-hidden="true" ref="pswpElement">

    <!-- Background of PhotoSwipe.
         It's a separate element as animating opacity is faster than rgba(). -->
    <div class="pswp__bg"></div>

    <!-- Slides wrapper with overflow:hidden. -->
    <div class="pswp__scroll-wrap">

      <!-- Container that holds slides.
          PhotoSwipe keeps only 3 of them in the DOM to save memory.
          Don't modify these 3 pswp__item elements, data is added later on. -->
      <div class="pswp__container" :class="{'vertical': isVerticalVideo}">
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
      </div>


      <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
      <div class="pswp__ui pswp__ui--hidden">

        <div class="pswp__top-bar">

          <!--  Controls are self-explanatory. Order can be changed. -->

          <div class="pswp__counter"></div>

          <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

          <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

          <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

          <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
          <!-- element will get class pswp__preloader--active when preloader is running -->
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div class="pswp__share-tooltip"></div>
        </div>

        <button v-if="isArrowPrev" :class="{'vertical': isVerticalVideo}" ref="arrowPrev"
                class="pswp__button pswp__button--arrow--left" title="Previous">
        </button>

        <button v-if="isArrowNext" :class="{'vertical': isVerticalVideo}" ref="arrowNext"
                class="pswp__button pswp__button--arrow--right" title="Next">
        </button>

        <div class="pswp__caption">
          <div class="pswp__caption__center"></div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>

import PhotoSwipe from "photoswipe/dist/photoswipe.min";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.min";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";


export default {
  components: {},
  name: "pswp",
  props: {
    images: Array,
    index: Number,
    swiper: Object,
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 400
    },
    animation: {
      type: Boolean,
      default: true
    },
    isYoutube: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      gallery: null,
      isArrowNext: true,
      isArrowPrev: false,
      isVerticalVideo: null,
      players: [],
      items: []
    };
  },
  methods: {
    stopAllVideos() {
      this.players.forEach(item => {
        item.destroy();
      });
    },
    init() {
      const items = this.getFiles;
      this.items = items;
      const options = {
        index: this.index,
        history: false,
        focus: true,
        fullscreenEl: true,
        closeOnScroll: false,
        showAnimationDuration: 500,
        showHideOpacity: !this.animation,
        hideAnimationDuration: 500,
        loop: false,
        maxSpreadZoom: 5,
        getDoubleTapZoom: function (isMouseClick, item) {
          if (isMouseClick) {
            return 2;
          } else {
            if (item.w === 414 && item.h === 414) {
              return 3;
            }
            return item.initialZoomLevel < 0.7 ? 2 : 1.33;
          }
        },
        getThumbBoundsFn: (index) => {
          if (this.getCurrentFile().isYoutube) return;
          let thumbnail = this.swiper.el.querySelectorAll(".swiper-slide")[index].querySelector(".item__image");
          if (index > 2 && !this.animation) {
            thumbnail = this.swiper.el.querySelectorAll(".swiper-slide")[2].querySelector(".item__image");
          }
          let pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
          let rect = thumbnail.getBoundingClientRect();
          return {x: rect.left, y: rect.top + pageYScroll, w: rect.width};
        },
      };

      this.gallery = new PhotoSwipe(this.$refs.pswpElement, PhotoSwipeUI_Default, items, options);

      let self = this;

      items[self.index].w = this.width;
      items[self.index].h = this.height;
      items[self.index].msrc = this.src;

      self.gallery.listen("gettingData", (index, item) => {
        if (this.getCurrentFile().isYoutube) {
          return;
        }
        if ((item.w < 1 || item.h < 1)) {
          let img = new Image();
          img.onload = function () {
            item.w = this.width;
            item.h = this.height;

            self.gallery.updateSize(true);
          };
          img.src = item.src;
        }
      });

      this.gallery.listen("close", () => {
        this.toggleBlockScroll(false);
        this.stopAllVideos();
        setTimeout(() => {
          document.querySelector(".pswp").style.zIndex = 14;
        }, 100);
        setTimeout(() => {
          document.querySelector(".pswp").style.zIndex = 41;
        }, 600);
      });
      this.gallery.listen("afterInit", () => {
        this.toggleBlockScroll(true);
        this.checkArrows();
      });

      const changeSwiperSlide = (data) => {
        // get current pswp slide and call slideTo with current slide index
        if (!this.swiper) return;
        const {currentSlide} = data;
        this.swiper.slideTo(currentSlide);
      };
      this.gallery.listen("afterChange", () => {
        changeSwiperSlide({currentSlide: this.gallery.getCurrentIndex()});
        this.checkArrows();
        this.isVerticalVideo = !!this.gallery.currItem.isVertical;
        this.stopAllVideos();
        if (this.getCurrentFile().isYoutube) {
          this.initYoutubeVideo();
        }
      });
      // Init gallery
      this.gallery.init();

      this.checkArrows();
      this.isVerticalVideo = !!this.gallery.currItem.isVertical;
    },
    initYoutubeVideo() {
      this.setupVideo();
    },
    setupVideo() {
      const videoId = this.images[this.gallery.getCurrentIndex()].src;
      const playerId = `video-player-${this.gallery.getCurrentIndex()}`;
      this.loadVideo(playerId, videoId);
    },
    loadVideo(elementId, videoId) {
      window.YT.ready(() => {
        const player = new window.YT.Player(elementId, {
          videoId: videoId,
          playerVars: {
            "autoplay": 1,
            "iv_load_policy": 3,
          },
          events: {
            "onReady": this.onPlayerReady,
          }
        });
        this.players.push(player);
      });
    },
    onPlayerReady(event) {
      setTimeout(() => {
        event.target.playVideo();
      }, 50);
    },
    checkArrows() {
      this.isArrowNext = true;
      this.isArrowPrev = true;
      if (this.gallery.getCurrentIndex() >= this.gallery.items.length - 1) {
        this.isArrowNext = false;
      } else if (this.gallery.getCurrentIndex() <= 0) {
        this.isArrowPrev = false;
      }
    },
    toggleBlockScroll(isBlock) {
      const htmlElement = document.documentElement;
      // Padding need if header have style position: fixed.
      const header = document.querySelector("header.header-move");
      const section = document.querySelectorAll(".content .section");
      const lockPaddingValue = window.innerWidth - document.body.offsetWidth + "px";

      if (isBlock) {
        htmlElement.style.overflow = "hidden";
        if (this.deviceWidth < 640) {
          section.forEach(item => {
            item.style.visibility = "hidden";
            item.style.overflow = "hidden";
          });
        }
        htmlElement.style.paddingRight = lockPaddingValue;
        if (header) {
          header.style.paddingRight = lockPaddingValue;
        }
      } else {
        htmlElement.style.overflow = "";
        if (this.deviceWidth < 640) {
          section.forEach(item => {
            item.style.visibility = "visible";
            item.style.overflow = "";
          });
        }
        htmlElement.style.paddingRight = "0px";
        if (header) {
          header.style.paddingRight = "0px";
        }
      }
    },
    getImages(image) {
      return {
        src: (image.image || image.src || image),
        w: this.width,
        h: this.height,
      };
    },
    getVideos(video, index) {
      return {
        isVertical: video.isVertical,
        isYoutube: true,
        w: this.width,
        h: this.height,
        html: `
            <div class="pswp__youtube-wrapper">
              <div class="video-wrapper" >
                <div class="video" id="video-player-${index}"></div>
              </div>
            </div>`
      };
    },
    getCurrentFile() {
      if (this.gallery?.getCurrentIndex()) {
        return this.items[this.gallery.getCurrentIndex()];
      }
      return this.items[this.index];
    }
  },
  mounted() {

  },
  computed: {
    getFiles() {
      return this.images.map((image, index) => {
        return (image.is_video || image.is_youtube) ? this.getVideos(image, index) : this.getImages(image);
      });
    },
  }
};
</script>

<style lang="scss">
.pswp {
  z-index: 41;
}

.pswp__bg {
  z-index: -2;
}

.pswp__container.vertical {
  .video-wrapper {
    height: 100%;
    padding-bottom: unset;

    @include _desktop {
      height: unset;
      padding-bottom: 28.125%;
    }
  }
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;

  @include _desktop {
    height: unset;
    max-width: 1446px;
    padding-bottom: 42.3%;
  }

  .video {
    touch-action: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

}

.pswp__youtube-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
