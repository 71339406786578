<template>
  <div v-if="sampleSales" class="sample-sales__container">
    <div class="sample-sales__title">Распродажа выставочных образцов:</div>
    <ul class="sample-sales__list">
      <li v-for="element in sampleSales"
          :key="element.id"
          class="item sample-sales__items item__price"
      >
        <div class="text">{{ element.title }}&nbsp;
          <tippy v-if="deviceWidth < 640 && (element.hint_text || element.hint_image)" class="tippy__orientation">
            <div class="item__info_specification tippy-description placeholder"></div>
            <template #content>
              <img v-if="element.hint_image" class="tippy-image" :src="$host + element.hint_image" alt="">
              <p class="tippy-description sample-sales-hint" v-html="element.hint_text"></p>
            </template>
          </tippy>
        </div>
        <div class="old-price">{{ nicePrice(getFakeOldPrice(element.new_price, element.discount)) }}&nbsp;</div>
        <div class="new-price">{{ nicePrice(element.new_price) }} ₽&nbsp;</div>
        <div class="badge-discount discount">-{{ element.discount }}%</div>
        <tippy v-if="deviceWidth > 640 && (element.hint_text || element.hint_image)" class="tippy__orientation">
          <div class="item__info_specification tippy-description placeholder"></div>
          <template #content>
            <img v-if="element.hint_image" class="tippy-image" :src="$host + element.hint_image" alt="">
            <p class="tippy-description sample-sales-hint" v-html="element.hint_text"></p>
          </template>
        </tippy>
      </li>

    </ul>
    <div class="sample-sales__text-detail">Подробности уточняйте по телефону.</div>
  </div>
</template>

<script>
import {Tippy} from "vue-tippy";

export default {
  name: "v-sample-sales",
  inject: ["nicePrice", "deviceWidth", "getFakeOldPrice"],
  props: {
    wordTranslation: Function,
    sampleSales: Array
  },
  components: {
    Tippy
  },
  methods: {}
};
</script>

<style scoped lang="scss">
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

.sample-sales__container {
  padding-left: toRem(51);
  padding-right: toRem(41);
  margin-bottom: toRem(32);
  @include _desktop {
    padding: 0;
    margin-bottom: toRem(99);
  }
}

.sample-sales__title {
  font-weight: 400;
  font-size: toRemMob(28);
  line-height: toRemMob(33);
  margin-bottom: toRemMob(30);
  @include _desktop {
    font-weight: 300;
    font-size: toRem(35);
    line-height: toRem(41);
    margin-bottom: toRem(30);
  }
}

.sample-sales__list {
  margin-bottom: toRem(35);
}

.sample-sales__items {
  position: relative;
  font-weight: 300;
  font-size: toRemMob(16);
  line-height: toRem(24);
  margin-left: toRem(15);
  margin-bottom: toRemMob(10);
  @include _desktop {
    display: flex;
    font-weight: 300;
    flex-direction: row;
    font-size: toRem(22);
    line-height: toRem(26);
    padding-left: toRem(25);
    margin-bottom: toRem(25);
    margin-left: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 12.5%;
    left: -5%;
    width: toRem(4);
    height: toRem(4);
    background: black;
    border-radius: 50%;
    @include _desktop {
      width: toRem(5);
      height: toRem(5);
      top: 35%;
      left: 0;
    }
  }

  .text {
    position: relative;
    display: inline-block;
    line-height: toRem(24);
    margin-bottom: toRem(5);

    @include _desktop {
      font-size: toRem(22);
      margin-right: toRem(10);
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: toRem(-4);
        width: toRem(5);
        height: toRem(1);
        background: black;
      }
    }
  }

  .old-price {
    display: inline-block;
    position: relative;
    color: #BBBBBB;

    &::before {
      content: '';
      position: absolute;
      top: 45%;
      left: 0;
      width: 95%;
      height: 1px;
      background: #BBBBBB;
    }
  }

  .new-price {
    display: inline-block;
  }

  .discount {
    display: inline-block;
    @include _desktop {
      display: block;
      position: relative;
      top: toRem(5);
    }
    margin-left: toRem(10);
  }

  .tippy__orientation {
    position: relative;
    top: toRem(7);
    left: toRem(-13);
    @include _desktop {
      top: 0;
      left: 0;
      width: 23px;
    }
  }

  .item__info_specification {
    display: inline-block;
    align-self: center;
    width: toRemMob(20);
    height: toRemMob(20);
    @include _desktop {
      width: toRem(25);
      height: toRem(25);
    }
  }
}

.sample-sales__text-detail {
  font-weight: 300;
  font-size: toRemMob(16);
  line-height: toRem(26);
  @include _desktop {
    font-size: toRem(22);
  }
}


</style>