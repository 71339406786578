<template>
  <section class="section section_page-greenhouse">
    <div class="container">
      <h1 class="section__title section__title_page-greenhouse">
        {{ getTranslateJsonField("title", greenhouse) }} - {{ getTranslateJsonField("title", activeVariant) }}
      </h1>
      <swiper class="section__list section__list_page-greenhouse"
              @active-index-change="changeSlide($event)"
              :css-mode="true"
              :slides-per-view="1"
              :space-between="0"
              navigation
              :breakpoints='{
            "0":{
               pagination:{
               el: `.item__pagination`,
               type: "custom",
               renderCustom: (swiper, current, total) => {
               return `${current} / ${total}`;
                },
            },
            },
            "640": {
               cssMode: false,
                pagination:{
                  clickable: true,
                  el: `.item__pagination`,
                  type: "bullets",
                  renderBullet: (index, className) => {
                    return `<span class="${className}"></span>`;
                  },
                },
/*                    navigation: {
      nextEl: `.swiper-button-next`,
      prevEl: `.swiper-button-prev`,
      },*/
             },
          }'
              @swiper="swiper => greenhouseSwiper = swiper"
      >
        <swiper-slide
          v-for="(item, index) in greenhouse.slider"
          :key="index"
          @click.stop="galleryInit(
            getGalleryImage({slider: greenhouse.slider, event: $event}),
            index,
            greenhouseSwiper,
            this.deviceWidth < 640 ? 828: 2888,
            this.deviceWidth < 640 ? 828: 1928,
          )"
        >
          <div
            class="item__image item__image_page-greenhouse preloader preloader_start"
            :class="{'visible-more': visible.el, 'preloader_stop': preload.img}"
          >
            <picture-element
              @load-image="onLoadPageGreenhouse"
              :image-desktop="$host + item.extra_desktop"
              :image-mobile="$host + item.mobile"
              :default-image="$host + item.extra_desktop"
              :class="{'visible': preload.img}"
              :alt="getTranslateJsonField('title', greenhouse)"
              class="unvisible image image_contain image_main-greenhouse-slider"
            />
          </div>
        </swiper-slide>
        <div class="item__wrapper item__wrapper_control item__wrapper_control-page-greenhouse">
          <div class="item__pagination item__pagination_page-greenhouse"></div>
        </div>
      </swiper>
      <div class="mini-circle-wrapp">
        <div v-if="greenhouse.slider && deviceWidth<640" class="mini-circle-container">
          <div class="mini-circle"
               v-for="index  in greenhouse.slider.length" :key="index"
               :class="{'mini-circle-active': currentDot===index-1}"
               @click="changeActiveDot(index)"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/effect-fade/effect-fade.min.css";

import "swiper/modules/pagination/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, {EffectFade, Pagination} from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, EffectFade]);

import PictureElement from "@/components/PictureElement";


export default {
  props: {
    galleryInit: Function,
    greenhouse: Object,
    getTranslateJsonField: Function,
    activeVariant: Object
  },
  components: {
    Swiper,
    SwiperSlide,
    PictureElement
  },
  inject: ["getCompressImage", "deviceWidth"],
  name: "vPageGreenhouse",
  data() {
    return {
      currentDot: 0,
      greenhouseSwiper: null,
      visible: {
        el: false,
      },
      preload: {
        img: false,
      },
    };
  },
  methods: {
    changeActiveDot(num) {
      this.currentDot = num - 1
      this.greenhouseSwiper.slideToLoop(num - 1)
    },
    changeSlide(event) {
      let slideOdj = {...event.$el['0'].swiper}
      this.currentDot = slideOdj.realIndex
    },
    onLoadPageGreenhouse() {
      this.preload.img = true;
    },
    getGalleryImage(data) {
      const {slider, event} = data;
      const currentSrc = event.target.closest("img")?.currentSrc || "";
      const currentCompress = /@(\d)x.\w+/.exec(currentSrc)[1];

      this.$metrika.reachGoal('openImageOnFullScreen')

      return slider.map(image => {
        if (this.deviceWidth < 640) {
          return this.getCompressImage(this.$host + image.mobile, currentCompress);
        }
        return this.getCompressImage(this.$host + image.extra_desktop, currentCompress);
      });


    }
  }
};
</script>
<style lang="scss">

.swiper-button-next {
  display: none;
  @include _desktop {
    display: block;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    background-image: url("../../assets/images/icons/arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.swiper-button-next::after {
  display: none;
}

.mini-circle {
  background-color: #444444;
  opacity: .3;
  height: toRemMob(7);
  width: toRemMob(7);
  min-width: toRemMob(7);
  max-width: toRemMob(7);
  border-radius: 50%;
  margin-left: toRemMob(7);

  &-active {
    opacity: 1;
  }

  &-container {
    position: absolute;
    margin-left: toRemMob(-7);
    justify-content: center;
    top: 20px;
    display: flex;
    width: 100%;
  }

  &-wrapp {
    position: relative;
  }
}

.swiper-button-prev {
  display: none;
  @include _desktop {
    display: block;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    background-image: url("../../assets/images/icons/arrow.svg");
    transform: scale(-1, 1);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.swiper-button-prev::after {
  display: none;
}

.section {
  &__list {
    &_page-greenhouse {
      position: relative;
      margin-left: toRemMob(-15);
      margin-right: toRemMob(-15);
      @include _desktop {
        padding-bottom: toRem(46);
        margin-left: unset;
        margin-right: unset;
      }

      .swiper-slide {
        height: toRemMob(414);
        width: toRemMob(414);
        @include _desktop {
          height: toRem(964);
          width: toRem(1446);
          overflow: hidden;
          border-radius: toRem(10);
        }
      }
    }
  }
  &__phrase-model {
    position: relative;
    top: toRem(30);
    @include _desktop {
      top: toRem(-30);
    }
    margin-bottom: toRem(30);

    .phrase {
      &__container {
        padding: 0;
        @include _desktop {
          align-items: center;
        }
      }

      &__text {

        line-height: toRem(29);
        @include _desktop {
          line-height: toRem(20);
        }
        font-size: toRem(20);
      }
    }

    .placeholder {
      width: toRem(19);
      height: toRem(19);
      @include _desktop {
        width: toRem(20);
        height: toRem(20);
      }
    }

    .tippy__orientation {
      top: toRem(4);
      left: toRem(-5);
      @include _desktop {
        top: 2px;
      }
    }

  }
}


.item {

  &__wrapper {
    &_control {
      &-page-greenhouse {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 1;
        right: toRemMob(31);
        top: toRemMob(31);

        @include _desktop {
          justify-content: center;
          z-index: unset;
          right: unset;
          top: unset;
          bottom: 0;
          width: 100%;
          margin-left: toRem(-19);
        }
      }
    }
  }

  &__image {
    &_page-greenhouse {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &__pagination {
    &_page-greenhouse {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: toRemMob(23);
      padding-right: toRemMob(5);
      height: toRemMob(23);
      min-width: toRemMob(57);
      border-radius: toRemMob(5);
      font-size: toRemMob(14);
      line-height: toRemMob(15);
      font-weight: 300;
      color: $color-white;
      background: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(2px);

      @include _desktop {
        padding-left: unset;
        padding-right: unset;
        height: unset;
        min-width: unset;
        border-radius: unset;
        font-size: unset;
        line-height: unset;
        font-weight: unset;
        color: unset;
        background: unset;
        backdrop-filter: unset;
      }


      &:before {
        content: "";
        position: absolute;
        width: toRemMob(14);
        height: toRemMob(14);
        left: toRemMob(5);
        top: 0;
        bottom: 0;
        margin: auto;
        background-image: url("../../assets/images/icons/photo.svg");
        background-size: contain;
        background-repeat: no-repeat;
        @include _desktop {
          display: none;
        }
      }
    }
  }
}

.swiper-pagination-bullet {
  background-color: #444444;
  opacity: .3;
  height: toRemMob(7);
  width: toRemMob(7);
  min-width: toRemMob(7);
  max-width: toRemMob(7);
  border-radius: 50%;
  margin: unset !important;
  margin-left: toRemMob(7) !important;

  &-active {
    opacity: 1;
  }
}

.image {
  &_main-greenhouse-slider {
    img {
      display: block;
    }
  }
}
</style>




