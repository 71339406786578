import * as VueRouter from "vue-router";
import LocalePages from "@/components/LocalPages";
import HomePage from "@/components/index-page/HomePage";
import GreenhousePage from "@/components/greenhouse-page/GreenhousePage";
import CompletedWorksPage from "@/components/CompletedWorksPage/CompletedWorksPage";
import NotFound from "@/components/NotFound";
import { store } from "@/main";
import { defaultLanguage } from "@/cfg";

const routes = [
  {
    path: "/:geoLocation/",
    name: "LocalePage",
    component: LocalePages,
    children: [
      {
        path: "",
        name: "HomePage",
        component: HomePage
      },
      {
        path: "greenhouses/:greenhouseSlug/",
        name: "GreenhousePage",
        component: GreenhousePage
      },
      {
        path: "completed-works/",
        name: "CompletedWorks",
        component: CompletedWorksPage
      },
      {
        path: "404/",
        name: "NotFound",
        component: NotFound,
        meta: {
          title: "Страница не найдена",
          isNotFound: true
        }
      },
      {
        path: ":catchAll(.*)",
        name: "NotFound",
        component: NotFound,
        meta: {
          title: "Страница не найдена",
          isNotFound: true
        }
      }
    ]
  }

  // {
  //     path: '/',
  //     redirect: '/ru',
  //     meta: {
  //         title: 'GreenhouseLine купить теплицу в английском стиле из стекла | Производство и продажа качественных теплиц из стелка',
  //     }
  // },


];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if ((from.name === to.name || from.name === undefined) && to.name !== 'CompletedWorks') {
      return savedPosition
    } else {
      return {left: 0, top: 0}
    }
  }
});
//
// const DEFAULT_TITLE = 'GreenhouseLine';

const changedJivoisite = (language, isDestroy) => {
  if (isDestroy) {
    window.jivo_destroy();
  }
  const dataForJivoiSite = {
    ru: "YtSypg7DX1",
    // us: "nrEoE0IDwS",
    // ca: "nrEoE0IDwS"
  };

  if (!dataForJivoiSite[language]) {
    return
  }
  const src = "//code-ya.jivosite.com/widget/" + dataForJivoiSite[language];
  const script = document.createElement("script");
  script.src = src;
  document.body.append(script);
};


router.beforeEach((to) => {
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  // see: https://router.vuejs.org/ru/guide/advanced/meta.html
  // see: https://v3.vuejs.org/guide/migration/global-api-treeshaking.html#_3-x-syntax
  const city = store.getters.currentCountry?.cities.find(city => city.abbreviation === to.params.geoLocation);
  const country = store.getters.countries.find(country => country.abbreviation === to.params.geoLocation)
  if (!city && !country) {
    store.commit("updateIsNotFound", true);
  }
  if (process.env.NODE_ENV === "production") {
    changedJivoisite(store.getters.currentCountry?.abbreviation || defaultLanguage, !!document.querySelector("#jivo-iframe-container"));
  }
});


export default router;


