<template>
  <section class="section section_comleted" v-if="comments.length">
    <div class="container">
      <h2 class="section__title section__title_comleted">
        {{ wordTranslation("completed_works_block.title") }}
      </h2>
      <div class="section__list section__list_comleted">
        <v-completed-items
          @changed-slide="collapseAllComments"
          :word-translation="wordTranslation"
          :gallery-init="galleryInit"
          :get-translate-json-field="getTranslateJsonField"
          :comments="comments"
          :get-comment-title="getCommentTitle"
          :format-date="formatDate"
          :get-files-src="getFilesSrc"
          :toggle-comment="toggleComment"
          :get-class-with-index="getClassWithIndex"
        />
      </div>
      <div class="completed-more__wrapper">
        <router-link class="completed-more__text" :to="{...$router, name: 'CompletedWorks'}">
          {{ wordTranslation("completed_works_block.all_comments") }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import vCompletedItems from "@/components/v-completed-items";
import moment from "moment";

import {Axios} from "@/assets/core/http-common";

export default {
  name: "vComleted",
  props: {
    getTranslateJsonField: Function,
    getTranslateField: Function,
    wordTranslation: Function,
    galleryInit: Function,
    getCommentTitle: Function,
    currentGreenhouseId: Number
  },
  components: {
    vCompletedItems
  },
  data() {
    return {
      variantExecutionId: 1,
      comments: [],
      durationTime: 300
    };
  },
  inject: ["deviceWidth"],
  computed: {
    ...mapGetters(['currentCountry'])
  },
  methods: {
    toggleComment(comment) {
      if (comment.isOpening) return;
      comment.isOpening = true;
      comment.isOpen = !comment.isOpen;
      setTimeout(() => {
        comment.isShowGradient = !comment.isShowGradient;
        comment.isOpening = false;
      }, this.durationTime / 2);
    },
    collapseAllComments() {
      this.comments.filter(comment => comment.isOpen).forEach(comment => {
        this.toggleComment(comment);
      });
    },
    getFilesSrc(host, comment) {
      return comment.files.map((file) => {
        if (file.is_video) {
          return {
            src: file.youtube_id,
            isVertical: false,
            is_youtube: file.is_video
          };
        }
        const image = host + (this.deviceWidth <= 640 ? file.mobile_thumb_filename : file.desktop_thumb_filename);
        return image;
      });
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    async getComments() {
      try {
        const params = {
          "is-index-page": true,
          'greenhouse-id': this.currentGreenhouseId,
          'country-id': this.currentCountry?.id
        };
        this.comments = await Axios.get(`completed-works/`, {params}).then(({data}) => data?.completed_works || []);
        // this.comments = this.comments
        //   .concat(JSON.parse(JSON.stringify(this.comments)))
        //
        //
        // this.comments[1].text="On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish.  "
        //
        // this.comments[0].text="But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever"

      } catch (error) {
        console.error(error);
      }
    },
    getClassWithIndex(className, index) {
      return Object.fromEntries([[`${className}${index}`, 1]]);
    },
  },
  mounted() {
    this.getComments();
  },
};
</script>

<style lang="scss">
.section {
  &_comleted {
    background-color: $color-grey-7;
    padding-top: toRemMob(30);
    @include _desktop {
      padding: toRem(60) 0 toRem(160);
    }
  }

  &__title {
    &_comleted {
      margin-bottom: toRemMob(30);
      margin-left: toRemMob(42);
      margin-right: toRemMob(48);
      font: 500 toRemMob(28)/toRemMob(33) "Roboto";

      @include _desktop {
        margin-bottom: toRem(63);
        margin-left: unset;
        margin-right: unset;
        font: 300 toRem(45)/toRem(53) "Roboto";
        color: $color-black;
      }

      span {
        display: block;
        @include _desktop {
          display: inline-block;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.completed-more {
  &__wrapper {
    display: flex;
    align-items: center;
    margin-top: toRemMob(0);
    padding: 0 toRemMob(40) toRemMob(60);
    @include _desktop {
      padding: 0;
      margin-top: toRem(60);
      justify-content: center;
    }
  }

  &__text {
    position: relative;
    font-size: toRemMob(16);
    line-height: toRemMob(19);
    color: $color-blue;
    font-weight: 300;
    cursor: pointer;
    border-right: toRemMob(10) solid transparent;

    @include _desktop {
      border-right: toRem(10) solid transparent;
      font-size: toRem(20);
      line-height: toRem(23);
    }

    &:hover {
      &::after {
        right: toRemMob(-20);
        @include _desktop {
          right: toRem(-20);
        }
      }
    }


    &::after {
      content: "";
      position: absolute;
      background-image: url("../assets/images/icons/more_blue.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width: toRemMob(10);
      height: toRemMob(10);
      right: toRemMob(-17);
      top: toRemMob(4.5);
      transition: right .2s ease;

      @include _desktop {
        width: toRem(9);
        height: toRem(15);
        right: toRem(-15);
        top: toRem(4.5);

      }
    }
  }
}
</style>

