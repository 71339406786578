<template>
  <section class="section section_page-feature">
    <div class="container">
      <h2 class="section__title section__title_page-feature">
        {{ wordTranslation("greenhouse_features_block.title") }}
      </h2>
      <div class="section__list section__list_feature">
        <template
          v-for="(feature, index) in features"
          :key="index">
          <div
            @click="toggleFeature(feature)"
            class="item item_feature"
            :class="{'active':feature.isOpen}"
          >
            <div class="item__image item__image_feature"
                 :class="`item__image_feature-${index} item__image_feature-${currentGreenhouseId}-${index} ${feature.isOpen ? 'active': ''}`">
              <img :src="$host + feature.image" :alt="getTranslateJsonField('title', feature)">
            </div>
            <div class="item__body item__body_feature">
              <h3 class="item__title item__title_feature">
                {{ getTranslateJsonField("title", feature) }}
              </h3>
              <button class="button button_feature">
                {{ wordTranslation("greenhouse_features_block.more_details") }}
              </button>
            </div>
          </div>
          <slide-up-down tag="article" v-model="feature.isOpen" :duration="durationTime" class="item item_article">
            <button @click="toggleFeature(feature)" class="button button_close-article"></button>
            <p v-html="getTranslateJsonField('text', feature)"></p>
          </slide-up-down>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import SlideUpDown from "vue3-slide-up-down";

export default {
  props: {
    wordTranslation: Function,
    getTranslateJsonField: Function,
    features: Array,
    currentGreenhouseId: Number
  },
  components: {SlideUpDown},
  inject: ["deviceWidth"],
  name: "vPageFeature",
  data() {
    return {
      durationTime: 500,
      currentFeature: null,
    };
  },
  methods: {
    toggleFeature(feature) {
      this.features.forEach(feature => {
        feature.isOpen = false;
      });
      if (this.currentFeature === null) {
        feature.isOpen = true;
      } else if (this.currentFeature !== feature) {
        setTimeout(() => {
          feature.isOpen = true;
        }, this.durationTime);
      }
      this.currentFeature = this.currentFeature === feature ? null : feature;

      this.$metrika.reachGoal('openFeatures')
    }
  }
};
</script>

<style lang="scss">

.section {
  &_page-feature {
    padding: toRemMob(29) 0 toRemMob(60);
    @include _desktop {
      padding: toRem(70) 0;
    }
  }

  &__title {
    &_page-feature {
      margin-bottom: toRemMob(22);
      margin-left: toRemMob(42);
      font: 500 toRemMob(28)/toRemMob(33) "Roboto";

      @include _desktop {
        margin-bottom: toRem(60);
        margin-left: unset;
        font: 300 toRem(45)/toRem(53) "Roboto";
        color: $color-black;
      }
    }
  }

  &__list {
    &_feature {
      display: flex;
      flex-wrap: wrap;
      margin-left: toRemMob(-15);
      margin-right: toRemMob(-15);
      @include _desktop {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

}

.item {
  &_feature {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: toRemMob(129);
    float: left;
    background-color: $color-grey;
    transition: height .5s;


    &.active {
      height: toRemMob(280);

      @include _desktop {
        height: unset;
      }

      img {
        object-position: top;
        @include _desktop {
          object-position: unset;
        }
      }
    }


    @include _desktop {
      height: toRem(360);
    }


    @include _desktop {
      width: 33.333333%;
    }


    &:nth-of-type(3) {
      @include _desktop {
        order: 3;
      }
    }

    &:nth-of-type(4) {
      @include _desktop {
        order: 3;
      }
    }

    &:nth-of-type(5) {

      @include _desktop {
        order: 3;
      }
    }

    &:nth-of-type(1) {

      @include _desktop {
        order: 1;
        width: 50%;
        height: toRem(539);
      }
    }

    &:nth-of-type(2) {

      @include _desktop {
        order: 2;
        width: 50%;
        height: toRem(539);
      }
    }

    &.active {
      .button_feature:before {
        transform: rotate(180deg);
      }
    }
  }

  &_article {
    position: relative;
    width: 100%;
    float: left;

    p {
      padding: toRemMob(30);
      font-weight: 300;
      font-size: toRemMob(16);
      line-height: toRemMob(24);
      @include _desktop {
        padding: toRem(60) 0;
        font-size: toRem(18);
        line-height: toRem(27);
      }
    }

    &.active {

    }


    &:nth-of-type(1) {

      @include _desktop {
        order: 3;
      }
    }

    &:nth-of-type(2) {

      @include _desktop {
        order: 3;
      }
    }

    &:nth-of-type(3) {

      @include _desktop {
        order: 6;
      }
    }

    &:nth-of-type(4) {


      @include _desktop {
        order: 9;
      }
    }

    &:nth-of-type(5) {

      @include _desktop {
        order: 10;
      }
    }

    p {
      margin-bottom: toRemMob(15);
      @include _desktop {
        margin-bottom: toRem(15);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    &_feature {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      @include _mobile {
        &-0 {
          img {
            object-position: top;
          }
        }

        &-1 {
          img {
            object-position: 0 toRemMob(-59);
          }
        }
        &-4 {
          img {
            object-position: 0 toRemMob(-130);
          }
        }
        &-2-4 {
          img {
            object-position: 0 toRemMob(-60);
          }
        }
      }


      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: object-position .5s;
      }
    }
  }

  &__body {
    &_feature {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      z-index: 1;
      width: 100%;
      padding: toRemMob(10) toRemMob(50) toRemMob(11) toRemMob(56);

      @include _desktop {
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
        padding: toRem(39) toRem(39);
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        z-index: 1;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(196, 196, 196, 0) 100%);
      }
    }
  }

  &__title {
    &_feature {
      position: relative;
      z-index: 1;
      font-size: toRemMob(16);
      line-height: toRemMob(19);
      font-weight: 700;
      color: $color-white;

      @include _desktop {
        margin-bottom: toRem(18);
        margin-left: toRem(3);
        font-size: toRem(30);
        line-height: toRem(35);
      }
    }
  }
}

.button {
  &_feature {
    position: relative;
    width: toRemMob(28);
    height: toRemMob(28);
    padding: 0;
    font-size: 0;
    line-height: 0;
    z-index: 1;

    @include _desktop {
      padding: toRem(13) toRem(19) toRem(13) toRem(51);
      width: max-content;
      height: unset;
      font-size: toRem(18);
      line-height: toRem(21);
      color: $color-white;
      background-color: rgba(0, 0, 0, 0.15);
      border: toRem(1) solid $color-white;
      border-radius: toRem(4);
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-size: 58%;
      background-image: url("../../assets/images/icons/arrow_feature-mob.svg");
      background-repeat: no-repeat;
      background-position: center;
      transition: transform .2s ease;

      @include _desktop {
        left: toRem(20);
        top: toRem(-2);
        right: unset;
        width: toRem(13);
        height: toRem(14);
        background-size: contain;
        background-image: url("../../assets/images/icons/arrow_feature.svg");
      }
    }
  }

  &_close-article {
    right: toRemMob(10);
    top: toRemMob(10);
    position: absolute;
    width: toRemMob(20);
    height: toRemMob(20);
    @include _desktop {
      top: toRem(20);
      width: toRem(20);
      height: toRem(20);
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-image: url("../../assets/images/icons/close-modal.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

</style>

