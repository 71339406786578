<template>
  <section class="section section_links">
    <div class="container">
      <div v-if="currentCountry.abbreviation === 'ru'" class="section__list section__list_links">
        <div class="item item_links active">
          <div class="item__image item__image_leaf">
            <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.6416 17.1887C5.41096 17.1892 4.02585 7.44152 3.04963 3.19765C7.96837 4.50694 18.9494 6.42128 16.6416 17.1887Z"
                fill="#3C6639"/>
              <path
                d="M15.3845 17.1549C15.5662 15.7538 14.1062 9.12516 24.4955 5.80319C23.8544 13.8235 21.2741 17.4198 15.3845 17.1549Z"
                fill="#3C6639"/>
            </svg>
          </div>
          <div class="item__description item__description_links">
            {{ wordTranslation("index_page.link_greenhouse") }}
          </div>
        </div>
        <a href="https://zavodteplic.ru" target="_blank" class="item item_links item_links-ico">
          <span class="item__image item__image_leaf">
       <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.6416 17.1887C5.41096 17.1892 4.02585 7.44152 3.04963 3.19765C7.96837 4.50694 18.9494 6.42128 16.6416 17.1887Z"
          fill="#3C6639"/>
        <path
          d="M15.3845 17.1549C15.5662 15.7538 14.1062 9.12516 24.4955 5.80319C23.8544 13.8235 21.2741 17.4198 15.3845 17.1549Z"
          fill="#3C6639"/>
       </svg>
      </span>
          <span class="item__description item__description_links">
            {{ wordTranslation("index_page.link_zgt") }}
          </span>
          <span class="item__image item__image_links">
       <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.0894 9.625C17.8573 9.625 17.6347 9.71719 17.4706 9.88128C17.3065 10.0454 17.2144 10.2679 17.2144 10.5V15.75C17.2144 15.9821 17.1222 16.2046 16.9581 16.3687C16.794 16.5328 16.5714 16.625 16.3394 16.625H5.83936C5.60729 16.625 5.38473 16.5328 5.22064 16.3687C5.05654 16.2046 4.96436 15.9821 4.96436 15.75V5.25C4.96436 5.01794 5.05654 4.79538 5.22064 4.63128C5.38473 4.46719 5.60729 4.375 5.83936 4.375H11.0894C11.3214 4.375 11.544 4.28281 11.7081 4.11872C11.8722 3.95462 11.9644 3.73206 11.9644 3.5C11.9644 3.26794 11.8722 3.04538 11.7081 2.88128C11.544 2.71719 11.3214 2.625 11.0894 2.625H5.83936C5.14316 2.625 4.47548 2.90156 3.9832 3.39384C3.49092 3.88613 3.21436 4.55381 3.21436 5.25V15.75C3.21436 16.4462 3.49092 17.1139 3.9832 17.6062C4.47548 18.0984 5.14316 18.375 5.83936 18.375H16.3394C17.0355 18.375 17.7032 18.0984 18.1955 17.6062C18.6878 17.1139 18.9644 16.4462 18.9644 15.75V10.5C18.9644 10.2679 18.8722 10.0454 18.7081 9.88128C18.544 9.71719 18.3214 9.625 18.0894 9.625Z"
          fill="#C0C0C0"/>
        <path
          d="M14.5894 4.375H15.9719L10.4682 9.87C10.3862 9.95134 10.3211 10.0481 10.2766 10.1547C10.2322 10.2614 10.2094 10.3757 10.2094 10.4912C10.2094 10.6068 10.2322 10.7211 10.2766 10.8278C10.3211 10.9344 10.3862 11.0312 10.4682 11.1125C10.5495 11.1945 10.6463 11.2596 10.7529 11.304C10.8595 11.3485 10.9739 11.3713 11.0894 11.3713C11.2049 11.3713 11.3193 11.3485 11.4259 11.304C11.5326 11.2596 11.6293 11.1945 11.7107 11.1125L17.2144 5.6175V7C17.2144 7.23206 17.3066 7.45462 17.4707 7.61872C17.6348 7.78281 17.8574 7.875 18.0894 7.875C18.3215 7.875 18.544 7.78281 18.7081 7.61872C18.8722 7.45462 18.9644 7.23206 18.9644 7V3.5C18.9644 3.26794 18.8722 3.04538 18.7081 2.88128C18.544 2.71719 18.3215 2.625 18.0894 2.625H14.5894C14.3574 2.625 14.1348 2.71719 13.9707 2.88128C13.8066 3.04538 13.7144 3.26794 13.7144 3.5C13.7144 3.73206 13.8066 3.95462 13.9707 4.11872C14.1348 4.28281 14.3574 4.375 14.5894 4.375Z"
          fill="#C0C0C0"/>
       </svg>
      </span>
        </a>
      </div>
    </div>
  </section>
  <section class="section section_main">
    <div class="container">
      <div class="section__wrapper section__wrapper_main" :class="{'visible-less': visible.page}">
        <div class="section__image section__image_greenhouse preloader preloader_start"
             :class="{'visible': visible.el, 'preloader_stop': preload.img }">
          <picture-element
            @load-image="onLoadMain"
            :image-desktop="$host + baseGreenhouseSlider?.desktop"
            :image-mobile="$host + baseGreenhouseSlider?.mobile"
            :default-image="$host + baseGreenhouseSlider?.desktop"
            :class="{'visible':  preload.img}"
            alt="Принимаем заказы!"
            class="unvisible image"
          />
        </div>
        <div class="section__image section__image_tree section__image_element"></div>
        <div class="section__image section__image_chair section__image_element"></div>
        <div class="section__block section__block_main">
          <h2
            class="section__title section__title_block section__title_block-main"
            :class="{[currentCountry?.abbreviation]: true}"
            v-html="wordTranslation('hero_block.title')">
          </h2>
          <template v-if="currentCountry.abbreviation === 'ru'">
            <div
              class="section__description section__description_block section__description_block-medium"
            >
              <span>
                {{ wordTranslation("hero_block.prepare_advance") }}
              </span>
              <tippy class="tippy__orientation">
                <div class="item__info_specification tippy-description placeholder"></div>
                <template #content>
                  <p class="tippy-description">
                    {{ wordTranslation("hero_block.prepare_advance_hint") }}
                  </p>
                </template>
              </tippy>
            </div>
            <div
              class="section__description section__description_block section__description_block-medium"
            >
              <span>
                {{ wordTranslation("hero_block.mounting") }}
              </span>
              <tippy class="tippy__orientation">
                <div class="item__info_specification tippy-description placeholder"></div>
                <template #content>
                  <p class="tippy-description">
                    {{ wordTranslation("hero_block.mounting_hint") }}
                  </p>
                </template>
              </tippy>
            </div>
          </template>
          <template v-else>
            <p
              v-if="wordTranslation('hero_block.description')?.length"
              class="section__description section__description_block section__description_block-big"
            >
              {{ wordTranslation("hero_block.description") }}
            </p>
            <p class="section__description section__description_block section__description_block-small">
              {{ wordTranslation("hero_block.sign_up_description") }}
              <a
                v-if="anotherCountries.includes(currentCountry?.abbreviation)"
                href="mailto:mng@greendi.com"
              >
                mng@greendi.com
              </a>
              <a
                v-else
                href="tel:88002346818"
              >
                8-800-234-68-18
              </a>
            </p>
          </template>

          <button
            @click.prevent="heroModal"
            class="button button_presentation"
          >
            {{ wordTranslation("buttons.sign_up_on_presentation") }}
          </button>
          <button
            @click.prevent="Object.entries($store.getters.baseGreenhouse || {}).length && openPreorder($store.getters.baseGreenhouse || {})"
            class="button button_order-main"
            :class="{'disable': !Object.entries($store.getters.baseGreenhouse || {}).length}"
          >
            {{ wordTranslation("buttons.place_preorder") }}
          </button>
          <p class="section__description section__description_block section__description_block-small">
            {{ wordTranslation("hero_block.invite_sellers") }}
          </p>
        </div>
        <div
          v-for="(heroPopup, index) in $options.heroPopups"
          :key="heroPopup.title"
          :class="`section__info_${index+1}` + (currentPopupTitle === heroPopup.title ? ' visible' : '')"
          class="section__info"
        >
          <div
            @click.stop="currentPopupTitle = currentPopupTitle !== heroPopup.title ? heroPopup.title : null"
            class="section__info-ico" :class="{'active': currentPopupTitle === heroPopup.title}"
          >

          </div>
          <div class="section__info-wrap">
            <div class="section__title section__title_info section__title_desktop">
              {{ wordTranslation(`hero_block.${heroPopup.title}`) }}
            </div>
            <div class="section__body section__body_info">
              <div class="section__title section__title_info section__title_mobile">
                {{ wordTranslation(`hero_block.${heroPopup.title}`) }}
              </div>
              <div class="section__description section__description_info">
                {{ wordTranslation(`hero_block.${heroPopup.value}`) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section section_pluses">
    <div class="container">
      <div class="section__list section__list_pluses">
        <div class="item item_pluses">
          <div class="item__image item__image_pluses">
            <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.6416 17.1887C5.41096 17.1892 4.02585 7.44152 3.04963 3.19765C7.96837 4.50694 18.9494 6.42128 16.6416 17.1887Z"
                fill="#3C6639"/>
              <path
                d="M15.3845 17.1549C15.5662 15.7538 14.1062 9.12516 24.4955 5.80319C23.8544 13.8235 21.2741 17.4198 15.3845 17.1549Z"
                fill="#3C6639"/>
            </svg>
          </div>
          <div class="item__description item__description_pluses">
            {{ wordTranslation("hero_block.advantage_delivery") }}
          </div>
        </div>
        <div class="item item_pluses">
          <div class="item__image item__image_pluses">
            <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.6416 17.1887C5.41096 17.1892 4.02585 7.44152 3.04963 3.19765C7.96837 4.50694 18.9494 6.42128 16.6416 17.1887Z"
                fill="#3C6639"/>
              <path
                d="M15.3845 17.1549C15.5662 15.7538 14.1062 9.12516 24.4955 5.80319C23.8544 13.8235 21.2741 17.4198 15.3845 17.1549Z"
                fill="#3C6639"/>
            </svg>
          </div>
          <div class="item__description item__description_pluses">
            {{ wordTranslation("hero_block.advantage_quality") }}
          </div>
        </div>
        <div class="item item_pluses">
          <div class="item__image item__image_pluses">
            <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.6416 17.1887C5.41096 17.1892 4.02585 7.44152 3.04963 3.19765C7.96837 4.50694 18.9494 6.42128 16.6416 17.1887Z"
                fill="#3C6639"/>
              <path
                d="M15.3845 17.1549C15.5662 15.7538 14.1062 9.12516 24.4955 5.80319C23.8544 13.8235 21.2741 17.4198 15.3845 17.1549Z"
                fill="#3C6639"/>
            </svg>
          </div>
          <div class="item__description item__description_pluses">
            {{ wordTranslation("hero_block.advantage_warranty") }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PictureElement from "@/components/PictureElement";
import {mapGetters} from "vuex";


export default {
  // hard code title and value hero popups.
  heroPopups: [
    {title: "ridge_decor_title", value: "ridge_decor"},
    {title: "high_quality_glazing_title", value: "high_quality_glazing"},
    {title: "comfortable_work_title", value: "comfortable_work"},
    {title: "various_door_configurations_title", value: "various_door_configurations"},
    {title: "aluminum_profile_title", value: "aluminum_profile"},
  ],
  name: "vFirstScreen",
  props: {
    sendModalPreorder: Function,
    openSignUpOnPresentation: Function,
    openPreorder: Function,
    wordTranslation: Function,
    anotherCountries: Array,
    openDownloadPresentation: Function
  },
  components: {PictureElement},
  data() {
    return {
      currentPopupTitle: null,
      visible: {
        page: false,
        sectionBlock: false,
        el: false,
      },
      preload: {
        img: false,
        img2: false,
      },
    };
  },
  created() {
    document.addEventListener("click", event => {
      if (!event.target.closest(".section__info")) {
        this.currentPopupTitle = null;
      }
    });
  },
  methods: {
    onLoadMain() {
      this.preload.img = true;
      this.visible.page = true;
    },
    heroModal() {
      if (this.currentCountry?.abbreviation !== "ru") {
        this.openSignUpOnPresentation("top");
      } else {
        this.openDownloadPresentation();
      }
    }
  },
  computed: {
    ...mapGetters([
      "currentCountry"
    ]),
    baseGreenhouseSlider() {
      return (this.$store.getters.baseGreenhouse?.slider && this.$store.getters.baseGreenhouse?.slider[0]) || {};
    },
  }
};
</script>

<style lang="scss">

.button {

  &_presentation {
    width: max-content;
    margin-top: toRemMob(20);
    padding: toRemMob(10) toRemMob(16);
    border-radius: toRemMob(5);
    border: 0;
    cursor: pointer;
    color: $color-black;
    background-color: $color-grey-6;
    font-weight: 300;
    font-size: toRemMob(16);
    line-height: toRemMob(16);
    text-align: center;
    @include _desktop {
      margin-top: toRem(23);
      padding: toRem(9) toRem(14);
      border-radius: toRem(5);
      font-size: toRem(18);
      line-height: toRem(21);
    }

    &:hover {
      background-color: $color-white;
    }
  }

  &_order-main {
    width: max-content;
    margin-top: toRemMob(19);
    margin-bottom: toRemMob(30);
    padding: toRemMob(10) toRemMob(15) toRemMob(9);
    border-radius: toRemMob(5);
    border: 0;
    cursor: pointer;
    color: $color-white;
    background-color: $color-grey-2;
    font-weight: 300;
    font-size: toRemMob(16);
    line-height: toRemMob(16);
    text-align: center;
    @include _desktop {
      margin-top: toRem(22);
      margin-bottom: toRem(33);
      padding: toRem(9) toRem(14) toRem(8);
      border-radius: toRem(5);
      font-size: toRem(18);
      line-height: toRem(21);
    }

    &:hover {
      opacity: .8;
    }
  }
}

.section {
  &_links {
    padding: toRemMob(36) toRemMob(0) toRemMob(27);
    @include _desktop {
      padding: toRem(102) 0 toRem(105);
    }
  }

  &_main {
    //min-height: toRemMob(880);
    @include _desktop {
      padding: 0 0 toRem(22);
      min-height: toRem(725);
    }
  }

  &_pluses {
    display: none;
    @include _desktop {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: toRem(207);
      padding-bottom: toRem(23);
      box-shadow: 0 toRem(16.36) toRem(24.06) rgba(0, 0, 0, 0.04);
    }
  }

  &__image {
    &_element {
      position: absolute;
    }

    &_tree {
      top: toRemMob(13);
      left: toRemMob(-202);
      width: toRemMob(279);
      height: toRemMob(279);
      transform: scale(-1, 1);
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/main-section/tree.png");
      @include _desktop {
        top: toRem(-91);
        left: auto;
        right: toRem(46);
        width: toRem(614);
        height: toRem(569);
        transform: unset;
      }
    }

    &_chair {
      top: toRemMob(219);
      right: toRemMob(315);
      width: toRemMob(99);
      height: toRemMob(136);
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/main-section/chair_bailer_mobile.png");
      @include _desktop {
        top: unset;
        bottom: toRem(8);
        right: toRem(357);
        width: toRem(357);
        height: toRem(261);
        transform: unset;
        background-image: url("../../assets/images/main-section/chair_bailer.png");
      }
    }

    &_main {
      position: relative;
    }

    &_greenhouse {
      height: toRemMob(414);
      width: toRemMob(414);
      @include _desktop {
        height: toRem(700);
        width: toRem(1096);
      }

      .image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__title {
    &_block {
      &-main {
        margin-bottom: toRemMob(22);
        font-size: toRemMob(32);
        line-height: toRemMob(40);
        font-weight: 700;
        color: $color-black;
        @include _desktop {
          margin-bottom: toRem(25);
          font-size: toRem(48);
          line-height: toRem(56);
        }
      }

      &.ru {
        margin-bottom: toRemMob(22);
        color: $color-black;
        @include _desktop {
          margin-bottom: toRem(32);
        }
      }
    }

    &_info {
      margin-bottom: toRemMob(9);
      font-size: toRemMob(18);
      line-height: toRemMob(18);
      font-weight: 700;
      font-family: "Noto Sans";
      color: $color-black;
      @include _desktop {
        max-width: toRem(301);
        min-width: toRem(301);
        margin-bottom: toRem(7);
        font-size: toRem(19);
        line-height: toRem(20);
        font-weight: 400;
        color: $color-white;
        text-shadow: 0 toRem(2) toRem(2) rgb(0, 0, 0, 0.59);
      }
    }

    &_mobile {
      display: block;
      @include _desktop {
        display: none;
      }
    }

    &_desktop {
      display: none;
      @include _desktop {
        display: block;
      }
    }
  }

  &__body {
    &_info {
      max-width: toRemMob(301);
      min-width: toRemMob(301);
      padding: toRemMob(11) toRemMob(14) toRemMob(16);
      border-radius: toRemMob(10);
      background-color: $color-white;
      box-shadow: 0 toRemMob(4) toRemMob(10) 0 rgb(0, 0, 0, 0.15);

      @include _desktop {
        max-width: toRem(301);
        min-width: toRem(301);
        padding: toRem(14);
        border-radius: toRem(10);
        margin-left: toRem(-7);
        box-shadow: 0 toRem(4) toRem(10) 0 rgb(0, 0, 0, 0.15);
      }
    }
  }

  &__description {
    &_block {

      &-big {
        width: 94%;
        margin-bottom: toRemMob(29);
        font-weight: 300;
        font-size: toRemMob(24);
        line-height: toRemMob(34);
        color: $color-black;
        @include _desktop {
          margin-bottom: toRem(34);
          font-size: toRem(22);
          line-height: toRem(33);
        }
      }

      &-small {
        font-weight: 300;
        font-size: toRemMob(16);
        line-height: toRemMob(24);
        color: $color-black;
        @include _desktop {
          font-size: toRem(18);
          line-height: toRem(26);
        }

        a {
          display: block;
          @include _desktop {
            display: inline-block;
          }
        }
      }

      &-medium {
        margin-bottom: toRemMob(20);
        font-size: toRemMob(18);
        line-height: toRemMob(21);
        font-weight: 300;
        @include _desktop {
          margin-bottom: toRem(20);
          font-size: toRem(22);
          line-height: toRem(26);
        }
      }
    }

    &_info {
      font: 400 toRemMob(16)/toRemMob(19) "Roboto";
      @include _desktop {
        font: 400 toRem(16)/toRem(18) "Roboto";
      }
    }
  }

  &__wrapper {
    &_main {
      position: relative;
      margin-left: toRemMob(-15);
      margin-right: toRemMob(-15);
      @include _desktop {
        margin: 0;
      }
    }
  }

  &__list {
    &_links {
      display: flex;
      align-items: center;
      margin-left: toRemMob(-12);
      @include _desktop {
        margin-left: toRem(-54);
      }
    }

    &_pluses {
      @include _desktop {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: toRem(-63);
      }
    }
  }

  &__block {
    &_main {
      position: relative;
      top: toRemMob(-68);
      right: 0;
      width: toRemMob(352);
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      padding: toRemMob(30) toRemMob(26) toRemMob(32);
      border-radius: toRemMob(8);
      background-color: rgba($color-grey-7, 0.85);
      backdrop-filter: blur(22px);
      @include _desktop {
        position: absolute;
        top: toRem(22);
        width: toRem(468);
        margin: unset;
        padding: toRem(38) toRem(43) toRem(34);
        border-radius: toRem(10);
      }
    }
  }

  &__info {
    position: absolute;
    display: flex;
    align-items: flex-start;
    z-index: auto;
    top: toRemMob(101);
    left: toRemMob(35);


    &_1 {
      @include _desktop {
        top: toRem(70);
        left: toRem(461);
      }

      .section__info-ico {
        left: toRemMob(185);
        top: toRemMob(-43);
        @include _desktop {
          left: unset;
          top: unset;
        }
      }

      .section__info-wrap {
        left: toRemMob(-17);
        position: relative;
        @include _desktop {
          left: unset;
          position: unset;
        }
      }
    }

    &_2 {
      @include _desktop {
        top: toRem(174);
        left: toRem(400);
      }

      .section__info-ico {
        left: toRemMob(154);
        top: toRemMob(10);
        @include _desktop {
          left: unset;
          top: unset;
        }
      }

      &.visible {
        top: toRemMob(155);
        @include _desktop {
          top: toRem(174);
        }

        .section__info-ico {
          top: toRemMob(-43);
          @include _desktop {
            top: unset;
          }
        }

        .section__info-wrap {
          left: toRemMob(-17);
          position: relative;
          @include _desktop {
            left: unset;
            position: unset;
          }
        }
      }
    }

    &_3 {

      @include _desktop {
        left: toRem(315);
        top: toRem(363);
      }

      .section__info-ico {
        left: toRemMob(103);
        top: toRemMob(95);
        @include _desktop {
          left: unset;
          top: unset;
        }
      }

      &.visible {
        top: toRemMob(28);
        @include _desktop {
          top: toRem(363);
        }

        .section__info-ico {
          top: toRemMob(167);
          @include _desktop {
            top: unset;
          }
        }

        .section__info-wrap {
          bottom: toRemMob(-127);
          left: toRemMob(22);
          position: absolute;
          height: auto;
          @include _desktop {
            bottom: unset;
            left: unset;
            position: unset;
            height: unset;
          }
        }
      }
    }

    &_4 {

      @include _desktop {
        top: toRem(530);
        left: toRem(234);
      }

      .section__info-ico {
        left: toRemMob(67);
        top: toRemMob(177);
        @include _desktop {
          left: unset;
          top: unset;
        }
      }

      &.visible {
        top: toRemMob(106);
        @include _desktop {
          top: toRem(530);
        }

        .section__info-ico {
          top: toRemMob(172);
          @include _desktop {
            top: unset;
          }
        }

        .section__info-wrap {
          bottom: toRemMob(-127);
          left: toRemMob(22);
          position: absolute;
          height: auto;
          @include _desktop {
            bottom: unset;
            left: unset;
            position: unset;
            height: unset;
          }
        }
      }
    }

    &_5 {
      @include _desktop {
        top: toRem(489);
        left: toRem(619);
      }

      .section__info-ico {
        left: toRemMob(215);
        top: toRemMob(140);
        @include _desktop {
          left: unset;
          top: unset;
        }
      }

      &.visible {
        top: toRemMob(74);
        @include _desktop {
          top: toRem(489);
        }

        .section__info-ico {
          top: toRemMob(167);
          @include _desktop {
            top: unset;
          }
        }

        .section__info-wrap {
          bottom: toRemMob(-127);
          left: toRemMob(22);
          position: absolute;
          height: auto;
          @include _desktop {
            bottom: unset;
            left: unset;
            position: unset;
            height: unset;
          }
        }
      }
    }

    &-ico {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: toRemMob(22);
      min-width: toRemMob(22);
      height: toRemMob(22);
      margin-right: 0;
      cursor: pointer;
      border-radius: 50%;
      border: toRemMob(1) solid rgba(0, 0, 0, .1);
      backdrop-filter: blur(4px);
      background-color: hsla(0, 0%, 100%, .36);

      @include _desktop {
        max-width: toRem(24);
        min-width: toRem(24);
        height: toRem(24);
        border: toRem(1) solid rgba(0, 0, 0, .1);
        backdrop-filter: blur(4px);
        transition: transform .2s ease, margin-right .2s ease;
      }

      &:before {
        content: "+";
        margin: auto;
        color: $color-black;
        font-size: toRemMob(17);
        line-height: toRemMob(20);
        text-align: center;
        font-weight: 300;

        @include _desktop {
          font-size: toRem(20);
          line-height: toRem(20);
        }

      }

      &.active {
        margin-right: toRemMob(16);
        transform: rotate(45deg) scale(1.5025);
        background-color: hsla(0, 0%, 100%, .93);
        border: toRemMob(1) solid rgba(0, 0, 0, .1);
        backdrop-filter: blur(6px);
        @include _desktop {
          margin-right: toRem(16);
          border: toRem(1) solid rgba(0, 0, 0, .1);
          transition: transform .2s ease, margin-right .2s ease;
        }

        &:before {
          color: #b12727;
        }
      }
    }

    &-wrap {
      display: none;
      @include _desktop {
        margin-top: toRem(2);
      }

    }

    &.visible > &-wrap {
      display: block;
      z-index: 1;
    }
  }

}

.item {
  &_links {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: toRemMob(17);
    margin-left: toRemMob(12);
    padding-bottom: toRemMob(7);
    cursor: pointer;
    white-space: nowrap;
    @include _desktop {
      padding-left: toRem(40);
      margin-left: toRem(54);
      padding-bottom: toRem(8);
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: toRemMob(2);
      margin: auto;
      background-color: $color-blue-2;
      width: 0;
      transition: all .2s ease;
      @include _desktop {
        height: toRem(2);
      }
    }

    &-ico {
      padding-right: toRemMob(19);
      @include _desktop {
        padding-right: toRem(25);
      }
    }

    &.active, &:hover {
      &:before {
        width: 100%;
      }
    }
  }

  &_pluses {
    @include _desktop {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: toRem(54);
      margin-left: toRem(63);
    }
  }

  &__image {

    &_leaf {
      position: absolute;
      width: toRemMob(15);
      height: toRemMob(11);
      left: toRemMob(1);
      top: toRemMob(2);
      @include _desktop {
        width: toRem(30);
        height: toRem(22);
        top: unset;
        left: 0;
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &_links {
      position: absolute;
      width: toRemMob(15);
      height: toRemMob(15);
      right: toRemMob(4);
      @include _desktop {
        width: toRem(23);
        height: toRem(23);
        right: toRem(-1);
        top: toRem(1);
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &_pluses {
      @include _desktop {
        width: toRem(40);
        height: toRem(30);
        left: 0;
        top: toRem(-5);
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__description {
    &_links {
      font-weight: 400;
      font-size: toRemMob(14);
      line-height: toRemMob(16);
      color: $color-black;
      @include _desktop {
        font-weight: 400;
        font-size: toRem(24);
        line-height: toRem(24);
      }
    }

    &_pluses {
      @include _desktop {
        font-weight: 300;
        font-size: toRem(22);
        line-height: toRem(22);
      }
    }
  }
}


</style>


<style scoped lang="scss">

.tippy-content .tippy-description {
  margin-bottom: toRemMob(30);
  @include _desktop {
    margin-bottom: toRem(32);

  }
}

.tippy-description.item__info_specification {
  display: inline-block;
  width: toRemMob(20);
  height: toRemMob(20);
  margin-bottom: toRemMob(-3);
  margin-left: toRemMob(5);
  margin-right: toRemMob(5);
  @include _desktop {
    margin-bottom: toRem(-3.7);
    width: toRem(24.9);
    height: toRem(24.9);
  }
}
</style>