<template>
  <modal-base class="modal_presentation" ref="modalBase" :wordTranslation="wordTranslation">
    <template #modal-error></template>
    <template #modal-success>
      <div class="modal__success">
        <div class="modal__image modal__image_success"></div>
        <h2 class="modal__title modal__title_success">{{ wordTranslation("base_modal.hooray") }}</h2>
        <p class="modal__description modal__description_success modal__description_success-presentation">
          {{ wordTranslation("presentation_modal.register_info") }}
        </p>
      </div>
    </template>
    <template #modal-title>
      {{ wordTranslation("presentation_modal.title") }}
    </template>
    <template #modal-content>
      <div class="modal__list">
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            {{ wordTranslation("presentation_modal.your_name") }}
            <span>*</span>
          </div>
          <input
            @input="fioError = ''"
            :placeholder="wordTranslation('presentation_modal.your_name_placeholder')"
            class="input input_modal input_modal-name"
            v-model="fio"
            type="text"
            name="name"
          >
          <div class="error__placeholder" v-show="fioError.length">
            {{ fioError }}
          </div>
        </div>
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            {{ wordTranslation("presentation_modal.phone_input") }}
            <span>*</span>
          </div>
          <modalWindowMask
            @update:phone="phone = $event.phone, phoneObject = $event.phoneObject"
            @input="phoneError = ''"
            :phone="phone"
            :word-translation="wordTranslation"
            ref="modalWindowMask"
          />
          <!--          <input class="input input_modal input_modal-phone" type="tel" name="phone" v-model="phone" placeholder="Введите номер телефона">-->
          <div class="error__placeholder" v-show="phoneError.length">
            {{ phoneError }}
          </div>
        </div>
      </div>
    </template>
    <template #modal-confirm>
      <button class="button button_modal button_modal-presentation button_green" @click="modalValidation">
        {{ wordTranslation("buttons.sign_up") }}
      </button>
      <p class="modal__politic">
        {{ wordTranslation("presentation_modal.politic_text") }}<br>
        <a class="link link_blue link_modal" download="user_agreement.pdf" href="/user_agreement.pdf">
          {{ wordTranslation("base_modal.personal_data") }}
        </a>
      </p>
    </template>
  </modal-base>
</template>

<script>

import ModalBase from "@/components/modal/ModalBase";

import modalWindowMask from "@/components/modal/ModalWindowMask";

export default {
  name: "ModalPresentation",
  props: {
    wordTranslation: Function
  },
  components: {
    ModalBase,
    modalWindowMask
  },
  data() {
    return {
      fio: "",
      fioError: "",
      phone: "",
      phoneError: "",
      phoneObject: {},
    };
  },
  methods: {
    open() {
      return this.$refs.modalBase.open();
    },
    close() {
      return this.$refs.modalBase.close();
    },
    regexValidation(re, str) {
      return re.test(str);
    },
    nameValidation() {
      if (!this.regexValidation(/^[\w \-А-я]{1,128}$/, this.fio)) {
        this.fioError = this.wordTranslation("base_modal.fio_error");
        return false;
      }
      this.fioError = "";
      return true;
    },
    phoneValidation() {
      if (!this.phoneObject.valid) {
        this.phoneError = this.wordTranslation("base_modal.phone_error");
        return false;
      }
      this.phoneError = "";
      return true;
    },
    modalValidation() {
      this.nameValidation();
      this.phoneValidation();
      if (!this.phoneError.length && !this.fioError.length) {
        this.$metrika.reachGoal('signUpPresentation')
        return this.$refs.modalBase.confirm({fio: this.fio, phone: this.phoneObject.formatted});
      }
    },
    async showError() {
      return new Promise(resolve => {
        this.$refs.modalBase.showError();
        setTimeout(() => {
          this.$refs.modalBase.hideError();
          resolve();
        }, 3000);
      });
    },
    async showSuccess() {
      return new Promise(resolve => {
        this.$refs.modalBase.showSuccess();
        setTimeout(() => {
          this.fio = "";
          this.phone = "";
          this.$refs.modalWindowMask.clearPhone();
          this.$refs.modalBase.hideSuccess();
          this.$refs.modalBase.close();
          resolve();
        }, 3000);
      });
    },
    showPreloader() {
      return this.$refs.modalBase.showPreloader();
    },
    hidePreloader() {
      return this.$refs.modalBase.hidePreloader();
    }
  }
};
</script>

<style lang="scss">
.button {
  &_modal-presentation {
    padding: toRemMob(16) toRemMob(32);
    @include _desktop {
      padding: toRem(16) toRem(32);
    }
  }
}

.modal {
  &_presentation {
    .modal__header {
      @include _desktop {
        margin-bottom: toRem(50);
      }
    }

    .modal__title {
      @include _desktop {
        font-size: toRem(28);
        line-height: toRem(33);
      }
    }

    .modal__body {
      @include _desktop {
        min-width: toRem(429);
        max-width: toRem(429);
      }
    }

    .modal__politic {
      @include _desktop {
        margin-top: toRem(101);
      }
    }

    .modal__description_error {
      margin: 0 toRemMob(70);
      @include _desktop {
        margin: 0 toRem(77);
      }
    }
  }
}
</style>
