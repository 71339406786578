<template>
  <modal-base class="modal_country" ref="modalBase" @close="closeEvent" :wordTranslation="wordTranslation">
    <template #modal-title>
      {{ wordTranslation("code_modal.title") }}
    </template>
    <template #modal-content>
      <input
        v-model="code"
        @input="inputCode"
        :placeholder="wordTranslation('code_modal.code_placeholder')"
        class="input input_modal input_modal-code"
        type="text"
        name="code"
      >
      <div
        v-if="codeError.length"
        class="warning__placeholder"
      >
        {{ codeError }}
      </div>
    </template>
    <template #modal-confirm>
      <button class="button button_modal button_green" @click="confirm">
        {{ wordTranslation("buttons.next") }}

      </button>
    </template>
  </modal-base>
</template>

<script>

import ModalBase from "@/components/modal/ModalBase";


export default {
  name: "ModalCode",
  components: {
    ModalBase,
  },
  props: {
    getTranslateJsonField: Function,
    wordTranslation: Function,
  },
  data() {
    return {
      code: "",
      codeError: "",
    };
  },
  methods: {
    open() {
      return this.$refs.modalBase.open();
    },
    close() {
      return this.$refs.modalBase.close();
    },
    closeEvent() {
      this.code = "";
      this.codeError = "";
    },
    confirm() {
      return this.$refs.modalBase.confirm({code: this.code});
    },
    async showError() {
      this.codeError = this.wordTranslation("code_modal.code_error");
    },
    inputCode() {
      this.codeError = "";
    },
    showPreloader() {
      return this.$refs.modalBase.showPreloader();
    },
    hidePreloader() {
      return this.$refs.modalBase.hidePreloader();
    }
  }
};
</script>

<style scoped lang="scss">
</style>
