<template>
  <div class="section section_completed-review">
    <div class="modal-upload">
      {{ wordTranslation("create_comment_modal.pin_image") }}
    </div>
    <div class="section__block section__block_title">
      <div class="container">
        <h1 class="section__title section__title_completed-review">
          {{ wordTranslation("completed_works_start_page.title") }}
        </h1>
      </div>
    </div>
    <div class="section__block section__block_count">
      <div class="container">
        <div class="section__count section__count_completed-review">
          <p class="section__description section__description_completed-review">
            {{ wordTranslation("completed_works_start_page.total_comments") }}
            <span>
            {{ countCompletedWorks }}
          </span>,
            {{ wordTranslation("completed_works_start_page.leave_your_comment") }}
          </p>
          <button @click.stop="openModalCreateComment()" class="button button_completed-review">
            <span>{{ wordTranslation("buttons.leave_comment") }}</span>
            <span>{{ wordTranslation("buttons.leave_comment") }}</span>
          </button>
        </div>
      </div>
    </div>
<!--    <div class="section__block section__block_tabs">-->
<!--      <div class="section__tabs section__tabs_completed-review">-->
<!--        <div-->
<!--          :class="{active: currentCompletedWorkFilter === null}"-->
<!--          @click="changeCurrentCompletedWorkFilter(null)"-->
<!--          class="tab tab_completed-review"-->
<!--        >-->
<!--          Все-->
<!--        </div>-->
<!--        <div-->
<!--          v-for="filter in completedWorkFilters"-->
<!--          :key="filter.variant_execution_id"-->
<!--          :class="{active: currentCompletedWorkFilter === filter.variant_execution_id}"-->
<!--          @click="changeCurrentCompletedWorkFilter(filter.variant_execution_id)"-->
<!--          class="tab tab_completed-review"-->
<!--        >-->
<!--          {{ getFilterTitle(filter) }}-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="section__block">
      <div class="container">
        <div class="section__list section__list_completed-review">
          <v-completed-item
            :word-translation="wordTranslation"
            :gallery-init="galleryInit"
            :get-translate-json-field="getTranslateJsonField"
            :comments="comments"
            :get-comment-title="getCommentTitle"
            :format-date="formatDate"
            :get-files-src="getFilesSrc"
            :get-class-with-index="getClassWithIndex"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vCompletedItem from "@/components/v-completed-item";
import moment from "moment";
import {Axios} from "@/assets/core/http-common";
import {changeMeta} from "@/assets/core/seo";
import {loadScript} from "@/assets/core/loadYoutubeScript";

import {mapGetters} from "vuex";

export default {
  name: "CompletedWorksPage",
  props: {
    getTranslateJsonField: Function,
    getTranslateField: Function,
    wordTranslation: Function,
    galleryInit: Function,
    openModalCreateComment: Function,
    getCommentTitle: Function,
  },
  components: {
    vCompletedItem
  },
  data() {
    return {
      variantExecutionId: 1,
      comments: [],
      completedWorkFilters: [],
      durationTime: 300,
      page: 0,
      countCompletedWorks: 0,
      isHasNextPage: true,
      currentCompletedWorkFilter: null,
      isLoadingComments: false,
    };
  },
  inject: ["showPagePreloader", "hidePagePreloader", "deviceWidth"],
  methods: {
    getFilesSrc(host, comment) {
      return comment.files.map((file) => {
        if (file.is_video) {
          return {
            src: file.youtube_id,
            isVertical: false,
            is_youtube: file.is_video
          };
        }
        const image = host + (this.deviceWidth <= 640 ? file.mobile_thumb_filename : file.desktop_thumb_filename);
        return image;
      });
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    async getComments(isFiltering = false) {
      this.isLoadingComments = true;
      const params = {
        "is-show-all": true,
        "page": this.page,
        "variant-execution-id": this.currentCompletedWorkFilter,
        'country-id': this.currentCountry?.id
      };
      try {
        const responseData = await Axios.get(`completed-works/`, {params}).then(({data}) => data);
        if (isFiltering) {
          this.comments = responseData.completed_works;
        } else {
          this.comments = this.comments.concat(responseData.completed_works);
        }
        this.page += 1;
        this.isHasNextPage = responseData.is_has_next_page;
        if (!this.countCompletedWorks) {
          this.countCompletedWorks = responseData.count;
        }
        this.isLoadingComments = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getFilters() {
      const params = {
        "country-id": this.currentCountry?.id
      };
      try {
        this.completedWorkFilters = await Axios.get(`completed-works-filters/`, {params}).then(({data}) => data.filters || []);
      } catch (error) {
        console.error(error);
      }
    },
    getClassWithIndex(className, index) {
      return Object.fromEntries([[`${className}${index}`, 1]]);
    },
    changeSeo() {
      const currentSeo = this.locales[this.currentCountry?.abbreviation][this.$route.name];
      if (!currentSeo) {
        return;
      }
      let {title, description} = currentSeo;
      if (this.currentCity) {
        title = currentSeo.city_title;
        description = currentSeo.city_description;
      }
      title = title
        .replace("[[country]]", this.getTranslateJsonField("title", this.currentCountry))
        .replace("[[cityTitle]]", this.currentCity?.seo_title);
      description = description
        .replace("[[country]]", this.getTranslateJsonField("title", this.currentCountry))
        .replace("[[cityTitle]]", this.currentCity?.seo_title);
      changeMeta({title, description});
    },
    checkScroll() {
      // Позиция всего контента (именно #app, а не body)
      const contentRect = document.querySelector("#app").getBoundingClientRect();
      // Позиция списка выполенные работ и отзывов
      const completedWorksListRect = this.$refs.sectionListCompletedWorks.getBoundingClientRect();
      // Позиция НАЧАЛА списка выполенные работ и отзывов на странице
      const completedWorksListPosition = completedWorksListRect.top - contentRect.top;
      // Высота экрана(дисплея)
      const screenHeight = window.innerHeight;
      // Высота списка выполенные работ и отзывов
      const completedWorksListHeight = this.$refs.sectionListCompletedWorks.offsetHeight;

      // Позиция Y конца списка - высота всего экрана - 200px (для загрузки до того как вы уведиле конец блока)
      const additionalValue = this.deviceWidth < 640 ? 300 : 200;
      const completedWorksListEnd = completedWorksListHeight + completedWorksListPosition - screenHeight - additionalValue;
      // Сколько проскролено
      const scrolled = window.scrollY;
      if ((scrolled >= completedWorksListEnd) && !this.isLoadingComments && this.isHasNextPage) {
        this.getComments();
      }
    },
    getFilterTitle(filter) {
      const greenhouseTitle = this.getTranslateJsonField("greenhouse_title", filter).replace("«Green Diamond» ", "");
      return `${greenhouseTitle} - ${this.getTranslateJsonField("variant_execution_title", filter)}`;
    },
    changeCurrentCompletedWorkFilter(variantExecutionId) {
      if (this.currentCompletedWorkFilter === variantExecutionId) return;
      this.currentCompletedWorkFilter = variantExecutionId;
      this.page = 0;
      this.getComments(true);
    }
  },
  computed: {
    ...mapGetters(["currentCountry", "currentCity", "locales"])
  },
  mounted() {
    this.getComments().then(() => {
      this.hidePagePreloader();
    });
    loadScript();
    this.getFilters();
    document.addEventListener("scroll", this.checkScroll);
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.checkScroll);
  },
  created() {
    this.showPagePreloader();
    this.changeSeo();
    this.$emit("init-change-seo", this.changeSeo);
  }
};
</script>

<style lang="scss">


.section {
  &_completed-review {
    padding: toRemMob(34) 0 toRemMob(40) 0;
    @include _desktop {
      padding: toRem(102) 0 toRem(160) 0 !important;
    }
  }

  &__block {

    &_title {
      margin-bottom: toRemMob(34);
      @include _desktop {
        margin-bottom: toRem(53);
      }
    }

    &_count {
      margin-bottom: toRemMob(34);
      padding: 0 toRemMob(40);
      @include _desktop {
        margin-bottom: toRem(45);
        padding: unset;
      }
    }

    &_tabs {
      max-width: $breakpoint-maximum;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: toRemMob(25);
      @include _desktop {
        margin-bottom: toRem(54);
        padding-left: toRem(175);
        padding-right: toRem(175);
      }
    }

  }


  &__title_completed-review {
    padding: 0 toRemMob(40);
    margin-bottom: toRemMob(34);
    font-size: toRemMob(28);
    line-height: toRemMob(33);
    font-weight: 500;
    @include _desktop {
      padding: unset;
      margin-bottom: toRem(53);
      font-size: toRem(45);
      line-height: 1;
      font-weight: 300;
    }
  }

  &__tabs {
    &_completed-review {
      display: flex;
      align-items: center;
      margin-left: toRemMob(-11);
      padding: 0 toRemMob(55);
      overflow: auto;
      @include _desktop {
        overflow: unset;
        flex-wrap: wrap;
        margin-left: toRem(-11);
        margin-bottom: toRem(-10);
        padding: unset;
      }

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }

  &__count {
    &_completed-review {
      display: flex;
      flex-direction: column;
      margin-bottom: toRemMob(44);
      @include _desktop {
        flex-direction: row;
        align-items: center;
        margin-bottom: toRem(100);
        padding: unset;
      }
    }
  }

  &__description {
    &_completed-review {
      margin-bottom: toRemMob(14);
      font-size: toRemMob(16);
      line-height: toRemMob(24);
      font-weight: 400;
      @include _desktop {
        margin-bottom: unset;
        font-size: toRem(18);
        line-height: toRem(24);
        font-weight: 300;
        margin-right: toRem(26);
      }

      span {
        color: #D20000;
      }
    }
  }

  &__list {
    &_completed-review {
      .item {
        &_completed {
          cursor: unset;
        }

        &__wrapper-comment {
          > div {
            margin-bottom: toRemMob(34);
            padding-bottom: toRemMob(46);
            border-bottom: toRemMob(1) solid #CBCBCB;
            @include _desktop {
              margin-bottom: toRem(50);
              padding-bottom: toRem(50);
              border-bottom: toRem(1) solid #CBCBCB;
            }

            &:last-child {
              margin-bottom: unset;
              padding-bottom: unset;
              border-bottom: unset;
            }

            &.pin {
              background-color: #F9FFF8;
              border-bottom: unset;
              @include _desktop {
                border-radius: toRem(20);
                padding: toRem(32);
                margin: toRem(0) toRem(-32) toRem(63);
              }
            }
          }
        }
      }
    }
  }

}

.button {
  &_completed-review {
    font-size: toRemMob(16);
    line-height: toRemMob(19);
    color: $color-blue;
    cursor: pointer;
    padding: unset;
    font-weight: 300;
    @include _desktop {
      padding: toRem(15) toRem(83);
      font-size: toRem(18);
      line-height: toRem(18);
      border: toRem(1) solid #21A73F;
      border-radius: toRem(4);
      color: #777777;
    }

    &:hover {
      @include _desktop {
        color: #fff;
        background-color: #21A73F;
      }
    }
  }

  span {
    &:nth-child(1) {
      display: none;
      @include _desktop {
        display: block;
      }
    }

    &:nth-child(2) {
      display: block;
      @include _desktop {
        display: none;
      }
    }
  }
}

.modal-upload {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: #00000073;
  display: none;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: toRemMob(32);

  @include _desktop {
    font-size: toRem(32);
  }

  &.active {
    display: flex;
  }
}

.tab {

  &_completed-review {
    margin-left: toRemMob(11);
    padding: toRemMob(4) toRemMob(9);
    border: toRemMob(1) solid #D9D9D9;
    border-radius: toRem(20);
    white-space: nowrap;
    font-size: toRemMob(16);
    line-height: 1;
    cursor: pointer;
    transition: all .2s ease;

    @include _desktop {
      padding: toRem(7) toRem(14) toRem(7);
      margin-left: toRem(11);
      margin-bottom: toRem(10);
      border: toRem(1) solid #D9D9D9;
      border-radius: toRem(20);
      font-size: toRem(18);
    }

    &.active {
      background-color: #D9D9D9;
    }

    &:hover {
      background-color: #D9D9D9;
      transition: all .2s ease;
    }
  }

}
</style>