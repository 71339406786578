<template>
  <div
    v-for="(greenhouse, index) in greenhouses"
    :key="greenhouse.id"
    class="item item_greenhouse">
    <div class="item__wrapper item__wrapper_greenhouse">
      <swiper
        :css-mode="true"
        :slides-per-view="1"
        :space-between="0"
        :pagination='{
            el: `.item__pagination-${greenhouse.id}`,
            type: "custom",
            renderCustom: (swiper, current, total) => {
              return `${current} / ${total}`;
            },
          }'
        :breakpoints='{
          "640": {
             cssMode: false,
            navigation:{
            prevEl: `.item__arrow.item__arrow_catalog.item__arrow_catalog-prev-${greenhouse.id}`,
            nextEl: `.item__arrow.item__arrow_catalog.item__arrow_catalog-next-${greenhouse.id}`
              }
            },
          }'
        @swiper="swiper => greenhouseSwiper[greenhouse.id] = swiper"
      >
        <swiper-slide
          v-for="(item, index) in greenhouse.slider"
          :key="index"
          @click.stop="galleryInit(
            getGalleryImage({slider: greenhouse.slider, event: $event}),
            index,
            greenhouseSwiper[greenhouse.id],
            this.deviceWidth < 640 ? 828: 2192,
            this.deviceWidth < 640 ? 828: 1564,
          )"
        >
          <div
            class="item__image item__image_greenhouse preloader preloader_start"
            :class="{'visible-more': preload.greenhouseImages[greenhouse.id], 'preloader_stop': preload.greenhouseImages[greenhouse.id]}"
          >
            <picture-element
              @load-image="onLoadGreenhouse(greenhouse.id)"
              :image-desktop="$host + item.desktop"
              :image-mobile="$host + item.mobile"
              :default-image="$host + item.desktop"
              :class="{'visible': preload.greenhouseImages[greenhouse.id]}"
              :alt="getTranslateField('title', greenhouse)"
              class="unvisible image pswp__image"
            >
            </picture-element>

          </div>
        </swiper-slide>
      </swiper>
      <div class="item__wrapper item__wrapper_control item__wrapper_control-catalog">
        <div class="item__pagination item__pagination_catalog" :class="`item__pagination-${greenhouse.id}`">
        </div>
        <div class="item__navigation item__navigation_catalog">
          <div
            :class="`item__arrow_catalog-prev-${greenhouse.id}`"
            class="item__arrow item__arrow_catalog item__arrow_catalog-prev"
          ></div>
          <div
            :class="`item__arrow_catalog-next-${greenhouse.id}`"
            class="item__arrow item__arrow_catalog item__arrow_catalog-next"
          ></div>
        </div>
      </div>
      <div class="item__image item__image_element item__image_tree preloader"
           :class="{...getClassWithIndex('item__image_tree', index+1), 'visible': preload.greenhouseTrees[greenhouse.id]}">
        <img @load="loadTree(greenhouse.id)" class="unvisible image image_contain" :src="$host + greenhouse.image_tree"
             :alt="wordTranslation('greenhouse_block.alt_near_tree')"
             :class="{'visible': preload.greenhouseTrees[greenhouse.id]}">
      </div>
      <div class="item__block item__block_greenhouse">
        <div class="item__wrapper item__wrapper_title item__wrapper_title-greenhouse">
          <h2 class="item__title item__title_greenhouse">
            {{ getTranslateField("title", greenhouse) }}
            <div v-if="0" class="item__wrapper item__wrapper_info-greenhouse">
              <tippy
                trigger="click"
              >
                <!--                <div class="item__info item__info_greenhouse"></div>-->
                <template #content>
                  <div class="tippy-image">
                    <img :src='$host + greenhouse.hint.image'
                         :alt='getTranslateJsonField("text", greenhouse.hint)'>
                  </div>
                  <p class="tippy-description">
                    {{ getTranslateJsonField("text", greenhouse.hint) }}
                  </p>
                </template>
              </tippy>
            </div>
          </h2>


        </div>
        <div class="item__wrapper item__wrapper_greenhouse-list">
          <p class="item__description item__description_greenhouse">
            {{ wordTranslation("greenhouse_block.variants_execution") }}
          </p>
          <div class="item__list item__list_options">
            <div
              v-for="variantExecution in greenhouse.variants_execution"
              :key="`variant-execution-${variantExecution.id}`"
              @click="changeCurrentVariantExecution({variantExecution, greenhouseSlug: greenhouse.slug})"
              :class="{active: currentVariantExecution === variantExecution, disable: variantExecution.is_disable}"
              class="item item_options"
            >
              <div
                :style="`background-image: url(${$host + variantExecution.image})`"
                class="item__image item__image_options"
              >
              </div>
              <p class="item__description item__description_options" :class="{[currentCountry?.abbreviation]: true}">
                {{ getTranslateJsonField("title", variantExecution) }}
              </p>
            </div>
          </div>
        </div>
        <p class="item__description item__description_greenhouse">
          {{ getTranslateField("description", greenhouse) }}
        </p>
        <div class="item__price item__price_greenhouse">
          {{ wordTranslation("price_help.price_prefix") }}&nbsp;

          <span v-if="getTranslateJsonField('discount', greenhouse)">
              <div class="discount-large-cross">
              {{
                  nicePrice(
                    getFakeOldPrice(
                      getTranslateField("price", greenhouse), getTranslateJsonField("discount", greenhouse)
                    )
                  )
                }}
              </div>
            </span>

          {{ nicePrice(getTranslateField("price", greenhouse)) }}&nbsp;&nbsp;
          <div>
            {{ wordTranslation("price_help.currency") }}
          </div>
          <div v-if="getTranslateJsonField('discount', greenhouse)" class="badge-discount">
            {{ "-" + getTranslateJsonField("discount", greenhouse) + "%" }}
          </div>
        </div>
        <p class="item__description item__description_greenhouse">
          {{ getTranslateField("date_release", greenhouse) }}
        </p>
        <div class="item__wrapper item__wrapper_buttons">
          <button
            @click.prevent="openPreorder(greenhouse)"
            class="button button_order-catalog"
          >
            <template v-if="greenhouse.is_can_order">
              {{ wordTranslation("buttons.order") }}
            </template>
            <template v-else>
              {{ wordTranslation("buttons.preorder") }}
            </template>
          </button>
          <router-link
            v-if="[1, 2].includes(greenhouse.id)"
            :to="{name: 'GreenhousePage', params: {...$route.params, greenhouseSlug: greenhouse.slug}}"
            :class="{'disable': ![1, 2].includes(greenhouse.id)}"
            class="button button_more"
          >
            {{ wordTranslation("buttons.more_details") }}
          </router-link>
          <button
            v-else
            class="button button_more disable"
          >
            {{ wordTranslation("buttons.more_details") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Tippy} from "vue-tippy";
// Import Axios
import {Axios} from "@/assets/core/http-common";

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/effect-fade/effect-fade.min.css";

import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import PictureElement from "@/components/PictureElement";

// import Swiper core and required modules
import SwiperCore, {EffectFade, Navigation, Pagination} from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, Navigation, EffectFade]);
import {mapGetters} from "vuex";


export default {
  props: {
    getTranslateField: Function,
    sendModalPreorder: Function,
    openPreorder: Function,
    wordTranslation: Function,
    getTranslateJsonField: Function,
    galleryInit: Function,
  },
  inject: ["hidePagePreloader", "getCompressImage", "nicePrice", "deviceWidth", "getFakeOldPrice"],
  components: {
    Swiper,
    SwiperSlide,
    Tippy,
    PictureElement
  },
  name: "vCatalogItem",
  data() {
    return {
      greenhouses: [],
      baseGreenhouse: {},
      greenhouseSwiper: {},
      preload: {
        greenhouseImages: {},
        greenhouseTrees: {},
      },
      currentVariantExecution: null,
    };
  },
  computed: {
    ...mapGetters([
      "currentCountry"
    ])
  },
  methods: {
    async getGreenhouses() {
      try {
        const {data} = await Axios.get("greenhouses/");
        this.greenhouses = data?.greenhouses || [];
        this.$store.commit("updateGreenhouses", this.greenhouses);
        this.$store.commit("updateBaseGreenhouse", data?.base_greenhouses);
        this.hidePagePreloader();
      } catch (error) {
        console.log(error);
      }
    },
    changeCurrentVariantExecution(data) {
      const {variantExecution, greenhouseSlug} = data;

      if (variantExecution.is_disable) {
        return;
      }

      this.currentVariantExecution = variantExecution;
      this.$router.push({
        name: "GreenhousePage",
        params: {...this.$route.params, greenhouseSlug: greenhouseSlug},
        query: {"variant-execution": variantExecution.id}
      });
    },
    loadTree(greenhouseId) {
      this.preload.greenhouseTrees[greenhouseId] = true;
    },
    onLoadGreenhouse(greenhouseId) {
      this.preload.greenhouseImages[greenhouseId] = true;
    },
    getClassWithIndex(className, index) {
      return Object.fromEntries([[`${className}-${index}`, 1]]);
    },
    getGalleryImage(data) {
      const {slider, event} = data;
      const currentSrc = event.target.closest("img")?.currentSrc || "";
      const currentCompress = /@(\d)x.\w+/.exec(currentSrc)[1];
      return slider.map(image => {
        if (this.deviceWidth < 640) {
          return this.getCompressImage(this.$host + image.mobile, currentCompress);
        }
        return this.getCompressImage(this.$host + image.desktop, currentCompress);
      });
    }
  },
  mounted() {
    this.getGreenhouses();
  }
};
</script>

<style lang="scss">

.preloader {
  &_greenhouse {
    height: toRemMob(418);
    width: toRemMob(414);
    @include _desktop {
      height: toRem(782);
      width: toRem(1096);
    }
  }
}

.button {
  &_order-catalog {
    display: flex;
    align-items: center;
    justify-content: center;
    height: toRemMob(35);
    width: toRemMob(142);
    border-radius: toRemMob(5);
    border: 0;
    font: 400 toRemMob(16)/toRemMob(16) "Roboto";
    color: $color-white;
    background-color: $color-green;
    cursor: pointer;
    @include _desktop {
      height: toRem(38);
      width: toRem(124);
      border-radius: toRem(5);
      font: 400 toRem(16)/toRem(16) "Roboto";
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &_more {
    display: flex;
    align-items: center;
    justify-content: center;
    height: toRemMob(35);
    width: toRemMob(144);
    border-radius: toRemMob(5);
    font: 400 toRemMob(15)/toRemMob(18) "Roboto";
    color: $color-black;
    background-color: $color-grey-6;
    border: 1px solid $color-grey-23;
    cursor: pointer;
    @include _desktop {
      height: toRem(38);
      width: toRem(124);
      border-radius: toRem(5);
      font: 400 toRem(15)/toRem(18) "Roboto";
    }

    &:hover {
      @include _desktop {
        background-color: $color-grey-24;
        color: $color-white;
        border-color: $color-grey-24;
      }
    }

    &:active {
      background-color: $color-grey-24;
      color: $color-white;
      @include _desktop {
        color: $color-black;
        background: rgba(33, 167, 63, 0.1);
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
    }
  }
}

.badge-discount {
  background-color: $color-red-2;
  color: $color-white;
  font-size: toRemMob(12);
  line-height: toRemMob(12);
  padding: toRemMob(2) toRemMob(4);
  border-radius: toRemMob(2);
  margin-left: toRemMob(13);
  height: toRemMob(16);
  @include _desktop {
    border-radius: toRem(2);
    font-size: toRem(12);
    line-height: toRem(12);
    padding: toRem(2) toRem(4);
    height: toRem(16);
    margin-left: toRem(13);
  }
}

.item {
  &_greenhouse {
    margin-bottom: toRemMob(60);
    @include _desktop {
      margin-bottom: toRem(420);
    }
  }

  &_options {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: toRemMob(76);
    min-width: toRemMob(76);
    padding: toRemMob(12) toRemMob(6.2) toRemMob(10) toRemMob(6.2);
    cursor: pointer;
    transition: background-color .2s ease;
    border-radius: toRemMob(5);

    &:nth-child(2n) {
      @include _desktop {
        .item__description_options.us, .item__description_options.ca {
          padding-left: toRem(10);
        }
      }

      @include _mobile {
        .item__description_options.us, .item__description_options.ca {
          padding-left: toRemMob(6);
        }
      }
    }

    &:nth-child(2) {
      @include _mobile {
        .item__description_options {
          padding-left: toRemMob(8) !important;
        }
      }
    }

    @include _desktop {
      width: 25%;
      max-width: unset;
      min-width: unset;
      padding: toRem(20) toRem(19) toRem(20) toRem(19);
      border-radius: toRem(10);
    }

    &:before {
      content: '';
      transform: scale(0);
      position: absolute;
      width: toRemMob(5);
      height: toRemMob(5);
      left: toRemMob(5);
      top: toRemMob(5);
      border-radius: 50%;
      transition: all .2s ease;
      background-color: $color-green-2;
      @include _desktop {
        left: toRem(5);
        top: toRem(5);
        width: toRem(10);
        height: toRem(10);
      }
    }

    &:hover {
      background-color: $color-white;
      box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.05);
    }

    &.active {
      background-color: $color-white;

      &::before {
        transform: scale(1);
      }
    }
  }

  &__title {
    &_greenhouse {
      display: inline-block;
      font: 700 toRemMob(28)/toRemMob(33) "Roboto";
      @include _desktop {
        font: 700 toRem(35)/toRem(41) "Roboto";
      }
    }
  }

  &__info {

    &_greenhouse {
      width: toRemMob(20);
      height: toRemMob(20);
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/icons/info.svg");
      cursor: pointer;
      @include _desktop {
        width: toRem(26);
        height: toRem(26);
      }
    }
  }

  &__wrapper {

    &_title {
      &-greenhouse {
        display: flex;
        align-items: center;
        margin-bottom: toRemMob(19);
        @include _desktop {
          margin-bottom: toRem(29);
        }
      }
    }

    &_greenhouse {
      position: relative;
      margin-left: toRemMob(-15);
      margin-right: toRemMob(-15);
      @include _desktop {
        margin-left: unset;
        margin-right: unset;
      }
    }

    &_greenhouse-list {
      margin-bottom: toRemMob(22);
      @include _desktop {
        margin-bottom: toRem(31);
      }
    }

    &_buttons {
      display: flex;
      align-items: center;
      margin-top: toRemMob(10);
      @include _desktop {
        margin-top: toRem(20);
      }

      > .button {
        margin-left: toRemMob(14);
        @include _desktop {
          margin-left: toRem(11);
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }

    &_control {
      &-catalog {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 1;
        right: toRemMob(31);
        top: toRemMob(31);

        @include _desktop {
          left: toRem(32);
          bottom: toRem(35);
          right: unset;
          top: unset;
        }
      }
    }

    &_info-greenhouse {
      display: inline-block;
      margin-left: toRemMob(4);
      @include _desktop {
        margin-left: toRem(10);
      }
    }
  }

  &__pagination {
    &_catalog {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: toRemMob(23);
      padding-right: toRemMob(5);
      height: toRemMob(23);
      min-width: toRemMob(57);
      border-radius: toRemMob(5);
      font-size: toRemMob(14);
      line-height: toRemMob(15);
      font-weight: 300;
      color: $color-white;
      background: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(2px);

      @include _desktop {
        padding-left: toRem(23);
        padding-right: unset;
        height: toRem(35);
        min-width: toRem(77);
        border-radius: toRem(10);
        font-size: toRem(15);
        line-height: toRem(15);
      }


      &:before {
        content: "";
        position: absolute;
        width: toRemMob(14);
        height: toRemMob(14);
        left: toRemMob(5);
        top: 0;
        bottom: 0;
        margin: auto;
        background-image: url("../../assets/images/icons/photo.svg");
        background-size: contain;
        background-repeat: no-repeat;
        @include _desktop {
          width: toRem(17);
          height: toRem(15);
          left: toRem(9);
        }
      }
    }
  }

  &__navigation {
    &_catalog {
      display: none;

      @include _desktop {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: toRem(4);
        min-width: toRem(76);
        margin-left: toRem(15);
      }
    }
  }

  &__arrow {
    &_catalog {

      @include _desktop {
        width: toRem(32);
        height: toRem(32);
        cursor: pointer;
        background-image: url("../../assets/images/icons/arrow.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      &.swiper-button-disabled {
        @include _desktop {
          user-select: none;
          cursor: default;
          opacity: 0.3;
        }
      }

      &-prev {
        @include _desktop {
          transform: scale(-1, 1);
        }
      }
    }
  }

  &__block {
    &_greenhouse {
      position: relative;
      right: 0;
      width: toRemMob(352);
      margin: toRemMob(-86) auto 0;
      display: flex;
      flex-direction: column;
      padding: toRemMob(20) toRemMob(26) toRemMob(40);
      border-radius: toRemMob(10);
      z-index: 16;
      background-color: rgba($color-grey-7, 0.85) !important;
      backdrop-filter: blur(22px);
      @include _desktop {
        position: absolute;
        bottom: toRem(-198);
        width: toRem(528);
        margin: unset;
        padding: toRem(35) toRem(34) toRem(76) toRem(35);
        border-radius: toRem(10);
      }
    }
  }

  &__list {
    &_options {
      display: flex;
      justify-content: space-between;
      margin: 0 toRemMob(-12);

      @include _desktop {
        margin: 0;
      }
    }
  }

  &__image {
    &_element {
      position: absolute;
      z-index: 1;
    }

    &_tree {
      display: none;
      z-index: 15;

      @include _desktop {
        display: block;
        top: 0;
        right: toRem(66);
        width: toRem(547);
        height: toRem(596);
      }

      &-2 {
        @include _desktop {
          right: toRem(94);
          width: toRem(540);
          height: toRem(585);
        }
      }

      &-3 {
        @include _desktop {
          right: toRem(42);
          width: toRem(566);
        }
      }
    }

    &_options {
      width: toRemMob(49);
      height: toRemMob(40);
      margin: 0 auto toRemMob(11);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;

      @include _desktop {
        width: toRem(65);
        height: toRem(53);
        margin: 0 0 toRem(10);
      }
    }

    &_greenhouse {
      height: toRemMob(418);
      width: toRemMob(414);
      @include _desktop {
        height: toRem(782);
        width: toRem(1096);
      }

      .image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include _desktop {
          border-radius: toRem(12);
          overflow: hidden;
        }
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__description {
    &_greenhouse {
      font: 300 toRemMob(14)/toRemMob(20) "Roboto";
      @include _desktop {
        font: 300 toRem(15)/toRem(22) "Roboto";
      }
    }

    &_options {
      font: 300 toRemMob(11)/toRemMob(13) "Roboto";
      color: $color-black;
      @include _desktop {
        font: 300 toRem(13)/toRem(13) "Roboto";
      }
    }
  }

  span .discount-large-cross {
    color: $color-grey-3;
    text-decoration: line-through;
    font-weight: 400;
    margin: 0 toRemMob(5) 0 0;
    @include _desktop {
      font-weight: 300;
      white-space: nowrap;
      text-align: center;
      margin: 0 toRem(5);
    }
  }

  &__price {
    &_greenhouse {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin-top: toRemMob(19);
      margin-bottom: toRemMob(25);
      white-space: nowrap;
      font: 300 toRemMob(18)/toRemMob(22) "Roboto";
      @include _desktop {
        margin-top: toRem(33);
        margin-bottom: toRem(12);
        font: 300 toRem(22)/toRem(22) "Roboto";
      }
    }
  }
}
</style>




