<template>
  <swiper
    class="swiper-additional"
    :cssMode="true"
    :slidesPerView="'auto'"
    :watchSlidesProgress="true"
    :space-between="0"
    :breakpoints='{
      "640": {
        cssMode: false,
        navigation:{
        prevEl: ".item__arrow.item__arrow_additional.item__arrow_additional-prev",
        nextEl: ".item__arrow.item__arrow_additional.item__arrow_additional-next"
          }
        },
      }'
  >
    <swiper-slide
      v-for="addition in additions"
      :key="addition.id"
      class="swiper-slide_additional"
    >
      <div class="item item_additional">
        <div class="item__wrapper item__wrapper_additional">
          <div class="item__image item__image_additional">
            <img class="image" :src="$host + addition.image+'?V=001'" alt="">
          </div>
          <h2 class="item__title item__title_additional item__title_additional-desktop">
            {{ getTranslateField("title", addition) }} <span></span>
          </h2>
        </div>
        <h2 class="item__title item__title_additional item__title_additional-mobile">
          {{ getTranslateField("title", addition) }}
        </h2>
        <p class="item__description item__description_additional">
          {{ getTranslateField("description", addition) }}
        </p>
      </div>
    </swiper-slide>
  </swiper>
  <div class="item__navigation item__navigation_additional">
    <div class="item__arrow item__arrow_additional item__arrow_additional-prev"></div>
    <div class="item__arrow item__arrow_additional item__arrow_additional-next"></div>
  </div>
</template>

<script>

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/effect-fade/effect-fade.min.css";

import "swiper/modules/navigation/navigation.min.css";

// import Swiper core and required modules
import SwiperCore, {
  Navigation
} from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

export default {
  props: {
    additions: Array,
    getTranslateField: Function,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  name: "vAdditionalItems"
};
</script>

<style lang="scss">

.swiper {
  &-additional {
    margin-left: -$padding-trunk-mobile;
    margin-right: -$padding-trunk-mobile;
    @include _desktop {
      margin-left: unset;
      margin-right: unset;
    }
  }

  &-slide {
    &_additional {
      width: toRemMob(272);
      box-sizing: initial;
      @include _desktop {
        width: toRem(322);
        box-sizing: border-box;
      }

      &:not(:first-child) {
        border-left: toRemMob(45) solid transparent;
        @include _desktop {
          border-left: unset;
        }
      }

      &:first-child {
        border-left: toRemMob(57) solid transparent;
        @include _desktop {
          border-left: unset;
        }
      }

      &:last-child {
        box-sizing: initial;
        border-right: toRemMob(57) solid transparent;
        @include _desktop {
          box-sizing: unset;
          border-right: unset;
        }
      }
    }
  }
}

.item {
  &_additional {
    display: flex;
    flex-direction: column;
  }

  &__wrapper {

    &_additional {
      position: relative;
      margin-bottom: toRemMob(26);
      border-radius: toRemMob(5);
      overflow: hidden;
      @include _desktop {
        margin-bottom: unset;
        border-radius: unset;
        overflow: unset;
      }

      &:before {
        @include _desktop {
          content: '';
          position: absolute;
          width: 100%;
          height: toRem(140);
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(360deg, rgba(0, 0, 0, 0.66) 0%, rgba(196, 196, 196, 0) 100%);
        }
      }
    }
  }

  &__image {
    &_additional {
      width: toRemMob(272);
      height: toRemMob(198);
      @include _desktop {
        width: toRem(322);
        height: toRem(322);
        margin-bottom: unset;
      }

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include _desktop {
          object-fit: contain;
        }
      }
    }
  }

  &__title {
    &_additional {
      &-desktop {
        display: none;
        @include _desktop {
          display: block;
          position: absolute;
          bottom: toRem(25);
          left: toRem(21);
          padding-right: toRem(25);
          color: $color-white;
          font: 700 toRem(22)/toRem(26) "Roboto";
        }

        span {
          display: none;
          @include _desktop {
            position: relative;
            display: inline-block;
            left: toRem(25);
            top: toRem(-1);
            width: toRem(8);
            height: toRem(12);
            background-image: url("../../assets/images/icons/additional.svg");
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }

      &-mobile {
        font: 500 toRemMob(20)/toRemMob(23) "Roboto";
        margin-bottom: toRemMob(18);
        @include _desktop {
          display: none;
        }
      }
    }
  }

  &__description {
    &_additional {
      font: 300 toRemMob(16)/toRemMob(24) "Roboto";
      color: $color-grey-8;
      @include _desktop {
        display: none;
      }
    }
  }

  &__navigation {
    &_additional {
      display: none;

      @include _desktop {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: toRem(5);
        bottom: 0;
        margin: auto;
        width: 100%;
      }
    }
  }

  &__arrow {
    &_additional {
      @include _desktop {
        position: relative;
        z-index: 1;
        width: toRem(59);
        height: toRem(81);
        cursor: pointer;
        background-image: url("../../assets/images/icons/arrow_additional.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      &.swiper-button-disabled {
        user-select: none;
        cursor: default;
        opacity: 0;
      }

      &-prev {
        @include _desktop {
          left: toRem(37);
          transform: scale(-1, 1);
        }
      }

      &-next {
        right: toRem(37);
      }
    }
  }
}
</style>




