<template>
  <div v-if="isUserAuth" class="admin__container">
    <div @click="adminMenu" class="admin__panel">
      <img v-if="user.avatar" :src="$serverHost + user.avatar" alt="">
      <img v-else :src="$serverHost + 'media/avatars/default.jpg'" alt="">
      <a
        class="admin__point admin__come_in"
        title="Перейти в админку"
        href="/admin"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="-5 -5 30 30">
          <path
            d="M2.75 14.688V13.354H13.188V14.688ZM2.75 10.667V9.333H10.708V10.667ZM2.75 6.646V5.312H13.188V6.646ZM16.312 13.896 12.417 10 16.312 6.104 17.25 7.042 14.292 10 17.25 12.958Z"/>
        </svg>
      </a>
      <a
        class="admin__point admin__exit"
        title="Выйти"
        @click="userLogout"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="-5 -5 30 30">
          <path
            d="M7.625 9.542H14.417L12.396 7.5L13.333 6.562L17 10.229L13.333 13.896L12.396 12.958L14.479 10.875H7.625ZM10.083 2.917V4.25H4.583Q4.5 4.25 4.417 4.333Q4.333 4.417 4.333 4.5V15.917Q4.333 16 4.417 16.083Q4.5 16.167 4.583 16.167H10.083V17.5H4.583Q3.917 17.5 3.458 17.042Q3 16.583 3 15.917V4.5Q3 3.833 3.458 3.375Q3.917 2.917 4.583 2.917Z"/>
        </svg>
      </a>
    </div>
  </div>

</template>

<script>
import {AxiosUsers} from "../../../admin-vue/src/http-common";

export default {
  name: "AdminButton",
  components: {},
  data() {
    return {
      user: {},
      activeMenu: false,
      isUserAuth: false
    }
  },
  mounted() {
    this.getProfileInfo()
  },
  methods: {
    async getProfileInfo() {
      try {
        const response = await AxiosUsers.get('login-check/')
        if (response.status >= 200 && response.status < 300) {
          this.isUserAuth = true
          this.user = response.data.user
        }
      } catch (error) {
        this.isUserAuth = false
        console.error(error)
      }
    },
    adminMenu() {
      this.activeMenu = !this.activeMenu
      const elemExit = document.querySelector('.admin__exit')
      const elemAdmin = document.querySelector('.admin__come_in')

      if (this.activeMenu) {
        elemExit.style.display = 'block';
        elemAdmin.style.display = 'block';

        setTimeout(() => {
          elemExit.style.transform = 'translate(54px, -49px)';
          elemAdmin.style.transform = 'translate(20px, -93px)';
          elemExit.style.opacity = 0.6;
          elemAdmin.style.opacity = 0.6;
        }, 200);
      } else {
        elemExit.style.transform = 'translate(24px, -45px)';
        elemAdmin.style.transform = 'translate(10px, -40px)';
        elemExit.style.opacity = 0;
        elemAdmin.style.opacity = 0;

        setTimeout(() => {
          elemExit.style.display = 'none';
          elemAdmin.style.display = 'none';
        }, 200);
      }
    },
    async userLogout() {
      try {
        await AxiosUsers.get('logout/');
        this.$emit('is-user-auth', false)
        this.isUserAuth = false
      } catch (error) {
        alert('Что-то пошло не так')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.admin__container {
  position: fixed;
  left: 30px;
  bottom: 80px;
  opacity: 0.6;
  z-index: 15;

  &:hover {
    opacity: 1;
  }
}

.admin__panel {
  position: relative;
  display: none;
  @include _desktop {
    display: block;
    position: absolute;
    cursor: pointer;
    padding: 4px 6px;
    border-radius: 15px;
    background: linear-gradient(to top left, #54e881 0%, #adfcad 100%);
  }

  img {
    position: relative;
    top: 2px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: contain;
  }

  .admin {
    &__point {
      position: absolute;
      display: none;
      opacity: 1;
      transition: all .3s ease-in;

      svg {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(to top left, #54e881 0%, #adfcad 100%);
        border-radius: 50%;
      }
    }

    &__come_in {
      opacity: 0;
      transform: translate(20px, -45px);
      transition: all .3s ease-out;
    }

    &__exit {
      opacity: 0;
      transform: translate(15px, -46px);
      transition: all .3s ease-out;
    }
  }
}

</style>