<template>
  <footer class="footer">
    <div class="container">
<!--      <div class="footer__columns footer__columns_top">-->
<!--        <div class="footer__column footer__column_top">-->
<!--          <h2 class="footer__title footer__title_underline">{{ wordTranslation("footer.catalog_title") }}</h2>-->
<!--          <div class="footer__list footer__list_menu">-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.catalog_item_1") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.catalog_item_2") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.catalog_item_3") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.catalog_item_4") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.catalog_item_5") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.catalog_item_6") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.catalog_item_7") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.catalog_item_8") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.catalog_item_9") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.catalog_item_10") }}</a>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="footer__column footer__column_top">-->
<!--          <h2 class="footer__title footer__title_underline">{{ wordTranslation("footer.help_title") }}</h2>-->
<!--          <div class="footer__list footer__list_menu">-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.help_item_1") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.help_item_2") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.help_item_3") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.help_item_4") }}</a>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="footer__column footer__column_top">-->
<!--          <h2 class="footer__title footer__title_underline">{{ wordTranslation("footer.company_title") }}</h2>-->
<!--          <div class="footer__list footer__list_menu">-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.company_item_1") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.company_item_2") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.company_item_3") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.company_item_4") }}</a>-->
<!--            <a href="/" class="item item_menu">{{ wordTranslation("footer.company_item_5") }}</a>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="footer__column footer__column_top">-->
<!--          <h2 class="footer__title">{{ wordTranslation("footer.social_title") }}</h2>-->
<!--          <div class="footer__list  footer__list_social">-->
<!--            <a href="/" class="item item_social item_social-instagram"></a>-->
<!--          </div>-->
<!--          <p class="footer__description">-->
<!--            {{ wordTranslation("footer.info_text") }} <a-->
<!--            :href="`tel:${wordTranslation('footer.info_phone').replace(/\D/g ,'')}`">{{ wordTranslation("footer.info_phone") }}</a>-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="footer__columns footer__columns_center">-->
<!--        <div class="footer__column">-->
<!--          <h2 class="footer__title">{{ wordTranslation("footer.patents_title") }}</h2>-->
<!--          <div class="footer__wrapper footer__wrapper_patents">-->
<!--            <div class="footer__list footer__list_patents">-->
<!--              <div class="item item_patents">-->
<!--                <div class="item__icon item__icon_patents"></div>-->
<!--                <div class="item__description item__description_patents">{{ wordTranslation("footer.patent_item_1") }}</div>-->
<!--              </div>-->
<!--              <div class="item item_patents">-->
<!--                <div class="item__icon item__icon_patents"></div>-->
<!--                <div class="item__description item__description_patents">{{ wordTranslation("footer.patent_item_2") }}</div>-->
<!--              </div>-->
<!--              <div class="item item_patents">-->
<!--                <div class="item__icon item__icon_patents"></div>-->
<!--                <div class="item__description item__description_patents">{{ wordTranslation("footer.patent_item_3") }}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <a href="/" class="footer__link footer__link_more">{{ wordTranslation("footer.patent_see_more") }}</a>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="footer__column">-->
<!--          <h2 class="footer__title">{{ wordTranslation("footer.our_partners_title") }}</h2>-->
<!--          <div class="footer__list footer__list_partners">-->
<!--            <div class="item item_partners">-->
<!--              <img class="image image_contain" src="@/assets/images/footer/severstal.png"-->
<!--                   :alt="wordTranslation('seo.image_north_steel_alt')">-->
<!--            </div>-->
<!--            <div v-for="(partner, index) in partners" :key="partner.image"-->
<!--                 class="item item_partners">-->
<!--              <img-->
<!--                @click="galleryInit(partners.map(partnerMap => partnerMap.desktopImage), index, null)"-->
<!--                :src="partner.image"-->
<!--                :alt="partner.alt"-->
<!--                class="image image_contain"-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="footer__column">-->
<!--          <h2 class="footer__title">{{ wordTranslation("footer.greenhouses_from_polycarbonate") }}</h2>-->
<!--          <div class="footer__list footer__list_sites">-->
<!--            <a href="/" class="item item_sites">-->
<!--              <img class="image image_contain" src="@/assets/images/footer/site.png"-->
<!--                   :alt="wordTranslation('seo.image_zgt_alt')">-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="footer__columns footer__columns_bottom">
        <p class="footer__copyright">
          {{ wordTranslation("footer.copyright") }}
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "v-footer",
  props: {
    wordTranslation: Function,
    galleryInit: Function,
  },
  data() {
    return {
      partners: [
        {
          image: require("@/assets/images/footer/severstal-gratitude-1.png"),
          desktopImage: require("@/assets/images/footer/severstal-gratitude-1.png"),
          alt: "Благодарность от северстали"
        },
        {
          image: require("@/assets/images/footer/severstal-gratitude-2.png"),
          desktopImage: require("@/assets/images/footer/severstal-gratitude-2.png"),
          alt: "Благодарность от северстали"
        }

      ]
    };
  },
  methods: {
    getPSWPImages(images) {
      images.map(image => {
        return window.innerWidth > 640 ? image.mobileImage : image.desktopImage;
      });
    }
  }
};
</script>

<style lang="scss">
.footer {
  padding: toRemMob(30) 0;
  background-color: $color-grey-10;
  @include _desktop {
    //padding: toRem(71) 0 toRem(157);
    padding: toRem(71) 0 toRem(71);
  }


  &__columns {
    display: flex;

    @include _desktop {
      padding: 0 toRem(2);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &_top {
      justify-content: space-between;
      flex-direction: column;

      @include _desktop {
        margin-bottom: toRem(68);
        flex-direction: row;
      }

      > div {
        width: 100%;
        @include _desktop {
          margin-left: toRem(145);
          width: toRem(255);
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          @include _desktop {
            padding-left: toRem(44);
            width: toRem(243);
          }
        }
      }
    }

    &_center {
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: toRemMob(42);
      @include _desktop {
        margin-bottom: toRem(12);
        flex-direction: row;
      }

      > div {

        @include _desktop {
          width: toRem(300);
        }

        &:nth-child(1) {
          @include _desktop {
            width: toRem(684);
          }
        }

        &:nth-child(2) {
          @include _desktop {
            width: toRem(287);
          }
        }

        &:nth-child(3) {
          @include _desktop {
            width: toRem(240);
          }
        }
      }
    }
  }

  &__column {
    margin-bottom: toRemMob(30);

    &_top {
      order: 2;

      @include _desktop {
        order: unset;
        margin-bottom: unset;
      }

      &:last-child {
        order: 1;
        @include _desktop {
          order: unset;
        }
      }
    }
  }

  &__wrapper {
    &_patents {
      display: flex;
      margin-top: toRemMob(21);
      flex-direction: column;
      @include _desktop {
        flex-direction: row;
        align-items: center;
        margin-top: toRem(21);
      }
    }
  }

  &__list {
    &_menu {
      display: flex;
      flex-direction: column;
      margin-top: toRemMob(14);
      @include _desktop {
        margin-top: toRem(14);
      }
    }

    &_social {
      display: flex;
      align-items: center;
      margin-top: toRemMob(18);
      @include _desktop {
        margin-top: toRem(9);
      }
    }

    &_patents {
      display: flex;
      flex-direction: column;
      @include _desktop {
        flex-direction: row;
      }
    }

    &_partners {
      display: flex;
      align-items: center;
      margin-top: toRemMob(12);
      cursor: pointer;
      @include _desktop {
        margin-top: toRem(12);
      }
    }

    &_sites {
      display: flex;
      align-items: center;
      margin-top: toRemMob(19);
      @include _desktop {
        margin-top: toRem(19);
      }
    }
  }

  &__title {
    font: 700 toRemMob(18)/toRemMob(21) "Roboto";
    color: $color-white;
    @include _desktop {
      font: 700 toRem(18)/toRem(21) "Roboto";
    }

    &_underline {
      padding-bottom: toRemMob(6);
      border-bottom: toRemMob(1) solid $color-grey-11;
      @include _desktop {
        padding-bottom: toRem(6);
        border-bottom: toRem(1) solid $color-grey-11;
      }
    }
  }

  &__description {
    margin-top: toRemMob(11);
    color: $color-grey-12;
    font: 400 toRemMob(16)/toRemMob(32) "Roboto";
    @include _desktop {
      margin-top: toRem(11);
      font: 400 toRem(16)/toRem(32) "Roboto";
    }

    a {
      display: block;
      transition: all .2s ease;
      @include _desktop {
        display: inline-block;
      }

      &:hover {
        color: $color-white;
      }
    }
  }

  &__link {
    transition: all .2s ease;
    font: 400 toRemMob(16)/toRemMob(19) "Roboto";
    color: $color-grey-12;
    text-decoration: underline;
    @include _desktop {
      font: 400 toRem(16)/toRem(19) "Roboto";
      margin-left: toRem(17);
    }

    &:hover {
      color: $color-white;
    }
  }

  &__copyright {
    font: 400 toRemMob(13)/toRemMob(15) "Roboto";
    color: $color-white;
    @include _desktop {
      font: 400 toRem(13)/toRem(15) "Roboto";
    }
  }
}

.item {
  &_menu {
    margin-bottom: toRemMob(18);
    font: 400 toRemMob(16)/toRemMob(19) "Roboto";
    color: $color-grey-12;
    transition: all .2s ease;

    &:last-child {
      margin-bottom: 0;
    }

    @include _desktop {
      margin-bottom: toRem(13);
      font: 400 toRem(16)/toRem(19) "Roboto";
    }

    &:hover {
      color: $color-white;
    }
  }

  &_social {
    width: toRemMob(35);
    height: toRemMob(35);
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: toRemMob(13);
    filter: brightness(0.7);
    transition: all .2s ease;
    @include _desktop {
      width: toRem(27);
      height: toRem(27);
      margin-left: toRem(13);
    }

    &:first-child {
      margin-left: 0;
    }

    &-instagram {
      background-image: url("../assets/images/footer/instagram.svg");
    }

    &:hover {
      filter: brightness(1);
    }
  }

  &_patents {
    display: flex;
    align-items: center;
    margin-bottom: toRemMob(20);
    color: $color-grey-12;
    transition: all .2s ease;
    cursor: pointer;

    @include _desktop {
      margin-left: toRem(19);
      margin-bottom: unset;
    }

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: $color-white;
    }
  }

  &_partners {
    margin-right: toRemMob(10);
    width: toRemMob(48);
    height: toRemMob(32);
    border-radius: 3px;
    overflow: hidden;
    @include _desktop {
      margin-right: toRem(10);
      width: toRem(48);
      height: toRem(32);
      border-radius: 3px;
    }

    &:first-child {
      margin-right: toRemMob(22);
      width: toRemMob(128);
      height: toRemMob(40);
      @include _desktop {
        margin-right: toRem(22);
        width: toRem(128);
        height: toRem(40);
      }
    }
  }

  &_sites {
    width: toRemMob(126);
    height: toRemMob(43);
    margin-left: toRemMob(10);
    cursor: pointer;


    &:first-child {
      margin-left: 0;
    }

    @include _desktop {
      width: toRem(126);
      height: toRem(43);
      margin-left: toRem(10);
    }

    img {
      transition: all .2s ease;
    }

    &:hover {
      img {
        filter: brightness(2);
        transition: all .2s ease;
      }
    }
  }

  &__icon {
    &_patents {
      width: toRemMob(20);
      height: toRemMob(27);
      margin-right: toRemMob(7);
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../assets/images/footer/patents.svg");
      @include _desktop {
        width: toRem(20);
        height: toRem(27);
        margin-right: toRem(7);
      }
    }
  }

  &__description {
    &_patents {
      transition: all .2s ease;
      font: 400 toRemMob(16)/toRemMob(19) "Roboto";
      @include _desktop {
        font: 400 toRem(16)/toRem(19) "Roboto";
      }
    }
  }
}
</style>
