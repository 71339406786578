<template>
  <vue-tel-input
    v-model="phone"
    mode="international"
    defaultCountry="ru"
    :inputOptions="{placeholder: wordTranslation('preorder_modal.phone_placeholder'), styleClasses:'input input_modal input_modal-phone'}"
    :dropdownOptions="{showDialCodeInSelection: true, showFlags: true,showDialCodeInList: true}"
    :preferredCountries="['ru','us']"
    @on-input="enter"/>
</template>

<script>
import {VueTelInput} from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

export default {
  emits: {"update:phone": null, "input": null},
  props: {
    wordTranslation: Function
  },
  components: {
    VueTelInput,
  },
  data() {
    return {
      phone: "",
    };
  },
  methods: {
    enter(number, phoneObject) {
      this.phone = this.phone.replace(/\D/gi, "");
      if (phoneObject.valid) {
        this.phone = phoneObject.formatted.substr(phoneObject.countryCallingCode.length + 1);
      }
      this.$emit("update:phone", {phone: this.phone, phoneObject});
      this.$emit("input");
    },
    clearPhone() {
      this.phone = "";
    }
  },
};
</script>

<style lang="scss">

.vue-tel-input {
  border-radius: 0 !important;
  display: flex !important;
  border: 0 !important;
  text-align: left !important;
  position: relative;
  background: $color-white;
  border-bottom: toRemMob(1) solid $color-grey-22 !important;
  font-weight: 300;
  font-size: toRemMob(16);
  line-height: toRemMob(19);
  width: 100%;
  padding: toRemMob(6) toRemMob(10) toRemMob(4) toRemMob(3);
  box-shadow: none !important;
  transition: all .2s ease;

  @include _desktop {
    font-size: toRem(16);
    line-height: toRem(19);
    padding: toRem(6) toRem(10) toRem(4) toRem(3);
    text-align: left;
    border-bottom: toRem(1) solid $color-grey-22 !important;
    box-shadow: none;
  }
}

.input_modal-phone {
  position: relative;
  background: $color-white;
  font-weight: 300;
  font-size: toRemMob(16);
  line-height: toRemMob(19);
  padding: 0;
  width: 100%;
  text-align: left;

  @include _desktop {
    font-size: toRem(16);
    line-height: toRem(19);
  }
}

.vti {

  &__dropdown-arrow {
    margin-left: toRemMob(2);
    transform: scaleY(0.45) !important;
    @include _desktop {
      margin-left: toRem(2);
    }
  }

  &__dropdown {
    padding: 0 !important;
    margin-right: toRemMob(8);
    font-size: toRemMob(16);
    line-height: toRemMob(19);
    @include _desktop {
      margin-right: toRem(8);
      font-size: toRem(16);
      line-height: toRem(19);
    }

    &.disabled {
      background-color: transparent !important;
    }

    &.open {
      background-color: transparent !important;
    }

    &:hover {
      background-color: transparent !important;
    }

  }

  &__selection {
    font-size: toRemMob(16) !important;
    @include _desktop {
      font-size: toRem(16) !important;
    }
  }

  &__flag {
    width: toRemMob(21) !important;
    height: toRemMob(14) !important;
    margin: 0 toRemMob(4) 0 toRemMob(1) !important;
    @include _desktop {
      width: toRem(20) !important;
      height: toRem(11) !important;
      margin: 0 toRem(4) 0 toRem(1) !important;
    }
  }

  &__country-code {
    font-weight: 400;
    font-size: toRemMob(16);
    line-height: toRemMob(19);
    color: $color-black !important;

    @include _desktop {
      font-size: toRem(16);
      line-height: toRem(19);
    }
  }

  &__dropdown-list {
    width: toRemMob(374) !important;
    max-height: toRemMob(200) !important;
    left: toRemMob(-3);
    @include _desktop {
      max-height: toRem(185) !important;
      width: toRem(431) !important;
      left: toRem(-3) !important;
    }

    &.below {
      top: toRemMob(23) !important;
      @include _desktop {
        top: toRem(24) !important;
      }
    }
  }

  &__dropdown-item {
    padding: toRemMob(8) !important;
    font-size: toRemMob(16) !important;
    line-height: toRemMob(16) !important;
    transition: all .2s ease;
    @include _desktop {
      padding: toRem(7) !important;
      font-size: toRem(16) !important;
      line-height: toRem(16) !important;
    }
  }

  span {
    font-weight: 700;
  }
}


</style>