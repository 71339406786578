<template>
  <modal-base ref="modalBase" :wordTranslation="wordTranslation">
    <template #modal-error></template>
    <template #modal-success>
      <div class="modal__success">
        <div class="modal__image modal__image_success"></div>
        <h2 class="modal__title modal__title_success">{{ wordTranslation("base_modal.hooray") }}</h2>
        <p class="modal__description modal__description_success modal__description_success-presentation">
          {{ wordTranslation("download_presentation_modal.register_info") }}
        </p>
      </div>
    </template>
    <template #modal-title>
      {{ wordTranslation("download_presentation_modal.title") }}
    </template>
    <template #modal-content>
      <div class="modal__list">
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            {{ wordTranslation("download_presentation_modal.your_name") }}
            <span>*</span>
          </div>
          <input
            @input="fioError = ''"
            :placeholder="wordTranslation('download_presentation_modal.your_name_placeholder')"
            class="input input_modal input_modal-name"
            v-model="fio"
            type="text"
            name="name"
          >
          <div class="error__placeholder" v-show="fioError.length">
            {{ fioError }}
          </div>
        </div>
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            {{ wordTranslation("download_presentation_modal.phone_input") }}
            <span>*</span>
          </div>
          <modalWindowMask
            @update:phone="phone = $event.phone, phoneObject = $event.phoneObject"
            @input="phoneError = ''"
            :phone="phone"
            :word-translation="wordTranslation"
            ref="modalWindowMask"
          />
          <!--          <input class="input input_modal input_modal-phone" type="tel" name="phone" v-model="phone" placeholder="Введите номер телефона">-->
          <div class="error__placeholder" v-show="phoneError.length">
            {{ phoneError }}
          </div>
        </div>
        <div class="item item_modal">
          <div class="item__title item__title_modal">
            {{ wordTranslation("download_presentation_modal.presentation_input") }}
            <span>*</span>
          </div>
          <v-select
            v-model="currentPresentation"
            :options="presentationsList"
            :clearable="false"
            :filterable="false"
            :searchable="false"
          >
            <template #option="option">
              <div class="presentation-select__item">
                <span class="presentation-select__title">
                  {{ option.label }}
                </span>
              </div>
            </template>
            <template #selected-option="selectedOption">
              <div class="presentation-select__item">
                <span class="presentation-select__title">
              {{ selectedOption.label }}
                </span>
              </div>
            </template>
          </v-select>
        </div>
      </div>
    </template>
    <template #modal-confirm>
      <button class="button button_modal button_modal-presentation button_green" @click="modalValidation">
        {{ wordTranslation("buttons.download") }}
      </button>
      <p class="modal__politic">
        {{ wordTranslation("download_presentation_modal.politic_text") }}<br>
        <a class="link link_blue link_modal" download="user_agreement.pdf" href="/user_agreement.pdf">
          {{ wordTranslation("base_modal.personal_data") }}
        </a>
      </p>
    </template>
  </modal-base>
</template>

<script>

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import ModalBase from "@/components/modal/ModalBase";

import modalWindowMask from "@/components/modal/ModalWindowMask";

export default {
  name: "ModalPresentation",
  props: {
    wordTranslation: Function
  },
  components: {
    ModalBase,
    modalWindowMask,
    vSelect,
  },
  data() {
    return {
      fio: "",
      fioError: "",
      phone: "",
      phoneError: "",
      currentPresentation: {
        label: "«Green Diamond» Model 1",
        url: "media/download_presentation/Green Diamond_Мodel 1.pdf"
      },
      phoneObject: {},
      presentationsList: [
        {label: "«Green Diamond» Model 1", url: "media/download_presentation/Green Diamond_Мodel 1.pdf"},
      ]
    };
  },
  methods: {
    open() {
      return this.$refs.modalBase.open();
    },
    close() {
      return this.$refs.modalBase.close();
    },
    regexValidation(re, str) {
      return re.test(str);
    },
    nameValidation() {
      if (!this.regexValidation(/^[\w \-А-я]{1,128}$/, this.fio)) {
        this.fioError = this.wordTranslation("base_modal.fio_error");
        return false;
      }
      this.fioError = "";
      return true;
    },
    phoneValidation() {
      if (!this.phoneObject.valid) {
        this.phoneError = this.wordTranslation("base_modal.phone_error");
        return false;
      }
      this.phoneError = "";
      return true;
    },
    modalValidation() {
      this.nameValidation();
      this.phoneValidation();

      if (!this.phoneError.length && !this.fioError.length) {

        this.$metrika.reachGoal("signUpPresentation");
        return this.$refs.modalBase.confirm({
          fio: this.fio,
          phone: this.phoneObject.formatted,
          file: this.currentPresentation
        });
      }
    },
    async showError() {
      return new Promise(resolve => {
        this.$refs.modalBase.showError();
        setTimeout(() => {
          this.$refs.modalBase.hideError();
          resolve();
        }, 3000);
      });
    },
    async showSuccess() {
      return new Promise(resolve => {
        this.$refs.modalBase.showSuccess();
        setTimeout(() => {
          this.fio = "";
          this.phone = "";
          this.$refs.modalWindowMask.clearPhone();
          this.$refs.modalBase.hideSuccess();
          this.$refs.modalBase.close();
          resolve();
        }, 3000);
      });
    },
    showPreloader() {
      return this.$refs.modalBase.showPreloader();
    },
    hidePreloader() {
      return this.$refs.modalBase.hidePreloader();
    }
  }
};
</script>

<style lang="scss">


.button {
  &_modal-presentation {
    padding: toRemMob(16) toRemMob(32);
    @include _desktop {
      padding: toRem(16) toRem(32);
    }
  }
}

.modal {
  &_presentation {
    .modal__header {
      @include _desktop {
        margin-bottom: toRem(50);
      }
    }

    .modal__title {
      @include _desktop {
        font-size: toRem(28);
        line-height: toRem(33);
      }
    }

    .modal__body {
      @include _desktop {
        min-width: toRem(429);
        max-width: toRem(429);
      }
    }

    .modal__politic {
      @include _desktop {
        margin-top: toRem(101);
      }
    }

    .modal__description_error {
      margin: 0 toRemMob(70);
      @include _desktop {
        margin: 0 toRem(77);
      }
    }
  }
}

.vs__dropdown-toggle {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $color-grey-21;
}

.vs__selected, .vs__selected-options, .vs__search {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.vs__selected {
  position: inherit !important;
}

.vs__dropdown-option, .vs__selected {
  font-weight: 300;
  font-size: toRemMob(16);
  line-height: toRemMob(16);
  @include _desktop {
    font-size: toRem(16);
    line-height: toRem(19);
  }
}

:root {
  --vs-dropdown-option--active-bg: #{$color-grey-23};
}
</style>

<style scoped lang="scss">

// Reset styles
.presentation-select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  border: none;
  outline: none;
  padding-bottom: 10px;
}

.presentation-select {
  width: 100%;
  border-bottom: 1px solid $color-grey-22;
  cursor: pointer;
  background-color: transparent;

  &::-ms-expand {
    display: none;
  }

  &__item {
  }

  &__title {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      right: -30px;
      width: toRemMob(21);
      height: toRemMob(19);
      background: url("../../assets/images/icons/pdf_modal.svg") no-repeat center right;
      background-size: contain;
      @include _desktop {
        width: toRem(21);
        height: toRem(19);
      }

    }
  }
}


</style>