<template>
  <router-view
    @init-change-seo="initChangeSeo"
    v-if="!$store.getters.isNotFound"
    :get-translate-field="getTranslateField"
    :word-translation="wordTranslation"
    :get-translate-json-field="getTranslateJsonField"
    :gallery-init="galleryInit"
    :get-min-variant-execution-price="getMinVariantExecutionPrice"
    :open-sign-up-on-presentation="openSignUpOnPresentation"
    :open-cart-modal="openCartModal"
    :sample-sales="sampleSales"
    ref="routePage"
    :another-countries="anotherCountries"
    :open-download-presentation="openDownloadPresentation"
    :open-modal-create-comment="openModalCreateComment"
    :get-comment-title="getCommentTitle"
  />
  <not-found v-else :word-translation="wordTranslation" :to-home-page="toHomePage"/>
</template>

<script>
import NotFound from "@/components/NotFound";

export default {
  name: "LocalPages",

  emits: {
    "init-change-seo": null,
  },
  props: {
    getTranslateField: Function,
    wordTranslation: Function,
    toHomePage: Function,
    getTranslateJsonField: Function,
    galleryInit: Function,
    getMinVariantExecutionPrice: Function,
    openSignUpOnPresentation: Function,
    openCartModal: Function,
    sampleSales: Array,
    anotherCountries: Array,
    openDownloadPresentation: Function,
    openModalCreateComment: Function,
    getCommentTitle: Function,
  },
  components: {
    NotFound,
  },
  methods: {
    initChangeSeo(changeSeo) {
      this.$emit("init-change-seo", changeSeo);
    },

  }
};
</script>