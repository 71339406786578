<template>
  <section class="section section-contacts">
    <div class="container">
      <h2 class="section__title section-contacts__title">
        {{ wordTranslation("contacts_block.title") }}
      </h2>
    </div>
    <div class="container-map">
      <div ref="mapWrapper" class="map__wrapper" :class="{'show-preloader': !isShowMap}">
        <yandex-map
          v-if="isShowMap"
          @map-was-initialized="initedMap"
          @boundschange="centerMapAfterChangeCity"
          :settings="$options.yandexMapSettings"
          :coords="currentCityCoords"
          :zoom="$options.yandexMapZoom"
          :controls="$options.yandexMapControls"
          :detailed-controls="{zoomControl: $options.yandexMapZoomControl}"
          :options="{suppressMapOpenBlock: true}"
        >
          <ymap-marker
            @balloonopen="toggleCityInfoZindex"
            @balloonclose="toggleCityInfoZindex"
            :marker-id="1"
            :coords="currentCityCoords"
            :hint-content="currentCity.address"
            :balloon-template="`<b>Адрес:</b> <br/> ${currentCity.address}`"
            :icon="$options.yandexMarkerIcon"
          >
          </ymap-marker>

        </yandex-map>
      </div>
      <div class="container">
        <div ref="cityInfo" class="section-contacts__info city-info">
          <div class="city-info__wrapper">
            <p class="city-info__city-title">
              {{ `${wordTranslation("contacts_block.showroom")} ${wordTranslation("contacts_block.place_prefix")}` }}
              {{ currentCity.title }}
            </p>
            <div class="city-info__content city-info__address city-address">
              <p class="city-info__title city-address__title">{{ wordTranslation("contacts_block.address") }}:</p>
              <p class="city-address__address">{{ currentCity.address }}</p>
            </div>
            <div class="city-info__content city-info__phone city-phones">
              <p class="city-info__title city-phones__title">{{ wordTranslation("contacts_block.phone") }}:</p>
              <ul class="city-phones__phones">
                <li
                  v-for="phone in currentCityPhonesArray"
                  :key="phone"
                  class="city-phone__phone"
                >
                  <a class="city-phone__link" :href="`tel:${cleanPhone(phone)}`">{{ phone }}</a>
                </li>
              </ul>
            </div>
            <div class="city-info__content city-info__time-work city-time-work">
              <p class="city-info__title city-time-work__title">{{ wordTranslation("contacts_block.time_work") }}:</p>
              <ul class="city-time-work__times">
                <li
                  v-for="timeWork in getCityTimeWork(currentCity.time_work)"
                  :key="timeWork"
                  class="city-time-work__time"
                >
                  {{ timeWork }}
                </li>
              </ul>
            </div>
            <button @click="openCallbackModal" class="button city-info__button">{{ wordTranslation("buttons.callback") }}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import {yandexMap, ymapMarker} from "vue-yandex-maps";

export default {
  yandexMapSettings: {
    apiKey: "2f91950e-6e84-4c20-a2fd-432643d65981",
    lang: "ru_RU",
    coordorder: "latlong",
    enterprise: false,
    version: "2.1"
  },
  yandexMapZoom: 17,
  yandexMapControls: [],
  yandexMarkerIcon: {
    color: "red",
  },
  yandexMapZoomControl: {
    position: {
      right: 10,
      top: 70
    }
  },
  name: "ContactsBlock",
  props: {
    wordTranslation: Function,
    openSignUpOnPresentation: Function,
    openCallbackModal: Function,
  },
  inject: ["deviceWidth"],
  data() {
    return {
      yandexMap: null,
      isShowMap: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.showMap);
  },
  mounted() {
    // Оно вызывается до того, как html нормально построен. и высоты у объектов нет никакой. Поэтому
    // Ждем ~ пол секунды, что бы все построилось и уже тогда проверяем.
    setTimeout(() => {
      this.showMap();
    }, 500);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.showMap);
  },
  components: {yandexMap, ymapMarker},
  computed: {
    ...mapGetters([
      "currentCity",
    ]),
    currentCityPhonesArray() {
      return this.currentCity.phones.split(",");
    },
    currentCityCoords() {
      return this.currentCity.coords.split(",");
    }
  },
  methods: {
    showMap() {
      if (!this.isShowMap && this.isScrolledIntoView(this.$refs.mapWrapper)) {
        this.isShowMap = true;
      }
    },
    isScrolledIntoView(el) {
      const rect = el.getBoundingClientRect();
      const elementTop = rect.top;
      const elementBottom = rect.bottom;
      return elementTop < window.innerHeight && elementBottom >= 0;
    },
    getCityTimeWork(timeWork) {
      // TODO: Maybe create translate
      const result = [];
      //  ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];
      const weekDays = this.wordTranslation("contacts_block.week_days").split(',')
      let unUsedWorkDays = weekDays;
      timeWork.forEach(timeRange => {
        let temp = "";
        if (timeRange.day_start === timeRange.day_end) {
          temp = `${weekDays[timeRange.day_start]}: `;
        } else if (timeRange.day_start + 1 === timeRange.day_end) {
          temp = `${weekDays[timeRange.day_start]}, ${weekDays[timeRange.day_end]}: `;
        } else {
          temp = `${weekDays[timeRange.day_start]}-${weekDays[timeRange.day_end]}: `;
        }
        temp += `${timeRange.time_start} - ${timeRange.time_end}`;
        result.push(temp);
        // Если значение в массиве неиспользованных дней используется в текущей итерации, то вместо него пишем null
        unUsedWorkDays = unUsedWorkDays.map((value, index) => {
          // Если index дня находиться в промежутке тех дней, что находятся в timeRange
          return (index >= timeRange.day_start && index <= timeRange.day_end) ? null : value;
        });
      });
      // Чистим от неиспользованных
      unUsedWorkDays = unUsedWorkDays.filter(value => value);
      if (unUsedWorkDays.length) {
        result.push(`${unUsedWorkDays.join(", ")}: ${this.wordTranslation("contacts_block.weekend")}`);
      }
      return result;
    },
    initedMap(yandexMap) {
      this.yandexMap = yandexMap;
      this.disableBehaviors();
      this.setCenter();
    },
    disableBehaviors() {
      this.yandexMap.behaviors.disable("scrollZoom");
    },
    centerMapAfterChangeCity(event) {
      // Берем координаты нового центра, "округляем", так же с координатами города
      const newCenterCoords = event.get("newCenter").map(coordinate => {
        return Math.trunc(coordinate * 1000) / 1000;
      });
      const cityCoords = this.currentCity.coords.split(",").map(coordinate => {
        return Math.trunc(coordinate * 1000) / 1000;
      });
      // Если они равны, то "центруем" (на самом деле сдвигаем)
      const isMoved = cityCoords.every((coordinate, index) => {
        return coordinate === newCenterCoords[index];
      });
      if (isMoved) {
        this.setCenter();
      }
    },
    setCenter() {
      if (this.deviceWidth < 640) return;
      const pixelCenter = this.yandexMap.getGlobalPixelCenter(this.currentCity.coords);
      pixelCenter[0] = pixelCenter[0] + 300;
      const geoCenter = this.yandexMap.options.get("projection").fromGlobalPixels(pixelCenter, this.yandexMap.getZoom());
      this.yandexMap.setCenter(geoCenter);
      // Гений костылей в деле. Эта залупа не перерисовывлась, поэтому пришлось такие костыли писать)))))
      document.querySelector(".ymap-container").style.paddingRight = "1px";
      setTimeout(() => {
        document.querySelector(".ymap-container").style.paddingRight = "0px";
      }, 300);
    },
    cleanPhone(rawPhone) {
      return rawPhone.replace(/\D/g, "");
    },
    toggleCityInfoZindex() {
      // Костыль. на ширине < 400 у карты balloon открывается снизу. и эта сраная плашка с адресом ее перекрывает.
      if (this.deviceWidth <= 400) {
        const zIndex = this.$refs.cityInfo.style.zIndex;
        if (zIndex !== "-1") {
          this.$refs.cityInfo.style.zIndex = "-1";
        } else {
          this.$refs.cityInfo.style.zIndex = "unset";
        }
      }
    },
  },

};
</script>

<style scoped lang="scss">


.section-contacts {
  padding-bottom: toRemMob(60);
  @include _desktop {
    padding-bottom: 0;
  }

  &__title {
    margin: 0 toRemMob(48) toRemMob(30) toRemMob(42);
    font-size: toRemMob(28);
    line-height: toRemMob(33);
    font-weight: 500;
    padding: toRemMob(30) 0 0 0;

    @include _desktop {
      margin-bottom: toRem(60);
      margin-left: 0;
      padding: toRem(60) 0 0 0;
      font-size: toRem(45);
      line-height: toRem(53);
      font-weight: 300;
    }
  }
}

.map {
  &__wrapper {
    position: relative;
    @include _desktop {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
    }

    &.show-preloader {
      background: url("../assets/images/map/map-load.png") no-repeat center center;
      background-size: contain;
    }
  }
}

.city-info {
  @include _desktop {
    padding: toRemMob(92) 0;
  }

  &__wrapper {
    width: toRemMob(352);
    margin: toRemMob(-87) auto 0 auto;
    border-radius: toRemMob(10);
    padding: toRemMob(31) toRemMob(27) toRemMob(60) toRemMob(27);
    background: rgba(250, 250, 250, 0.85);
    backdrop-filter: blur(17.7115px);
    font-weight: 300;
    font-size: toRemMob(16);
    line-height: toRemMob(24);

    @include _desktop {
      position: relative;
      z-index: 1;
      margin: 0 0 0 auto;
      height: max-content;
      border-radius: toRem(10);
      width: toRem(529);
      padding: toRem(44) toRem(35) toRem(107) toRem(35);
      font-size: toRem(20);
      line-height: toRem(32);
    }
  }

  &__city-title {
    margin-bottom: toRemMob(36);
    font-size: toRemMob(30);
    line-height: toRemMob(35);
    font-weight: 700;
    @include _desktop {
      margin-bottom: toRem(36);
      font-size: toRem(30);
      line-height: toRem(35);
    }
  }

  &__content {
    padding-left: toRemMob(30);
    margin-bottom: toRemMob(20);
    @include _desktop {
      padding-left: toRem(30);
      margin-bottom: toRem(23);
    }
  }

  &__title {
    position: relative;
    margin-bottom: toRemMob(7);
    font-weight: 400;
    font-size: toRemMob(20);
    line-height: toRemMob(23);
    @include _desktop {
      margin-bottom: toRem(7);
      font-weight: 600;
      font-size: toRem(20);
      line-height: toRem(23);
    }
  }

  &__button {
    padding: toRemMob(14) toRemMob(20) toRemMob(12) toRemMob(20);
    border-radius: toRemMob(4);
    border: 0;
    cursor: pointer;
    color: $color-white;
    background-color: $color-green;
    font-weight: 300;
    text-align: center;
    width: 100%;
    font-size: toRemMob(20);
    line-height: toRemMob(23);
    @include _desktop {
      padding: toRem(15) toRem(30);
      border-radius: toRem(4);
      font-size: toRem(20);
      line-height: toRem(20);
      width: max-content;
    }

    span {
      &:last-child {
        display: none;
        @include _desktop {
          display: inline-block;
        }
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }

}

.city-address {
  &__title {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: toRemMob(-28);
      width: toRemMob(13);
      height: toRemMob(19);
      margin-top: toRemMob(2);
      background: url("../assets/images/icons/address.svg") no-repeat left center;
      background-size: cover;
      @include _desktop {
        left: toRem(-28);
        width: toRem(13);
        height: toRem(19);
        margin-top: toRem(2);
      }
    }
  }
}

.city-phones {
  &__title {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: toRemMob(-28);
      width: toRemMob(16);
      height: toRemMob(16);
      margin-top: toRemMob(3);
      background: url("../assets/images/icons/phone.svg") no-repeat left center;
      background-size: cover;
      @include _desktop {
        left: toRem(-28);
        width: toRem(16);
        height: toRem(16);
        margin-top: toRem(2);
      }
    }
  }
}

.city-phone {

  &__phone {
    font-size: toRemMob(20);
    line-height: toRemMob(32);
  }

  &__link {
    color: $color-black;
  }
}

.city-time-work {
  &__title {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: toRemMob(-28);
      width: toRemMob(17);
      height: toRemMob(17);
      margin-top: toRemMob(3);
      background: url("../assets/images/icons/clock.svg") no-repeat left center;
      background-size: cover;
      @include _desktop {
        left: toRem(-28);
        width: toRem(17);
        height: toRem(17);
        margin-top: toRem(2);
      }
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>

<style lang="scss">

.container-map {
  position: relative;
}

.ymap-container {
  height: toRemMob(414);
  min-height: toRemMob(414);
  max-height: toRemMob(414);
  @include _desktop {
    position: absolute;
    height: unset;
    min-height: unset;
    max-height: unset;
    width: 100%;
    top: 0;
    bottom: 0;
  }
}
</style>
