<template>
  <div class="container-404">
    <div class="container-404__wrapper">
      <div class="container-404__leftTree"></div>
      <div class="container-404__main-block">
        <div class="container-404__404">404</div>
        <div class="container-404__text">{{ wordTranslation("404.not_found") }}</div>
      </div>
      <div class="container-404__rightTree"></div>
    </div>
    <a href="/" class="container-404__button">
      {{ wordTranslation("buttons.on_main_page") }}
    </a>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  inject: ["hidePagePreloader"],
  props: {
    wordTranslation: Function,
    toHomePage: Function
  },
  created() {
    this.hidePagePreloader();
    this.$store.commit("updateIsNotFound", true);
  }
};
</script>

<style lang="scss">
.container-404 {
  height: 100%;
  background: linear-gradient(180deg, #C5C5C5 0%, rgba(255, 255, 255, 0) 100%);
  display: flex;
  flex-direction: column;
  width: 100%;

  &__leftTree {
    display: none;
    @include _desktop {
      display: inherit;
      width: toRem(287);
      height: toRem(743);
      background-image: url("../assets/images/404/left-tree.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__rightTree {
    display: none;
    @include _desktop {
      display: inherit;
      width: toRem(287);
      height: toRem(743);
      background-image: url("../assets/images/404/right-tree.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__wrapper {
    padding-top: toRem(120);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__404 {
    text-align: center;
    width: 99%;
    height: toRem(250);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: toRem(250);
    line-height: toRem(140);
    color: rgba(255, 255, 255, 0.29);
    text-shadow: 0px toRem(4) toRem(50) rgba(0, 0, 0, 0.03);
    -webkit-text-stroke-width: toRem(3);
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.09);
    @include _desktop {
      width: toRem(845);
      height: toRem(534);
      font-size: toRem(490);
      line-height: toRem(574);
    }
  }

  &__main-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  &__text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: toRem(22);
    line-height: toRem(26);
    color: #B8B8B8;
    @include _desktop {
      margin: 0 auto;
    }
  }

  &__button {
    padding: toRem(16) toRem(63);
    background-color: #4E4E4E;
    color: #FFFFFF;
    margin: toRem(70) auto toRem(30) auto;
    border-radius: toRem(5);
    cursor: pointer;
    @include _desktop {
      margin: toRem(20) auto toRem(100) auto;
    }
  }
}

</style>

